import { styled } from '@mui/system';

export const TableCard = styled('div')(({ theme }) => ({
    borderRadius: 4,
    boxShadow: '0 0.5px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 4px 0.5px rgba(0, 0, 0, 0.08)',
    backgroundColor: theme.palette.common.white,
    paddingTop: 24
}));

export const PartnersContentBox = styled('div')({
    marginTop: 16
});

export const ActionBox = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '16px',
    marginBottom: 16,
    width: '100%'
});

export const TableActionsBox = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    justifyContent: 'flex-end'
});

export const TableActionBox = styled('div')({
    alignItems: 'center',
    padding: '8px',
    cursor: 'pointer',
    margin: '4px 8px',
    '&:hover': {
        borderRadius: 24,
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
});
