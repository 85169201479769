import { FC, useCallback, useEffect, useMemo, KeyboardEvent } from 'react';
import { useAdminOutcomesStateValue } from '../../../../../contexts/AdminOutcomesContext';
import NoDataCard from '../../../../../ui/cards/no-data-card/NoDataCard';
import SomethingWentWrongCard from '../../../../../ui/cards/something-went-wrong-card/SomethingWentWrongCard';
import Loading from '../../../../../ui/general/loading/Loading';
import {
    FilterBox,
    FilterLabel,
    FilterSelect,
    HeaderWrapper,
    OrderSpan,
    OutcomeCardWrapper,
    OutcomesListWrapper,
    SkillCountBox,
    StyledPaper,
    TitleBox,
    StyledMain,
    RootInnerBox,
    MainContentBox,
    SideBox,
    SkillsStatusOverallWrapper,
    FiltersWrapper,
    CardTitleTypography
} from './Style';
import { Drawer, MenuItem, SelectChangeEvent, useMediaQuery, useTheme } from '@mui/material';
import UpArrowIcon from '../../../../../assets/icons/UpArrowIcon';
import UpArrowFullIcon from '../../../../../assets/icons/UpArrowFullIcon';
import Tooltip from '../../../../../ui/general/tooltip/Tooltip';
import DownArrowFullIcon from '../../../../../assets/icons/DownArrowFullIcon';
import { useTranslation } from 'react-i18next';
import { EAdminOutcomeSort, EOrderDirection } from '../../../../../contexts/util/filterCategories';
import AdminOutcomeCard from '../../../../../ui/cards/admin-outcome-card/AdminOutcomeCard';
import { useNavigate } from 'react-router';
import { makeOutcomeRoute } from '../../../../../pages/routes';
import Filters from '../../../../../ui/filters/filters/Filters';
import { FiltersProvider } from '../../../../../contexts/FiltersContext';
import NumberStatusBox from '../../../../../ui/general/number-status-box/NumberStatusBox';
import { EOutcomesStatuses } from '../../../../../interfaces/enums/EOutcomesStatuses';
import { useCrumbsStateValue } from '../../../../../contexts/CrumbsContext';
import {
    CloseDrawerActionWrapper,
    CloseDrawerBox,
    FilterDrawerBox,
    FilterIconSpan
} from '../../../../../css/CommonComponents';
import { CloseOutlined } from '@material-ui/icons';
import useFilterDrawer from '../../../../../hooks/useFilterDrawer';
import FilterIcon from '../../../../../assets/icons/FilterIcon';
import { IAdminOutcomeVM } from '../../../../../interfaces/views/IAdminOutcomeVM';
import { accessibilityEnterKeyCallback } from '../../../../../utils/accessibilityUtils';

const OutcomeSelection: FC = () => {
    const {
        isOutcomesLoading,
        isError,
        outcomes,
        refetchData,
        isFetchedDataProcessed,
        sortOrder,
        setSortOrder,
        emptyQuickFilters,
        outcomesSelectionFilterCategories,
        onFilterValueChange,
        activeOutcomesStatusOverallFilters,
        outcomesStatusOverallInfo,
        changeOutcomeStatusOverallActiveFilters,
        selectedOutcomesList,
        setSuccessAssignAction,
        bulkAssignmentStep
    } = useAdminOutcomesStateValue();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { appendCrumb } = useCrumbsStateValue();
    const { isDrawerOpen, toggleFilterDrawer } = useFilterDrawer();
    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        setSuccessAssignAction(false);
    }, [bulkAssignmentStep]);

    const setAscedingFilterOrderWay = () => {
        setSortOrder({
            sortBy: sortOrder.sortBy,
            direction: EOrderDirection.ASC
        });
    };

    const setDescedingFilterOrderWay = () => {
        setSortOrder({
            sortBy: sortOrder.sortBy,
            direction: EOrderDirection.DESC
        });
    };

    const handleSkillFilterChange = (event: SelectChangeEvent<unknown>) => {
        setSortOrder({
            sortBy: event.target.value as EAdminOutcomeSort,
            direction: sortOrder.direction
        });
    };

    const onOutcomeClick = useCallback(
        (id: string) => {
            let adminOutcome: IAdminOutcomeVM | undefined;
            if (outcomes) {
                adminOutcome = outcomes.find((adminOutcome) => adminOutcome.outcome.id === parseInt(id));
            }
            const pathname = makeOutcomeRoute(id);
            appendCrumb({
                name: adminOutcome?.outcome.title || 'Organizational Outcome',
                pathname: pathname
            });
            navigate(pathname);
        },
        [outcomes]
    );

    const outcomesMemo = useMemo(() => {
        if (isOutcomesLoading) return <Loading />;
        if (isError) return <SomethingWentWrongCard actionCallback={refetchData}></SomethingWentWrongCard>;
        if (outcomes.length === 0 && isFetchedDataProcessed)
            return <NoDataCard message='No data that match your filter criteria' />;

        let container = document.querySelector('.outcomes-inner-box.not-empty');
        if (container) {
            (container as HTMLElement).style.transition = 'none';
            (container as HTMLElement).style.opacity = '0';
            container.classList.add('preAnimation');
            setTimeout(function () {
                if (container) {
                    (container as HTMLElement).style.transition = 'all 300ms';
                    (container as HTMLElement).style.opacity = '1';
                    container.classList.remove('preAnimation');
                }
            }, 100);
        }

        return (
            <OutcomeCardWrapper>
                {outcomes?.map((adminOutcome, index) => {
                    return (
                        <AdminOutcomeCard
                            index={index}
                            key={adminOutcome.outcome.id}
                            name={adminOutcome.outcome.title}
                            description={adminOutcome.outcome.bdmDescription}
                            appIcons={adminOutcome.outcome.apps}
                            id={adminOutcome.outcome.id}
                            outcomeClickHandler={onOutcomeClick}
                            isCompanyFocus={adminOutcome.isFavorite}
                            translations={{
                                assignTooltipText: 'Add Outcome',
                                unassignTooltipText: 'Remove Outcome'
                            }}
                            outcomeImage={adminOutcome.outcome.image}
                            outcomeVideo={adminOutcome.outcome.videoUrl}
                            outcomeVideoSnapshot={adminOutcome.outcome.videoImage}
                            numberOfUsers={adminOutcome.assignedUserCount}
                            isLoading={isOutcomesLoading}
                            skills={adminOutcome.outcome.skills}
                        />
                    );
                })}
            </OutcomeCardWrapper>
        );
    }, [outcomes, isOutcomesLoading, isError, refetchData, isFetchedDataProcessed, selectedOutcomesList]);

    const outcomesStatusOverallMemo = useMemo(() => {
        return (
            <SkillsStatusOverallWrapper isDisabled={isOutcomesLoading} className='outcome-filter-buttons'>
                {outcomesStatusOverallInfo.map((status) => {
                    return (
                        <NumberStatusBox
                            key={status.key}
                            clickHandler={(key) => changeOutcomeStatusOverallActiveFilters(key as EOutcomesStatuses)}
                            elementId={status.key}
                            isActive={activeOutcomesStatusOverallFilters.includes(status.key)}
                            circleColor={status.circleColor}
                            label={status.label}
                            value={status.value}
                            icon={status.icon}
                            isLoading={status.isLoading}
                            labelStyle={{
                                alignItems: 'center',
                                marginLeft: '4px'
                            }}
                        />
                    );
                })}
            </SkillsStatusOverallWrapper>
        );
    }, [outcomesStatusOverallInfo, activeOutcomesStatusOverallFilters, isOutcomesLoading]);

    const filtersMemo = useMemo(() => {
        return (
            <Filters
                isDisabled={isOutcomesLoading}
                handleFilterValueChange={onFilterValueChange}
                emptyQuickFilters={emptyQuickFilters}
                categories={outcomesSelectionFilterCategories}
                isQuickFiltersSelected={activeOutcomesStatusOverallFilters.length > 0}
            />
        );
    }, [
        isOutcomesLoading,
        activeOutcomesStatusOverallFilters,
        outcomesSelectionFilterCategories,
        emptyQuickFilters,
        onFilterValueChange
    ]);

    return (
        <StyledMain>
            <RootInnerBox>
                <FiltersProvider categories={outcomesSelectionFilterCategories}>
                    <MainContentBox>
                        <StyledPaper elevation={1}>
                            <HeaderWrapper>
                                <TitleBox>
                                    {isLgDown && (
                                        <Tooltip title='Filters'>
                                            <FilterIconSpan onClick={toggleFilterDrawer}>
                                                <FilterIcon />
                                            </FilterIconSpan>
                                        </Tooltip>
                                    )}
                                    <CardTitleTypography variant={isMdDown ? 'subtitle2' : 'h6'}>
                                        {t('adminOutcomes.outcomes')}
                                    </CardTitleTypography>
                                    <SkillCountBox variant='body2'>
                                        {selectedOutcomesList.length > 0
                                            ? `(${selectedOutcomesList.length}/${outcomes.length})`
                                            : `(${outcomes?.length ?? 0})`}
                                    </SkillCountBox>
                                </TitleBox>
                                <FilterBox isDisabled={isOutcomesLoading}>
                                    {!isMdDown && <FilterLabel>{t('outcomes.sortBy')}</FilterLabel>}
                                    <FilterSelect
                                        IconComponent={UpArrowIcon}
                                        value={sortOrder.sortBy}
                                        onChange={handleSkillFilterChange}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value={EAdminOutcomeSort.NAME}>
                                            {t('adminOutcomes.filters.name')}
                                        </MenuItem>
                                        <MenuItem value={EAdminOutcomeSort.NUMBER_OF_USERS}>
                                            {t('adminOutcomes.filters.numberOfUsers')}
                                        </MenuItem>
                                    </FilterSelect>
                                    {sortOrder.direction === EOrderDirection.ASC ? (
                                        <Tooltip title={t('tooltips.showDescending')}>
                                            <OrderSpan
                                                tabIndex={0}
                                                onClick={setDescedingFilterOrderWay}
                                                onKeyDown={(e: KeyboardEvent<any>) =>
                                                    accessibilityEnterKeyCallback(e, setDescedingFilterOrderWay)
                                                }
                                            >
                                                <UpArrowFullIcon />
                                            </OrderSpan>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title={t('tooltips.showAscending')}>
                                            <OrderSpan
                                                tabIndex={0}
                                                onClick={setAscedingFilterOrderWay}
                                                onKeyDown={(e: KeyboardEvent<any>) =>
                                                    accessibilityEnterKeyCallback(e, setAscedingFilterOrderWay)
                                                }
                                            >
                                                <DownArrowFullIcon />
                                            </OrderSpan>
                                        </Tooltip>
                                    )}
                                </FilterBox>
                            </HeaderWrapper>
                            <OutcomesListWrapper>{outcomesMemo}</OutcomesListWrapper>
                        </StyledPaper>
                        {!isLgDown && (
                            <SideBox>
                                {outcomesStatusOverallMemo}
                                <FiltersWrapper>
                                    <Filters
                                        isDisabled={isOutcomesLoading}
                                        handleFilterValueChange={onFilterValueChange}
                                        emptyQuickFilters={emptyQuickFilters}
                                        categories={outcomesSelectionFilterCategories}
                                        isQuickFiltersSelected={activeOutcomesStatusOverallFilters.length > 0}
                                    />
                                </FiltersWrapper>
                            </SideBox>
                        )}
                        {isDrawerOpen && (
                            <Drawer anchor='right' open={isDrawerOpen} onClose={toggleFilterDrawer}>
                                <CloseDrawerBox>
                                    <CloseDrawerActionWrapper onClick={toggleFilterDrawer}>
                                        <CloseOutlined />
                                    </CloseDrawerActionWrapper>
                                </CloseDrawerBox>
                                <FilterDrawerBox>
                                    {outcomesStatusOverallMemo}
                                    {filtersMemo}
                                </FilterDrawerBox>
                            </Drawer>
                        )}
                    </MainContentBox>
                </FiltersProvider>
            </RootInnerBox>
        </StyledMain>
    );
};

export default OutcomeSelection;
