import { styled } from '@mui/material/styles';
import { Select } from '@mui/material';

export const StyledForm = styled('form')({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    paddingBottom: 16
});

export const StyledSelect = styled(Select)({
    '& .MuiSvgIcon-root': {
        top: '7px !important'
    }
});
