import { useEffect } from 'react';
import { ELaunchType } from '../interfaces/enums/ELaunchType';

interface IProps {
    postMessageEvents: {
        type: ELaunchType;
        callback: (id: string, launchType: ELaunchType) => void;
    }[];
}

const usePostMessage: (props: IProps) => void = ({ postMessageEvents }) => {
    useEffect(() => {
        const messageEventHandler = (event: MessageEvent) => {
            const cdnUrl = process.env.REACT_APP_CDN_URL;
            if (!cdnUrl) return;
            if (!cdnUrl.startsWith(event.origin)) return;
            console.log('Message is received: ', event, event.data);

            const eventType = event.data.type as ELaunchType;
            const postMessageEvent = postMessageEvents.find((postMessageEvent) => postMessageEvent.type === eventType);

            if (!postMessageEvent) return;

            postMessageEvent.callback(event.data.targetId as string, eventType);
        };
        window.addEventListener('message', messageEventHandler, false);
        return () => {
            window.removeEventListener('message', messageEventHandler);
        };
    }, []);
};

export default usePostMessage;
