import { FC, useCallback, useRef } from 'react';
import Page from '../../components/page/Page';
import PageContent from '../../components/page-content/PageContent';
import {
    ConfirmDialog,
    EToastSeverity,
    IconButton,
    ITableHeadCell,
    Table,
    Tooltip,
    useConfirmModal,
    useModal,
    useToastContextStateValue
} from 'nulia-ui';
import { useTranslation } from 'react-i18next';
import { usePartnerUserStateValue } from '../../contexts/PartnerUserContext';
import { ActionBox, TableActionBox, TableActionsBox, TableCard } from './Style';
import { PlusCircle, UserX } from 'react-feather';
import { IPartnerUserVM } from '../../interfaces/views/IPartnerUserVM';
import AddPartnerUserDialog from '../../components/dialogs/add-partner-user-dialog/AddPartnerUserDialog';
import { useDeletePartnerUser } from '../../services/PartnerQueryService';
import { useParams } from 'react-router-dom';

interface IProps {}

interface ITableHeader {
    userId: string;
    displayName: string;
    email: string;
    actionsRow: string;
}

const PartnerUser: FC<IProps> = () => {
    const { t } = useTranslation();
    const {
        partnerUsersData,
        isPartnerUsersDataFetchingError,
        isPartnerUsersDataLoading,
        refetchPartnerUsersCallback
    } = usePartnerUserStateValue();
    const { mutateAsync: deletePartnerUserAsync } = useDeletePartnerUser();
    const {
        isOpen: isDeletePartnerUserDialogOpen,
        open: openDeletePartnerUserDialog,
        close: closeDeletePartnerUserDialog
    } = useConfirmModal({ defaultOpen: false });
    const {
        isOpen: isAddPartnerUserModalOpen,
        open: openPartnerUserModal,
        close: closePartnerUserModal
    } = useModal({ defaultOpen: false });
    const { setToastMessage } = useToastContextStateValue();
    const selectedPartnerUserRef = useRef<IPartnerUserVM | undefined>(undefined);
    const params = useParams<{ id: string }>();

    const onOpenDeleteAdministratorModalClick = useCallback((partnerUser: IPartnerUserVM) => {
        openDeletePartnerUserDialog();
        selectedPartnerUserRef.current = partnerUser;
    }, []);

    const onConfirmDeletePartnerUserCallback = useCallback(async () => {
        try {
            if (!selectedPartnerUserRef.current?.userId || !params.id) return;
            await deletePartnerUserAsync({
                partnerId: params.id,
                userId: selectedPartnerUserRef.current?.userId
            });
            setToastMessage({
                isOpen: true,
                message: t('successToastMessages.partnerUsers.delete'),
                severity: EToastSeverity.SUCCESS
            });
            refetchPartnerUsersCallback();
            closeDeletePartnerUserDialog();
        } catch (err) {
            console.error(err);
            setToastMessage({
                isOpen: true,
                message: t('errorToastMessages.partnerUsers.delete'),
                severity: EToastSeverity.ERROR
            });
            closeDeletePartnerUserDialog();
        }
    }, []);

    const onCancelDeletePartnerUserCallback = useCallback(() => {
        closeDeletePartnerUserDialog();
    }, []);

    const onAddPartnerUserSuccessCallback = useCallback(() => {
        refetchPartnerUsersCallback();
    }, []);

    const headCells: ITableHeadCell<ITableHeader>[] = [
        {
            id: 'userId',
            label: t('partnerUsers.table.userId'),
            width: '100px'
        },
        {
            id: 'displayName',
            label: t('partnerUsers.table.displayName')
        },
        {
            id: 'email',
            label: t('partnerUsers.table.email')
        },
        {
            id: 'actionsRow',
            label: '',
            disablePadding: true,
            disableSort: true,
            customRender(value, row: IPartnerUserVM) {
                return (
                    <TableActionsBox>
                        <TableActionBox onClick={() => onOpenDeleteAdministratorModalClick(row)}>
                            <Tooltip title='Remove Partner User'>
                                <UserX />
                            </Tooltip>
                        </TableActionBox>
                    </TableActionsBox>
                );
            }
        }
    ];

    return (
        <Page>
            <PageContent>
                <ActionBox>
                    <IconButton text='Partner User' startIcon={<PlusCircle />} clickHandler={openPartnerUserModal} />
                </ActionBox>
                <TableCard>
                    <Table
                        headCells={headCells}
                        isLoading={isPartnerUsersDataLoading}
                        data={partnerUsersData}
                        propertyKeys={headCells.map((headCell) => headCell.id)}
                        isError={isPartnerUsersDataFetchingError}
                        refetchDataCallback={refetchPartnerUsersCallback}
                        customMediaColumnName='actionsRow'
                    />
                </TableCard>
                <ConfirmDialog
                    title={t('partnerUsers.confirmDeleteDialog.title')}
                    description={t('partnerUsers.confirmDeleteDialog.description')}
                    isOpen={isDeletePartnerUserDialogOpen}
                    handleConfirm={onConfirmDeletePartnerUserCallback}
                    handleClose={onCancelDeletePartnerUserCallback}
                />
                <AddPartnerUserDialog
                    isOpen={isAddPartnerUserModalOpen}
                    onClose={closePartnerUserModal}
                    onAddPartnerUserSuccessCallback={onAddPartnerUserSuccessCallback}
                    partnerId={params?.id}
                />
            </PageContent>
        </Page>
    );
};

export default PartnerUser;
