import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const RootBox = styled('div')(({ theme }) => ({
    borderRadius: 4,
    boxShadow: '0 0.5px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 4px 0.5px rgba(0, 0, 0, 0.08)',
    backgroundColor: '#ffffff',
    padding: '6px 0 12px 0',
    gap: '16px',
    overflow: 'hidden',
    width: '100%',

    [theme.breakpoints.down('lg')]: {
        paddingBottom: '24px'
    }
}));

export const TitleTypography = styled(Typography)({
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: '0.25px',
    fontFamily: 'Ubuntu',
    color: 'rgba(0, 0, 0, 0.80)'
});

export const ContentBox = styled('div')({
    display: 'grid',
    gridTemplateRows: '1fr auto',
    width: '100%',
    gap: '16px',
    padding: '0 24px'
});

export const HeaderBox = styled('div')<{ isActionItemAvailable: boolean }>(({ isActionItemAvailable }) => ({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    marginBottom: '16px',
    padding: isActionItemAvailable ? '6px 24px 8px 24px' : '13px 24px 18px 24px',
    minHeight: '42px'
}));

export const ItemBox = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'auto 50px',
    width: '100%',
    alignItems: 'center'
});

export const ItemLabelTypography = styled(Typography)<{ isAccent?: boolean }>(({ isAccent = false }) => ({
    fontFamily: 'Open Sans',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: isAccent ? 600 : 400,
    color: 'rgba(0, 0, 0, 0.64)'
}));

export const ItemValueTypography = styled(Typography)<{ isAccent?: boolean }>(({ isAccent = false }) => ({
    textAlign: 'right',
    fontSize: '14px',
    lineHeight: '24px',
    fontFamily: 'Open Sans',
    fontWeight: isAccent ? 600 : 400,
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)'
}));

export const ContentColumn = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px'
});

export const OverallInfoBox = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 2fr',
    height: '120px',
    borderRadius: '4px',

    [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: '1fr 1fr',
        height: 'auto'
    },

    [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr'
    },

    '& > div:last-of-type': {
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px'
    },

    '& > div:first-of-type': {
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px'
    }
}));

export const SecondaryInfoBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    backgroundColor: '#FAFAFA',
    padding: '16px 20px',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px'
});

export const PrimaryInfoBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    background: 'linear-gradient(0deg, #F3F1F8 0%, #F3F1F8 100%)',
    padding: '16px 20px'
});

export const SecondaryInfoTitle = styled(Typography)({
    lineHeight: '16px',
    minHeight: '16px',
    color: '#000',
    paddingBottom: '10px'
});

export const SecondaryInfoSubtitle = styled(Typography)({
    lineHeight: '20px',
    fontSize: '10px',
    fontWeight: 500,
    letterSpacing: '1.25px'
});

export const SecondaryInfoValue = styled(Typography)({
    fontSize: '20px',
    lineHeight: '24px',
    color: '#000',
    letterSpacing: '0.5px'
});

export const PrimaryInfoTitle = styled(Typography)({});

export const PrimaryInfoSubtitle = styled(Typography)({
    lineHeight: '20px',
    fontSize: '10px',
    fontWeight: 500,
    color: '#616063',
    letterSpacing: '1.25px'
});

export const PrimaryInfoValue = styled(Typography)({
    fontSize: '20px',
    lineHeight: '24px',
    color: '#000',
    letterSpacing: '0.5px'
});

export const DetailedInfoBox = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
    marginTop: '30px',

    [theme.breakpoints.down(970)]: {
        gridTemplateColumns: '1fr',
        gap: '12px'
    }
}));

export const ActionsBox = styled('div')({
    display: 'flex',
    gap: '12px'
});

export const ChangeResellersBox = styled('div')<{ isDisabled: boolean }>(({ isDisabled, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    '&:hover': {
        background: 'rgba(0, 0, 0, 0.04)',
        cursor: 'pointer',
        borderRadius: '24px',
        color: theme.palette.primary.main
    }
}));

export const UpdateLicensesBox = styled('div')<{ isDisabled: boolean }>(({ isDisabled, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    '&:hover': {
        background: 'rgba(0, 0, 0, 0.04)',
        cursor: 'pointer',
        borderRadius: '24px',
        color: theme.palette.primary.main
    }
}));

export const RenameResellerBox = styled('div')<{ isDisabled: boolean }>(({ isDisabled, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    '&:hover': {
        background: 'rgba(0, 0, 0, 0.04)',
        cursor: 'pointer',
        borderRadius: '24px',
        color: theme.palette.primary.main
    }
}));

export const CustomerNameTypography = styled(Typography)(({ theme }) => ({
    fontSize: '20px',
    lineHeight: '24px',
    fontFamily: 'Ubuntu',
    color: theme.palette.primary.main,
    letterSpacing: '0.25px'
}));

export const UsageSummaryLabel = styled(Typography)({
    paddingLeft: '6px'
});

export const HeaderNameLabel = styled('div')({
    display: 'flex'
});
