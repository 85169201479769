import { styled } from '@mui/material/styles';
import { Dialog as MuiDialog } from '@material-ui/core';

export const StyledMuiDialog = styled(MuiDialog)<{ minHeight?: string }>(({ minHeight }) => ({
    '&& .MuiDialog-paper': {
        minHeight
    }
}));

export const CloseBox = styled('div')(() => ({
    '&:hover': {
        cursor: 'pointer'
    }
}));
