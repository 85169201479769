import { Switch, Tab, Tabs } from '@mui/material';
import Dialog from '../dialogs/dialog/Dialog';
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EToastSeverity, NTextInput, Tooltip, useToastContextStateValue } from 'nulia-ui';
import { Controller, useForm, useWatch } from 'react-hook-form';
import {
    ContentWrapper,
    CountBox,
    CurrentLicenseNumberLabelTypography,
    CurrentLicenseNumberValueTypography,
    LicenseSyncLabel,
    NewLicenseNumberLabelTypography,
    NewLicenseNumberValueTypography,
    StyledForm,
    StyledSwitchFormControlLabel,
    StyledTextInputFormControlLabel,
    TabPanelRootBox
} from './Style';
import { useTranslation } from 'react-i18next';
import {
    useDeleteDisableLicenseSync,
    usePostAddCustomerLicenses,
    usePostEnableLicenseSync,
    usePostRemoveCustomerLicenses
} from '../../services/AccountsQueryService';
import { Info } from 'react-feather';

interface IProps {
    isOpen: boolean;
    onCloseCallback: () => void;
    currentLicenseNumber?: number;
    customerId: string;
    isLicenseSyncEnabled: boolean;
    onChangeLicenseSyncCallback?: (isEnabled: boolean) => void;
    onChangeLicenseCountCallback?: () => void;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <TabPanelRootBox>{children}</TabPanelRootBox>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const UpdateLicenseCountModal: FC<IProps> = ({
    isOpen,
    onCloseCallback,
    currentLicenseNumber,
    customerId,
    isLicenseSyncEnabled,
    onChangeLicenseSyncCallback,
    onChangeLicenseCountCallback
}) => {
    const [activeTabValue, setActiveTabValue] = useState<number>(0);
    const formRef = useRef<HTMLFormElement>(null);
    const { t } = useTranslation();
    const { mutateAsync: enableLicenseSyncAsync, isPending: isEnableLicenseSyncPending } = usePostEnableLicenseSync();
    const { mutateAsync: disableLicenseSyncAsync, isPending: isDisableLicenseSyncPending } =
        useDeleteDisableLicenseSync();
    const { setToastMessage } = useToastContextStateValue();
    const { mutateAsync: mutatePostAddCustomerLicensesApiAsync, isPending: isAddCustomerLicensesPending } =
        usePostAddCustomerLicenses();
    const { mutateAsync: mutatePostRemoveCustomerLicensesApiAsync, isPending: isRemoveCustomerLicensesPending } =
        usePostRemoveCustomerLicenses();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTabValue(newValue);
    };

    const handleConfirm = (event: React.SyntheticEvent) => {
        handleUpdateLicenseCount();
    };

    const changeLicenseSync = async (isChecked: boolean) => {
        if (isChecked) {
            try {
                await enableLicenseSyncAsync({ customerId });
                setToastMessage({
                    isOpen: true,
                    message: t('successToastMessages.licenses.licenseSyncEnabled'),
                    severity: EToastSeverity.SUCCESS
                });
                if (onChangeLicenseSyncCallback) onChangeLicenseSyncCallback(isChecked);
            } catch (err) {
                setToastMessage({
                    isOpen: true,
                    message: t('errorToastMessages.licenses.licenseSyncEnabled'),
                    severity: EToastSeverity.ERROR
                });
            }
        } else {
            try {
                await disableLicenseSyncAsync({ customerId });
                setToastMessage({
                    isOpen: true,
                    message: t('successToastMessages.licenses.licenseSyncDisabled'),
                    severity: EToastSeverity.SUCCESS
                });
                if (onChangeLicenseSyncCallback) onChangeLicenseSyncCallback(isChecked);
            } catch (err) {
                setToastMessage({
                    isOpen: true,
                    message: t('errorToastMessages.licenses.licenseSyncDisabled'),
                    severity: EToastSeverity.ERROR
                });
            }
        }
    };

    const {
        control,
        handleSubmit,
        formState: { isValid, isSubmitting, errors },
        setValue,
        reset
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            sync: isLicenseSyncEnabled,
            numberOfLicenses: 0
        }
    });
    const numberLicensesWatch = useWatch({ name: 'numberOfLicenses', control });
    const licenseSyncWatch = useWatch({ name: 'sync', control });

    useEffect(() => {
        setValue('sync', isLicenseSyncEnabled);
        setValue('numberOfLicenses', 0);
        reset({
            sync: isLicenseSyncEnabled
        });
    }, [activeTabValue, isLicenseSyncEnabled]);

    const handleUpdateLicenseCount = async () => {
        try {
            if (activeTabValue === 0) {
                await mutatePostAddCustomerLicensesApiAsync({
                    customerId,
                    numLicenses: numberLicensesWatch.valueOf()
                });
                await setToastMessage({
                    isOpen: true,
                    message: t('successToastMessages.licenses.addLicenses'),
                    severity: EToastSeverity.SUCCESS
                });
                if (onChangeLicenseCountCallback) onChangeLicenseCountCallback();
                if (onCloseCallback) onCloseCallback();
                reset();
            } else {
                await mutatePostRemoveCustomerLicensesApiAsync({
                    customerId,
                    numLicenses: numberLicensesWatch.valueOf()
                });
                setToastMessage({
                    isOpen: true,
                    message: t('successToastMessages.licenses.removeLicenses'),
                    severity: EToastSeverity.SUCCESS
                });
                if (onChangeLicenseCountCallback) onChangeLicenseCountCallback();
                if (onCloseCallback) onCloseCallback();
                reset();
            }
        } catch (err) {
            console.error(err);
            if (activeTabValue === 0) {
                setToastMessage({
                    isOpen: true,
                    message: t('errorToastMessages.licenses.addLicenses'),
                    severity: EToastSeverity.ERROR
                });
            } else {
                setToastMessage({
                    isOpen: true,
                    message: t('errorToastMessages.licenses.removeLicenses'),
                    severity: EToastSeverity.ERROR
                });
            }
            reset();
        }
    };

    const tabContentMemo = useMemo(() => {
        if (currentLicenseNumber === undefined) return <></>;
        return (
            <StyledForm onSubmit={handleSubmit(handleUpdateLicenseCount)} ref={formRef}>
                {activeTabValue === 0 && (
                    <Controller
                        name='sync'
                        control={control}
                        disabled={isEnableLicenseSyncPending || isDisableLicenseSyncPending}
                        render={({ field }) => {
                            return (
                                <StyledSwitchFormControlLabel
                                    label={
                                        <LicenseSyncLabel>
                                            License Sync
                                            <Tooltip title={t('updateLicenseCount.licenseSyncTooltip')}>
                                                <Info />
                                            </Tooltip>
                                        </LicenseSyncLabel>
                                    }
                                    labelPlacement='start'
                                    disabled={isEnableLicenseSyncPending || isDisableLicenseSyncPending}
                                    control={
                                        <Switch
                                            color='secondary'
                                            // defaultChecked={field.value}
                                            checked={field.value}
                                            onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                                setValue('sync', checked);
                                                changeLicenseSync(checked);
                                            }}
                                            aria-label='Switch to Master Level'
                                            inputProps={{ 'aria-label': 'Switch to' }}
                                        />
                                    }
                                />
                            );
                        }}
                    />
                )}
                <Controller
                    name={'numberOfLicenses'}
                    control={control}
                    disabled={licenseSyncWatch}
                    rules={{
                        min: {
                            value: 1,
                            message: `Minimum number of licenses is 1`
                        },
                        required: {
                            value: true,
                            message: 'Minimum number of licenses is 1'
                        },
                        max: {
                            value: activeTabValue === 0 ? 500000 : currentLicenseNumber!,
                            message:
                                activeTabValue === 0
                                    ? 'Maximum number of licenses is 500000'
                                    : `You can remove maximum of ${currentLicenseNumber} licenses`
                        }
                    }}
                    render={({ field }) => {
                        return (
                            <Tooltip title={licenseSyncWatch ? t('updateLicenseCount.licenseSyncEnabledTooltip') : ''}>
                                <StyledTextInputFormControlLabel
                                    label='Number of Licenses'
                                    labelPlacement='end'
                                    control={
                                        <NTextInput
                                            disabled={licenseSyncWatch}
                                            type='number'
                                            label=''
                                            field={field}
                                            value={field.value}
                                            onChange={field.onChange}
                                            errorMessage={errors['numberOfLicenses']?.message as string}
                                            boxStyle={{ flex: 2 }}
                                        />
                                    }
                                />
                            </Tooltip>
                        );
                    }}
                />
                {
                    <>
                        {!licenseSyncWatch && (
                            <CountBox>
                                <CurrentLicenseNumberLabelTypography>
                                    Current licence number:
                                </CurrentLicenseNumberLabelTypography>
                                <CurrentLicenseNumberValueTypography>
                                    {currentLicenseNumber || 0}
                                </CurrentLicenseNumberValueTypography>
                            </CountBox>
                        )}
                        {!licenseSyncWatch && (
                            <CountBox>
                                <NewLicenseNumberLabelTypography>
                                    New licence number after submitting:
                                </NewLicenseNumberLabelTypography>
                                <NewLicenseNumberValueTypography>
                                    {activeTabValue == 0 && !isNaN(numberLicensesWatch)
                                        ? //@ts-ignore
                                          (currentLicenseNumber || 0) + parseInt(numberLicensesWatch || 0)
                                        : //@ts-ignore
                                          (currentLicenseNumber || 0) - parseInt(numberLicensesWatch || 0)}
                                </NewLicenseNumberValueTypography>
                            </CountBox>
                        )}
                    </>
                }
            </StyledForm>
        );
    }, [
        activeTabValue,
        errors,
        isValid,
        numberLicensesWatch,
        currentLicenseNumber,
        isLicenseSyncEnabled,
        isEnableLicenseSyncPending,
        isDisableLicenseSyncPending,
        control
    ]);

    const onModalClose = useCallback(() => {
        setActiveTabValue(0);
        reset();
        onCloseCallback();
    }, []);

    return (
        <Dialog
            open={isOpen}
            onClose={onModalClose}
            content={
                <ContentWrapper>
                    <Tabs value={activeTabValue} onChange={handleChange}>
                        <Tab label='Add Licenses' {...a11yProps(0)} />
                        <Tab label='Remove Licenses' {...a11yProps(1)} />
                    </Tabs>
                    <CustomTabPanel value={activeTabValue} index={0}>
                        {tabContentMemo}
                    </CustomTabPanel>
                    <CustomTabPanel value={activeTabValue} index={1}>
                        {tabContentMemo}
                    </CustomTabPanel>
                </ContentWrapper>
            }
            acceptButtonWidth={170}
            onAccept={handleConfirm}
            acceptText={activeTabValue === 0 ? 'Add Licenses' : 'Remove Licenses'}
            cancelText='Cancel'
            isSubmitActionDisabled={
                isSubmitting ||
                !isValid ||
                isAddCustomerLicensesPending ||
                isRemoveCustomerLicensesPending ||
                numberLicensesWatch === 0 ||
                licenseSyncWatch
            }
            submitActionDisabledTooltip={
                licenseSyncWatch ? t('updateLicenseCount.licenseSyncEnabledTooltip') : undefined
            }
            isBackdropCloseActive={false}
            width={500}
            minHeight='400px'
        ></Dialog>
    );
};

export default UpdateLicenseCountModal;
