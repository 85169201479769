import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledMain = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%'
});

export const RootInnerBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '12px',
    maxWidth: '790px',
    width: '100%',
    backgroundColor: theme.palette.common.white
}));

export const HeaderBox = styled('div')({
    display: 'flex',
    gap: '24px',
    padding: ' 32px 24px 24px 24px',
    alignItems: 'flex-end'
});

export const HeaderTitleTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main
}));

export const HeaderCount = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '22px'
}));

export const ReviewDataBox = styled('div')({});

export const ReviewTypeSwitch = styled('span')({
    textDecoration: 'underline',
    cursor: 'pointer',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    letterSpacing: '0.5px',
    paddingBottom: '2px'
});

export const UsersTableBox = styled('div')({
    padding: '16px'
});

export const OutcomeBox = styled('div')({
    display: 'flex',
    padding: '25px 20px 18px 70px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)'
});

export const OutcomeTitle = styled(Typography)({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '20px'
});
