import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { getTokenAndHeaders, IAuthParams, useAuthParams } from 'nulia-ui';
import { apiInstance } from '../util/apiInstance';

export interface IPostAddPartnerUserDTO {
    name: string;
    email: string;
    userOid: string;
}

export const postAddPartnerUser: (
    variables: { partnerId: string; addPartnerUserDTO: IPostAddPartnerUserDTO } & {
        authParams: IAuthParams;
    }
) => Promise<undefined> = async (variables) => {
    const { headers } = await getTokenAndHeaders(variables.authParams);

    const {
        data: { data }
    } = await apiInstance.post(`${variables.partnerId}/user`, variables.addPartnerUserDTO, headers);
    return data;
};

export const usePostAddPartnerUser: () => UseMutationResult<any, Error, any> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: async (variables: { partnerId: string; addPartnerUserDTO: IPostAddPartnerUserDTO }) => {
            return postAddPartnerUser({ ...variables, authParams });
        }
    });
};
