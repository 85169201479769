import { createContext, FC, useContext, PropsWithChildren, useState, useEffect } from 'react';
import { useGetPartnerCustomersQuery, useGetPartnerResellersQuery } from '../services/AccountsQueryService';
import { useGetLicenseSummaryQuery } from '../services/PartnerQueryService';
import { ILicenseSummaryVM } from '../interfaces/views/ILicenseSummaryVM';
import { IResellerVM } from '../interfaces/views/IResellerVM';
import { ICustomerVM } from '../interfaces/views/ICustomerVM';
import { QueryObserverResult } from '@tanstack/react-query';
import { useGetFolderLicenseSummaryQuery } from '../services/FolderQueryService';
import { IActivityVM } from '../interfaces/views/IActivityVM';
import { useGetFolderActivityLogsQuery, useGetPartnerActivityLogsQuery } from '../services/ActivityLogsQueryService';

export interface IAccountsContext {
    licenseSummaryData?: ILicenseSummaryVM;
    isLicenseSummaryDataLoading: boolean;
    isLicenseSummaryDataFetchingError: boolean;
    refetchLicenseSummaryDataCallback: () => void;
    resellersData?: IResellerVM[];
    customersData?: ICustomerVM[];
    isCustomersDataLoading: boolean;
    isCustomersDataFetchingError: boolean;
    refetchResellersData: () => Promise<QueryObserverResult<IResellerVM[], Error>>;
    refetchUsageSummary: (resellerId: string | undefined) => void;
    selectedResellerId: string | undefined;
    refetchCustomersData: () => Promise<QueryObserverResult<ICustomerVM[], Error>>;
    activityLogData?: IActivityVM[];
    isActivityLogDataLoading: boolean;
    isActivityLogDataError: boolean;
}

export const AccountsContext = createContext<IAccountsContext>({} as IAccountsContext);

interface IProps {}

export const AccountsProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const [selectedResellerId, setSelectedResellerId] = useState<string | undefined>();
    const {
        data: fetchedLicenseSummaryData,
        isLoading: isLicenseSummaryDataLoading,
        isError: isLicenseSummaryDataFetchingError,
        refetch: refetchLicenseSummaryDataCallback
    } = useGetLicenseSummaryQuery();
    const { data: fetchedResellersData, refetch: refetchResellersData } = useGetPartnerResellersQuery();
    const {
        data: fetchedPartnerCustomers,
        isLoading: isCustomersDataLoading,
        isError: isCustomersDataFetchingError,
        refetch: refetchCustomersData
    } = useGetPartnerCustomersQuery();
    const {
        data: fetchedFolderActivityData,
        isLoading: isFolderActivityDataLoading,
        isError: isFolderActivityDataError,
        refetch: refetchFolderActivityData
    } = useGetFolderActivityLogsQuery(selectedResellerId);
    const {
        data: fetchedPartnerActivityData,
        isLoading: isPartnerActivityDataLoading,
        isError: isPartnerActivityDataError,
        refetch: refetchPartnerActivityData
    } = useGetPartnerActivityLogsQuery();
    const {
        data: fetchedFolderLicenseSummaryData,
        refetch: refetchFolderLicenseSummary,
        isLoading: isFolderLicenseSummaryDataLoading,
        isError: isFolderLicenseSummaryDataFetchingError
    } = useGetFolderLicenseSummaryQuery(selectedResellerId);

    useEffect(() => {
        if (selectedResellerId) {
            refetchFolderLicenseSummary();
            refetchFolderActivityData();
        } else {
            refetchLicenseSummaryDataCallback();
            refetchPartnerActivityData();
        }
    }, [selectedResellerId]);

    const refetchUsageSummary = (resellerId: string | undefined) => {
        if (resellerId === 'My Customers') {
            setSelectedResellerId('00000000-0000-0000-0000-000000000000');
        } else setSelectedResellerId(resellerId);
    };

    const accountsState: IAccountsContext = {
        isLicenseSummaryDataFetchingError: selectedResellerId
            ? isFolderLicenseSummaryDataFetchingError
            : isLicenseSummaryDataFetchingError,
        isLicenseSummaryDataLoading: selectedResellerId
            ? isFolderLicenseSummaryDataLoading
            : isLicenseSummaryDataLoading,
        licenseSummaryData: selectedResellerId ? fetchedFolderLicenseSummaryData : fetchedLicenseSummaryData,
        refetchLicenseSummaryDataCallback,
        resellersData: fetchedResellersData,
        customersData: fetchedPartnerCustomers,
        isCustomersDataLoading,
        isCustomersDataFetchingError,
        refetchResellersData,
        refetchUsageSummary,
        selectedResellerId,
        refetchCustomersData,
        activityLogData: selectedResellerId ? fetchedFolderActivityData : fetchedPartnerActivityData,
        isActivityLogDataLoading: selectedResellerId ? isFolderActivityDataLoading : isPartnerActivityDataLoading,
        isActivityLogDataError: selectedResellerId ? isFolderActivityDataError : isPartnerActivityDataError
    };

    return <AccountsContext.Provider value={accountsState}>{children}</AccountsContext.Provider>;
};

export const useAccountsStateValue: () => IAccountsContext = () => useContext(AccountsContext);
