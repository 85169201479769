import styled from '../../../../../ui/theme/styled';

export const RootBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
});

export const RootInnerBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    width: 1080,
    paddingLeft: '20px',
    paddingRight: '20px',
    [theme.breakpoints.down('lg')]: {
        width: '100%'
    },
    [theme.breakpoints.down('md')]: {
        paddingLeft: '10px',
        paddingRight: '10px'
    }
}));

export const CardWrapper = styled('div')({});

export const TableFilterWrapper = styled('div')({
    display: 'flex',
    gap: '12px'
});

export const TableContainer = styled('div')({
    flex: 11
});

export const FiltersWrapper = styled('div')({
    flex: 4
});

export const StatusTableColumnBox = styled('div')({
    width: '100%',
    display: 'flex',
    paddingLeft: '16px'
});
