import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const RootBox = styled('div')({});

export const CollectorStatusWrapper = styled('div')({});

export const CollectorStatusInfoItemsWrapper = styled('div')(({ theme }) => ({
    padding: '0 21px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    gap: '12px',
    [theme.breakpoints.down('lg')]: {
        padding: '0 8px'
    }
}));

export const TenantStatusBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    marginTop: '8px'
});

export const TenantStatusTitleTypography = styled(Typography)({
    fontSize: '10px',
    fontWeight: 500
});

export const TenantStatusContentTypography = styled(Typography)({});
