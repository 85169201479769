import { FC, useRef } from 'react';
import Dialog from '../dialog/Dialog';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { StyledForm, StyledSelect } from './Style';
import { FormControl, FormHelperText, MenuItem } from '@mui/material';
import { EToastSeverity, NTextInput, useToastContextStateValue } from 'nulia-ui';
import { usePostAddNewPartner } from '../../../services/PartnerQueryService';
import { ERegion } from '../../../interfaces/enums/ERegion';

interface Props {
    isOpen: boolean;
    onConfirmSuccessCallback: () => void;
    onClose: () => void;
}

const AddPartnerDialog: FC<Props> = ({ isOpen, onConfirmSuccessCallback, onClose }) => {
    const { t } = useTranslation();
    const {
        reset,
        formState: { isValid, errors, isDirty },
        control,
        handleSubmit,
        getValues
    } = useForm({
        mode: 'onChange'
    });
    const formRef = useRef<HTMLFormElement>(null);
    const { setToastMessage } = useToastContextStateValue();
    const { mutateAsync: mutatePostAddNewPartnerAsync, isPending: isApiCallPending } = usePostAddNewPartner();

    const addNewPartner = async () => {
        try {
            const formValues = getValues();
            await mutatePostAddNewPartnerAsync({
                name: formValues.partnerName,
                adTenantGuid: formValues.tenantId,
                region: formValues.region
            });
            setToastMessage({
                isOpen: true,
                message: t('successToastMessages.partners.add'),
                severity: EToastSeverity.SUCCESS
            });
            if (onConfirmSuccessCallback) onConfirmSuccessCallback();
            reset();
        } catch (err) {
            console.error(err);
            setToastMessage({
                isOpen: true,
                message: t('errorToastMessages.partners.add'),
                severity: EToastSeverity.ERROR
            });
            reset();
        }
    };

    const handleAccept = async () => {
        handleSubmitForm();
    };

    const handleClose = () => {
        reset();
        onClose();
    };

    const handleSubmitForm = () => {
        addNewPartner();
        onClose();
    };
    return (
        <Dialog
            open={isOpen}
            onAccept={handleAccept}
            acceptText={t('partners.addDialog.confirmButton')}
            onClose={handleClose}
            cancelText={t('partners.addDialog.cancelButton')}
            title={t('partners.addDialog.title')}
            isSubmitActionDisabled={!isValid || isApiCallPending || !isDirty}
            minHeight='200px'
            content={
                <div>
                    <StyledForm ref={formRef} onSubmit={handleSubmit(handleSubmitForm)}>
                        <FormControl fullWidth>
                            <Controller
                                name={'partnerName'}
                                control={control}
                                rules={{
                                    required: true,
                                    maxLength: {
                                        message: 'Maximum length is 100',
                                        value: 100
                                    }
                                }}
                                render={({ field }) => (
                                    <NTextInput
                                        field={field}
                                        label={t('partners.addDialog.partnerName')}
                                        value={field.value}
                                        onChange={field.onChange}
                                        errorMessage={errors['partnerName']?.message as string}
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <Controller
                                name={'tenantId'}
                                control={control}
                                rules={{
                                    required: true,
                                    maxLength: {
                                        message: 'Maximum length is 100',
                                        value: 100
                                    },
                                    pattern: {
                                        value: /^\d{8}-\d{4}-\d{4}-\d{4}-\d{12}$/,
                                        message: "Value doesn't follow pattern 00000000-0000-0000-0000-000000000000"
                                    }
                                }}
                                render={({ field }) => (
                                    <NTextInput
                                        field={field}
                                        label={t('partners.addDialog.tenantId')}
                                        value={field.value}
                                        onChange={field.onChange}
                                        errorMessage={errors['tenantId']?.message as string}
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <FormHelperText style={{ marginLeft: 0 }}>
                                {t('accounts.addCustomer.regionInput')}
                            </FormHelperText>
                            <Controller
                                name={'region'}
                                control={control}
                                rules={{
                                    required: true
                                }}
                                render={({ field }) => (
                                    <StyledSelect id='region-select' value={field.value} onChange={field.onChange}>
                                        <MenuItem key='0' value={ERegion.US}>
                                            US
                                        </MenuItem>
                                        <MenuItem key='1' value={ERegion.EUROPE}>
                                            Europe
                                        </MenuItem>
                                        <MenuItem key='2' value={ERegion.UK}>
                                            UK
                                        </MenuItem>
                                        <MenuItem key='3' value={ERegion.AUSTRALIA}>
                                            Australia
                                        </MenuItem>
                                    </StyledSelect>
                                )}
                            />
                        </FormControl>
                    </StyledForm>
                </div>
            }
        />
    );
};

export default AddPartnerDialog;
