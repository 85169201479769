import {
    Dropdown,
    ITableHeadCell,
    Table,
    Tooltip,
    makeAccountDetailsRoute,
    routes,
    useCrumbsStateValue
} from 'nulia-ui';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Edit } from 'react-feather';
import UpdateLicenseCountModal from '../../../components/modals/UpdateLicenseCountModal';
import { AddRemoveLicenceIconBox, CircleSpan, FilterBox, FilterTypography, HeaderBox, HeaderLabel } from './Style';
import { SelectChangeEvent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ICustomerVM } from '../../../interfaces/views/ICustomerVM';
import { ETenantStatus } from '../../../interfaces/enums/ETenantStatus';
import { useTheme } from '@mui/system';
import { ELicenseClass } from '../../../interfaces/enums/ELicenseClass';

interface ITableHeader {
    tenantStatus: string;
    name: string;
    m365Licenses: string;
    nuliaLicensesAssigned: string;
    m365UsersWithoutNuliaLicenses: string;
    nuliaLicensesUnassigned: string;
    addRemoveLicenses: any;
}

interface IProps {
    customersData?: ICustomerVM[];
    isLoading: boolean;
    resellerFilter: string;
    onChangeLicenseSyncCallback: () => void;
    onChangeLicenseCountCallback: () => void;
    headerLabel?: string;
}

const AccountsTable: FC<IProps> = ({
    customersData,
    isLoading,
    resellerFilter,
    onChangeLicenseSyncCallback,
    onChangeLicenseCountCallback,
    headerLabel
}) => {
    const { t } = useTranslation();
    const [isUpdateLicenseCountModalOpen, setUpdateLicenseCountModalOpen] = useState<boolean>(false);
    const [tenantStatusFilter, setTenantStatusFilter] = useState<ETenantStatus | 'All Statuses'>('All Statuses');
    const [filteredCustomersData, setFilteredCustomersData] = useState<ICustomerVM[] | undefined>();
    const currentNumberOfLicensesForOpenModal = useRef<number | undefined>(undefined);
    const navigate = useNavigate();
    const theme = useTheme();
    const [selectedTableRow, setSelectedTableRow] = useState<ICustomerVM | undefined>();
    const { appendCrumb, replaceLatestCrumb } = useCrumbsStateValue();

    const onOpenUpdateLicenseModalClick = async (
        e: React.MouseEvent | React.KeyboardEvent,
        id: string,
        numberOfNuliaLicenses?: number
    ) => {
        e.stopPropagation();
        currentNumberOfLicensesForOpenModal.current = numberOfNuliaLicenses;
        setUpdateLicenseCountModalOpen(true);
    };

    const onCloseCallback = () => {
        setUpdateLicenseCountModalOpen(false);
    };

    useEffect(() => {
        let newFilteredCustomersData = customersData;
        if (resellerFilter === 'All Customers') {
            newFilteredCustomersData = customersData;
        } else if (resellerFilter === 'My Customers') {
            newFilteredCustomersData = customersData?.filter((customer) => !customer.folderId);
        } else {
            newFilteredCustomersData = customersData?.filter((customer) => customer.folderId === resellerFilter);
        }

        if (tenantStatusFilter !== 'All Statuses') {
            newFilteredCustomersData = newFilteredCustomersData?.filter(
                (customer) => customer.tenantStatus === tenantStatusFilter
            );
        }

        if (newFilteredCustomersData?.length === 0) setFilteredCustomersData(undefined);
        else setFilteredCustomersData(newFilteredCustomersData);
    }, [customersData, tenantStatusFilter, resellerFilter]);

    const headCells: ITableHeadCell<ITableHeader>[] = [
        {
            id: 'tenantStatus',
            label: 'Status',
            width: '50px',
            customRender(value, row) {
                switch (value) {
                    case ETenantStatus.NEW:
                        return (
                            <Tooltip title={t('partnerTooltips.new')}>
                                <CircleSpan color={theme.palette.status.assigned} />
                            </Tooltip>
                        );
                    case ETenantStatus.ACTIVE:
                        return (
                            <Tooltip title={t('partnerTooltips.active')}>
                                <CircleSpan color={theme.palette.status.attained} />
                            </Tooltip>
                        );
                    case ETenantStatus.NEEDS_ATTENTION:
                        return (
                            <Tooltip title={t('partnerTooltips.needsAttention')}>
                                <CircleSpan color={theme.palette.status.needAttention} />
                            </Tooltip>
                        );
                    case ETenantStatus.DEACTIVATED:
                        return (
                            <Tooltip title={t('partnerTooltips.deactivated')}>
                                <CircleSpan color='rgba(0, 0, 0, 0.08)' />
                            </Tooltip>
                        );
                }
                return <></>;
            }
        },
        {
            id: 'name',
            label: 'Company name',
            minWidth: '120px'
        },
        {
            id: 'm365Licenses',
            label: 'Office 365 licenses',
            disablePadding: true,
            numeric: true
        },
        {
            id: 'nuliaLicensesAssigned',
            label: 'Nulia licenses assigned',
            disablePadding: true,
            numeric: true,
            minWidth: '110px'
        },
        {
            id: 'm365UsersWithoutNuliaLicenses',
            label: 'Office 365 Users without Nulia',
            disablePadding: true,
            numeric: true,
            minWidth: '100px'
        },
        {
            id: 'nuliaLicensesUnassigned',
            label: 'Available licences',
            disablePadding: true,
            numeric: true
        },
        {
            id: 'addRemoveLicenses',
            label: '',
            disablePadding: true,
            disableSort: true,
            customRender(value, row: ICustomerVM) {
                return (
                    <AddRemoveLicenceIconBox
                        tabIndex={0}
                        onClick={(e) => {
                            setSelectedTableRow(row);
                            onOpenUpdateLicenseModalClick(e, row.id, row.nuliaLicensesUnassigned);
                        }}
                    >
                        <Tooltip title={t('partnerTooltips.openUpdateLicenseModal')}>
                            <Edit />
                        </Tooltip>
                    </AddRemoveLicenceIconBox>
                );
            }
        }
    ];

    const onChangeAccountStatusFilter = (event: SelectChangeEvent<unknown>) =>
        setTenantStatusFilter(event.target.value as ETenantStatus);

    const onAccountTableRowClick = (id: string) => {
        replaceLatestCrumb({
            name: 'Accounts',
            pathname: `${routes.PARTNER_ACCOUNTS}`
        });
        const pathname = makeAccountDetailsRoute(id);
        navigate(pathname);
        appendCrumb({
            name: 'Customer Details',
            pathname
        });
    };

    return (
        <div>
            <HeaderBox>
                <HeaderLabel>{headerLabel}</HeaderLabel>
                <FilterBox>
                    <FilterTypography variant='button'>FILTER BY</FilterTypography>
                    <Dropdown
                        items={[
                            { value: 'All Statuses', name: 'All Statuses' },
                            { value: ETenantStatus.NEW, name: 'New' },
                            { value: ETenantStatus.ACTIVE, name: 'Active' },
                            { value: ETenantStatus.NEEDS_ATTENTION, name: 'Needs Attention' },
                            { value: ETenantStatus.DEACTIVATED, name: 'Deactivated' }
                        ]}
                        value={tenantStatusFilter}
                        handleChange={onChangeAccountStatusFilter}
                        defaultValue={tenantStatusFilter}
                        style={{
                            width: '260px'
                        }}
                    />
                </FilterBox>
            </HeaderBox>

            <Table
                headCells={headCells}
                data={filteredCustomersData}
                propertyKeys={headCells.map((headCell) => headCell.id)}
                onRowClick={onAccountTableRowClick}
                customMediaColumnName='companyName'
                customMediaSecondaryColumns={['addRemoveLicenses']}
                initialOrderBy='companyName'
                isLoading={isLoading}
            />
            {selectedTableRow?.id && (
                <UpdateLicenseCountModal
                    currentLicenseNumber={currentNumberOfLicensesForOpenModal.current}
                    isOpen={isUpdateLicenseCountModalOpen}
                    onCloseCallback={onCloseCallback}
                    customerId={selectedTableRow.id}
                    isLicenseSyncEnabled={selectedTableRow.licenseClass === ELicenseClass.COMPLETE_SYNC}
                    onChangeLicenseSyncCallback={onChangeLicenseSyncCallback}
                    onChangeLicenseCountCallback={onChangeLicenseCountCallback}
                />
            )}
        </div>
    );
};

export default AccountsTable;
