import { FC, useEffect, useMemo, useRef } from 'react';
import { StyledForm, RootBox, ActionBox, InputBox } from './Style';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { FormControl } from '@mui/material';
import {
    EToastSeverity,
    Loading,
    NTextArea,
    PrimaryButton,
    SomethingWentWrongCard,
    useToastContextStateValue
} from 'nulia-ui';
import BasicStatusCard from '../../../components/basic-status-card/BasicStatusCard';
import { INotesVM } from '../../../interfaces/views/INotesVM';
import { usePutNotes } from '../../../services/AccountsQueryService';

interface IProps {
    notes?: INotesVM;
    isLoading: boolean;
    isError: boolean;
    refetch: () => void;
    customerId?: string;
}

const Notes: FC<IProps> = ({ notes, isLoading, isError, refetch, customerId }) => {
    const { t } = useTranslation();
    const formRef = useRef<HTMLFormElement>(null);
    const {
        formState: { isValid, isDirty },
        control,
        handleSubmit,
        getValues,
        setValue,
        reset
    } = useForm({
        defaultValues: {
            notes: notes?.notes || ''
        }
    });
    const { mutateAsync: mutateNotesAsync, isPending: isNotesPending } = usePutNotes();
    const { setToastMessage } = useToastContextStateValue();

    useEffect(() => {
        if (notes) setValue('notes', notes.notes);
    }, [notes]);

    const handleSubmitForm = async () => {
        const formValues = getValues();
        if (customerId) {
            try {
                await mutateNotesAsync({
                    notes: formValues.notes,
                    customerId
                });
                setToastMessage({
                    isOpen: true,
                    message: t('successToastMessages.notes.update'),
                    severity: EToastSeverity.SUCCESS
                });
                refetch();
                reset({
                    notes: formValues.notes
                });
            } catch (err) {
                console.error(err);
                setToastMessage({
                    isOpen: true,
                    message: t('errorToastMessages.notes.update'),
                    severity: EToastSeverity.ERROR
                });
            }
        }
    };

    const contentMemo = useMemo(() => {
        if (isLoading) return <Loading />;
        if (isError) return <SomethingWentWrongCard actionCallback={refetch} boxStyle={{ boxShadow: 'none' }} />;
        return (
            <StyledForm ref={formRef} onSubmit={handleSubmit(handleSubmitForm)}>
                <ActionBox>
                    <PrimaryButton
                        title={t('accountDetails.notes.submitButton')}
                        disabled={isNotesPending || !isDirty || !isValid}
                        type='submit'
                    />
                </ActionBox>
                <InputBox>
                    <FormControl fullWidth>
                        <Controller
                            name={'notes'}
                            control={control}
                            rules={{
                                required: false
                            }}
                            render={({ field }) => (
                                <NTextArea
                                    disabled={isNotesPending}
                                    field={field}
                                    placeholder={t('accountDetails.notes.inputPlaceholder')}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                            )}
                        />
                    </FormControl>
                </InputBox>
            </StyledForm>
        );
    }, [isLoading, isError, notes, refetch, customerId, isNotesPending, isDirty, isValid]);

    return (
        <BasicStatusCard title='Notes'>
            <RootBox>{contentMemo}</RootBox>
        </BasicStatusCard>
    );
};

export default Notes;
