import styled from '../../../../../ui/theme/styled';

export const BadgeStateWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%'
});

export const StateBox = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('lg')]: {
        justifyContent: 'flex-start'
    }
}));

export const ClaimBox = styled('div')(({ theme }) => ({
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
        justifyContent: 'flex-start'
    }
}));

export const ImageBox = styled('div')(({ theme }) => ({
    paddingRight: 12,
    minHeight: 120,
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
        minWidth: '180px'
    },
    [theme.breakpoints.down('md')]: {
        minWidth: '150px'
    }
}));

export const BadgeImg = styled('img')(({ theme }) => ({
    maxWidth: 140,
    [theme.breakpoints.down('lg')]: {
        width: 100,
        height: 100
    }
}));

export const DownloadBadgeBox = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    '&:hover svg path': {
        fill: `${theme.palette.primary.main} !important`
    }
}));

export const ShareBadgeBox = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    '&:hover svg path': {
        fill: `${theme.palette.primary.main} !important`
    }
}));

export const BadgeActionsBox = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    left: '6px',
    bottom: -6,
    width: 'calc(100% - 26px)'
});
