import { FC, useCallback, useMemo } from 'react';
import {
    ContentBox,
    ContentColumn,
    DetailedInfoBox,
    HeaderBox,
    ItemBox,
    ItemLabelTypography,
    ItemValueTypography,
    OverallInfoBox,
    PrimaryInfoBox,
    PrimaryInfoSubtitle,
    PrimaryInfoTitle,
    PrimaryInfoValue,
    RootBox,
    SecondaryInfoBox,
    SecondaryInfoSubtitle,
    SecondaryInfoTitle,
    SecondaryInfoValue,
    TitleTypography,
    ActionsBox,
    ChangeResellersBox,
    CustomerNameTypography,
    UpdateLicensesBox,
    RenameResellerBox,
    UsageSummaryLabel,
    HeaderNameLabel
} from './Style';
import { Loading, SomethingWentWrongCard, Tooltip, useModal } from 'nulia-ui';
import EditCustomerDialog from '../../../components/dialogs/edit-customer-dialog/EditCustomerDialog';
import RenameResellerDialog from '../../../components/dialogs/rename-reseller-dialog/RenameResellerDialog';
import UpdateLicenseCountModal from '../../../components/modals/UpdateLicenseCountModal';
import { ELicenseClass } from '../../../interfaces/enums/ELicenseClass';
import { Edit, Settings } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useAccountDetailsStateValue } from '../../../contexts/AccountDetailsContext';

interface IProps {
    allocatedNuliaLicenses: number;
    assignedNuliaLicenses: number;
    unassignedNuliaLicenses: number;
    totalOfficeLicenses: number;
    totalOfficeWithNoLicenses: number;
    totalNumberOfResellers?: number;
    totalNumberOfCustomers?: number;
    isLoading: boolean;
    isError: boolean;
    refetchDataCallback: () => void;
    initialCustomerName?: string;
    initialReseller?: string;
    initialRegion?: string;
    isRegionFieldChangeDisabled?: boolean;
    tenantId?: string;
    licenseClass?: ELicenseClass;
    isRenameResellerOptionAvailable?: boolean;
    initialResellerName?: string;
    isCustomerMode?: boolean;
    headerTitle?: string;
}

const UsageSummarySimple: FC<IProps> = ({
    allocatedNuliaLicenses,
    assignedNuliaLicenses,
    unassignedNuliaLicenses,
    totalOfficeLicenses,
    totalOfficeWithNoLicenses,
    totalNumberOfCustomers,
    totalNumberOfResellers,
    isLoading,
    isError,
    refetchDataCallback,
    initialCustomerName,
    initialRegion,
    initialReseller,
    isRegionFieldChangeDisabled = false,
    tenantId,
    licenseClass,
    isRenameResellerOptionAvailable = false,
    initialResellerName,
    isCustomerMode = false,
    headerTitle
}) => {
    const { isOpen: isAssociateAccountOpen, setOpen: setAssociateAccountOpen } = useModal({ defaultOpen: false });
    const {
        isOpen: isUpdateLicenseCountModalOpen,
        setOpen: setUpdateLicenseCountModalOpen,
        close: closeUpdateLicenseCountModal
    } = useModal({
        defaultOpen: false
    });
    const { t } = useTranslation();
    const { refetchCustomerDetailsApiCall } = useAccountDetailsStateValue();

    const toggleAssociateAccount = () => {
        setAssociateAccountOpen(!isAssociateAccountOpen);
    };

    const openUpdateCustomerModal = () => {
        setAssociateAccountOpen(true);
    };

    const openUpdateLicenseModal = () => {
        setUpdateLicenseCountModalOpen(true);
    };

    const onSuccessUpdateCustomerCallback = () => {
        refetchCustomerDetailsApiCall();
    };

    const contentMemo = useMemo(() => {
        return (
            <>
                <ContentColumn>
                    <ItemBox>
                        <ItemLabelTypography isAccent>Nulia Works Licenses Allocated</ItemLabelTypography>
                        <ItemValueTypography isAccent>{allocatedNuliaLicenses.toLocaleString()}</ItemValueTypography>
                    </ItemBox>
                    <ItemBox>
                        <ItemLabelTypography>Nulia Works Licenses Assigned</ItemLabelTypography>
                        <ItemValueTypography>{assignedNuliaLicenses.toLocaleString()}</ItemValueTypography>
                    </ItemBox>
                    <ItemBox>
                        <ItemLabelTypography>Nulia Works Licenses Unassigned</ItemLabelTypography>
                        <ItemValueTypography>{unassignedNuliaLicenses.toLocaleString()}</ItemValueTypography>
                    </ItemBox>
                </ContentColumn>
                <ContentColumn>
                    {totalNumberOfResellers && (
                        <ItemBox>
                            <ItemLabelTypography>Number of Resellers</ItemLabelTypography>
                            <ItemValueTypography variant='subtitle1'>
                                {totalNumberOfResellers.toLocaleString()}
                            </ItemValueTypography>
                        </ItemBox>
                    )}
                    {totalNumberOfCustomers && (
                        <ItemBox>
                            <ItemLabelTypography>Number of Customers</ItemLabelTypography>
                            <ItemValueTypography>{totalNumberOfCustomers.toLocaleString()}</ItemValueTypography>
                        </ItemBox>
                    )}
                </ContentColumn>
            </>
        );
    }, [
        allocatedNuliaLicenses,
        assignedNuliaLicenses,
        unassignedNuliaLicenses,
        totalNumberOfCustomers,
        totalNumberOfResellers
    ]);

    const overallInfoMemo = useMemo(() => {
        return (
            <OverallInfoBox>
                <SecondaryInfoBox>
                    <SecondaryInfoTitle variant='overline'>Licenses Overview</SecondaryInfoTitle>
                    <SecondaryInfoSubtitle variant='overline'>Total M365 licenses</SecondaryInfoSubtitle>
                    <SecondaryInfoValue variant='h6'>{totalOfficeLicenses.toLocaleString()}</SecondaryInfoValue>
                </SecondaryInfoBox>
                <SecondaryInfoBox>
                    <SecondaryInfoTitle variant='overline'></SecondaryInfoTitle>
                    <SecondaryInfoSubtitle variant='overline'>Allocated Nulia Works Licenses</SecondaryInfoSubtitle>
                    <SecondaryInfoValue variant='h6'>{allocatedNuliaLicenses.toLocaleString()}</SecondaryInfoValue>
                </SecondaryInfoBox>
                <PrimaryInfoBox>
                    <PrimaryInfoTitle variant='overline'>Opportunity!</PrimaryInfoTitle>
                    <PrimaryInfoSubtitle variant='overline'>
                        M365 Users with no Nulia Works Licenses
                    </PrimaryInfoSubtitle>
                    <PrimaryInfoValue variant='h6'>{totalOfficeWithNoLicenses.toString()}</PrimaryInfoValue>
                </PrimaryInfoBox>
            </OverallInfoBox>
        );
    }, [totalOfficeLicenses, allocatedNuliaLicenses, totalOfficeWithNoLicenses]);

    const cardContentMemo = useMemo(() => {
        if (isError) {
            return (
                <SomethingWentWrongCard
                    boxStyle={{ boxShadow: 'none', minHeight: '200px' }}
                    actionCallback={refetchDataCallback}
                />
            );
        }
        if (isLoading) {
            return (
                <ContentBox>
                    <Loading />
                </ContentBox>
            );
        }
        return (
            <ContentBox>
                {overallInfoMemo}
                <DetailedInfoBox>{contentMemo}</DetailedInfoBox>
            </ContentBox>
        );
    }, [overallInfoMemo, contentMemo, isError, isLoading, refetchDataCallback]);

    const onUpdateLicenseCountSuccessCallback = useCallback(() => {
        refetchDataCallback();
    }, []);

    return (
        <RootBox>
            <HeaderBox isActionItemAvailable={isCustomerMode || isRenameResellerOptionAvailable}>
                <TitleTypography>
                    {headerTitle ? (
                        <HeaderNameLabel>
                            <CustomerNameTypography>{headerTitle}</CustomerNameTypography>
                            <UsageSummaryLabel variant='h6'> - License Usage Summary</UsageSummaryLabel>
                        </HeaderNameLabel>
                    ) : (
                        <HeaderNameLabel>License Usage Summary</HeaderNameLabel>
                    )}
                </TitleTypography>

                <ActionsBox>
                    {isRenameResellerOptionAvailable && (
                        <RenameResellerBox isDisabled={false} onClick={openUpdateLicenseModal}>
                            <Tooltip title={t('partnerTooltips.openRenameResellerModal')}>
                                <Settings />
                            </Tooltip>
                        </RenameResellerBox>
                    )}
                    {isCustomerMode && (
                        <>
                            <UpdateLicensesBox isDisabled={false} onClick={openUpdateLicenseModal}>
                                <Tooltip title={t('partnerTooltips.openUpdateLicenseModal')}>
                                    <Edit />
                                </Tooltip>
                            </UpdateLicensesBox>
                            <ChangeResellersBox isDisabled={false} onClick={openUpdateCustomerModal}>
                                <Tooltip title={t('partnerTooltips.openUpdateCustomerModal')}>
                                    <Settings />
                                </Tooltip>
                            </ChangeResellersBox>
                        </>
                    )}
                </ActionsBox>
            </HeaderBox>
            {cardContentMemo}
            {isCustomerMode && tenantId && (
                <EditCustomerDialog
                    open={isAssociateAccountOpen}
                    onClose={toggleAssociateAccount}
                    initialCustomerName={initialCustomerName!}
                    initialRegion={initialRegion!}
                    initialReseller={initialReseller!}
                    isRegionFieldChangeDisabled={isRegionFieldChangeDisabled}
                    customerId={tenantId}
                    onSuccessCallback={onSuccessUpdateCustomerCallback}
                />
            )}
            {isCustomerMode && tenantId && (
                <UpdateLicenseCountModal
                    currentLicenseNumber={allocatedNuliaLicenses}
                    isOpen={isUpdateLicenseCountModalOpen}
                    onCloseCallback={closeUpdateLicenseCountModal}
                    customerId={tenantId}
                    isLicenseSyncEnabled={licenseClass === ELicenseClass.COMPLETE_SYNC}
                    onChangeLicenseCountCallback={onUpdateLicenseCountSuccessCallback}
                />
            )}
            {isRenameResellerOptionAvailable && initialResellerName && initialReseller && (
                <RenameResellerDialog
                    isOpen={isUpdateLicenseCountModalOpen}
                    onClose={closeUpdateLicenseCountModal}
                    initialResellerName={initialResellerName}
                    resellerId={initialReseller}
                />
            )}
        </RootBox>
    );
};

export default UsageSummarySimple;
