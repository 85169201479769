import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl } from '@mui/material';
import { EToastSeverity, NTextInput, useToastContextStateValue } from 'nulia-ui';
import { Controller, useForm } from 'react-hook-form';
import Dialog from '../../../../components/dialogs/dialog/Dialog';
import { StyledForm } from './Style';
import { usePostAddCustomerAdministrator } from '../../../../services/AccountsQueryService';

interface Props {
    open: boolean;
    onClose: () => void;
    customerId: string;
    successAddAdminCallback?: () => void;
}

const AddAdministratorDialog: FC<Props> = ({ open, onClose, customerId, successAddAdminCallback }) => {
    const { t } = useTranslation();
    const formRef = useRef<HTMLFormElement>(null);
    const {
        control,
        handleSubmit,
        reset,
        formState: { isValid, errors, isDirty },
        getValues
    } = useForm({
        mode: 'onChange'
    });
    const { setToastMessage } = useToastContextStateValue();
    const { mutateAsync: mutatePostAddCustomerAdministratorAsync, isPending: isAddCustomerAdministratorPending } =
        usePostAddCustomerAdministrator();

    const handleCloseDialog = () => {
        resetForm();
        onClose();
    };

    const handleSubmitForm = async () => {
        try {
            const formValues = getValues();
            await mutatePostAddCustomerAdministratorAsync({
                customerId,
                name: formValues.name,
                email: formValues.email
            });
            setToastMessage({
                isOpen: true,
                message: t('successToastMessages.accountDetails.administrators.add'),
                severity: EToastSeverity.SUCCESS
            });
            if (successAddAdminCallback) successAddAdminCallback();
            handleCloseDialog();
        } catch (e) {
            setToastMessage({
                isOpen: true,
                message: t('errorToastMessages.accountDetails.administrators.add'),
                severity: EToastSeverity.ERROR
            });
            handleCloseDialog();
        }
    };

    const handleAccept = async () => {
        handleSubmitForm();
    };

    const handleClose = () => {
        resetForm();
        onClose();
    };

    const resetForm = () => {
        reset();
    };

    return (
        <Dialog
            open={open}
            onAccept={handleAccept}
            acceptText={t('accountDetails.administrators.addDialog.confirmButton')}
            isSubmitActionDisabled={!isValid || isAddCustomerAdministratorPending || !isDirty}
            onClose={handleClose}
            cancelText={t('accountDetails.administrators.addDialog.cancelButton')}
            title={t('accountDetails.administrators.addDialog.title')}
            minHeight='200px'
            content={
                <div>
                    <StyledForm onSubmit={handleSubmit(handleSubmitForm)} ref={formRef}>
                        <FormControl fullWidth>
                            <Controller
                                name={'name'}
                                control={control}
                                rules={{
                                    required: true,
                                    maxLength: {
                                        message: 'Maximum length is 50',
                                        value: 50
                                    }
                                }}
                                render={({ field }) => (
                                    <NTextInput
                                        field={field}
                                        label={t('accountDetails.administrators.addDialog.name')}
                                        value={field.value}
                                        onChange={field.onChange}
                                        errorMessage={errors['name']?.message as string}
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <Controller
                                name={'email'}
                                control={control}
                                rules={{
                                    required: true,
                                    maxLength: {
                                        message: 'Maximum length is 255',
                                        value: 255
                                    },
                                    pattern: {
                                        value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                        message: 'Invalid email address'
                                    }
                                }}
                                render={({ field }) => (
                                    <NTextInput
                                        field={field}
                                        label={t('accountDetails.administrators.addDialog.email')}
                                        value={field.value}
                                        onChange={field.onChange}
                                        errorMessage={errors['email']?.message as string}
                                    />
                                )}
                            />
                        </FormControl>
                    </StyledForm>
                </div>
            }
        />
    );
};

export default AddAdministratorDialog;
