import { FC, useCallback, useMemo } from 'react';
import { format } from 'date-fns';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
    CardWrapper,
    FiltersWrapper,
    RootBox,
    RootInnerBox,
    StatusTableColumnBox,
    TableContainer,
    TableFilterWrapper
} from './Style';
import { FiltersProvider } from '../../../../../contexts/FiltersContext';
import { useAdminHistoryDetailsStateValue } from '../../../../../contexts/AdminHistoryDetailsContext';
import Table from '../../../../../ui/general/table/Table';
import { EAdminHistoryStatus } from '../../../../../interfaces/enums/EAdminHistoryStatus';
import {
    DecreaseStatusCircle,
    InProgressStatusCircle,
    IncreaseStatusCircle,
    UnassignedStatusCircle,
    WarningStatusCircle
} from '../../../../../css/CommonComponents';
import { ITableHeadCell } from '../../../../../interfaces/ITableHeaderCell';
import Filters from '../../../../../ui/filters/filters/Filters';
import DetailsSummaryCard from './components/details-summary-card/DetailsSummaryCard';
import useFilterDrawer from '../../../../../hooks/useFilterDrawer';
import { ITenantJobTaskStatusVM } from '../../../../../interfaces/views/ITenantJobTaskStatusVM';
import { ETenantHistoryStatus } from '../../../../../interfaces/enums/ETenantHistoryStatus';
import { useAdminHistoryStateValue } from '../../../../../contexts/AdminHistoryContext';
import { RefreshActionBox, RefreshControlWrapper, RefreshSpan } from '../Style';
import RefreshIcon from '../../../../../assets/icons/RefreshIcon';

interface IProps {}

interface ITableHeader {
    image: string;
    status: EAdminHistoryStatus;
    userName: string;
    department: string;
}

const HistoryDetails: FC<IProps> = () => {
    const {
        filterCategories,
        historyDetailsData,
        onFilterValueChange,
        selectedAdminHistoryItem,
        isTenantJobTaskStatusLoading,
        refetchTenantHistoryData,
        refetchTenantJobTaskStatus
    } = useAdminHistoryDetailsStateValue();
    const { isTenantHistoryDataLoading } = useAdminHistoryStateValue();
    const { isDrawerOpen, toggleFilterDrawer } = useFilterDrawer();
    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation();

    const headCells: ITableHeadCell<ITableHeader>[] = [
        {
            id: 'image',
            numeric: false,
            disablePadding: true,
            disableSort: true,
            label: ''
        },
        {
            id: 'userName',
            numeric: false,
            disablePadding: false,
            label: 'Display Name'
        },
        {
            id: 'department',
            numeric: false,
            disablePadding: false,
            label: 'Department',
            align: 'center'
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            align: 'left',
            label: 'Status',
            customRender: (value: ETenantHistoryStatus) => {
                let statusJsx = <></>;
                switch (value) {
                    case ETenantHistoryStatus.COMPLETED:
                        statusJsx = <IncreaseStatusCircle />;
                        break;
                    case ETenantHistoryStatus.ERROR:
                        statusJsx = <DecreaseStatusCircle />;
                        break;
                    case ETenantHistoryStatus.IN_PROGRESS:
                    case ETenantHistoryStatus.INITIATED:
                        statusJsx = <InProgressStatusCircle />;
                        break;
                    case ETenantHistoryStatus.WARNING:
                        statusJsx = <WarningStatusCircle />;
                        break;
                    default:
                        statusJsx = <UnassignedStatusCircle />;
                }
                return <StatusTableColumnBox className='task-status-box'>{statusJsx}</StatusTableColumnBox>;
            }
        }
    ];

    const filtersMemo = useMemo(() => {
        return (
            <Filters
                isDisabled={false}
                handleFilterValueChange={onFilterValueChange}
                categories={filterCategories}
                isQuickFiltersSelected={false}
                dropdownMode
            />
        );
    }, [onFilterValueChange, filterCategories]);

    const refreshHistoryDetailsTable = useCallback(() => {
        if (!isTenantHistoryDataLoading) {
            refetchTenantJobTaskStatus();
            refetchTenantHistoryData();
        }
    }, [refetchTenantHistoryData, isTenantHistoryDataLoading]);

    return (
        <RootBox>
            <FiltersProvider categories={filterCategories}>
                <RootInnerBox>
                    <RefreshControlWrapper>
                        <RefreshActionBox
                            isDisabled={isTenantHistoryDataLoading}
                            id='refresh-action-box'
                            onClick={refreshHistoryDetailsTable}
                        >
                            <RefreshIcon color={isTenantHistoryDataLoading ? theme.palette.gray.medium : undefined} />
                            <RefreshSpan color={isTenantHistoryDataLoading ? theme.palette.gray.medium : undefined}>
                                {t('adminHistory.refresh')}
                            </RefreshSpan>
                        </RefreshActionBox>
                    </RefreshControlWrapper>
                    <CardWrapper>
                        <DetailsSummaryCard
                            isLoading={isTenantJobTaskStatusLoading || isTenantHistoryDataLoading}
                            createdBy={selectedAdminHistoryItem?.initiatorName}
                            date={
                                selectedAdminHistoryItem?.date ? format(selectedAdminHistoryItem?.date, 'M/d/yy') : ''
                            }
                            status={selectedAdminHistoryItem?.status}
                            inProgressCount={selectedAdminHistoryItem?.inProgressCount}
                            failCount={selectedAdminHistoryItem?.failuresCount}
                            successCount={selectedAdminHistoryItem?.successesCount}
                            type={selectedAdminHistoryItem?.type}
                            targets={selectedAdminHistoryItem?.targets}
                        />
                    </CardWrapper>
                    <TableFilterWrapper>
                        <TableContainer>
                            <Table<ITenantJobTaskStatusVM, ITableHeader>
                                headCells={headCells}
                                data={historyDetailsData}
                                propertyKeys={headCells.map((headCell) => {
                                    return headCell.id;
                                })}
                                tableTitlePlural='History'
                                isFilterControlVisible={isLgDown}
                                isFilterDrawerOpen={isDrawerOpen}
                                filterControlToggleHandler={toggleFilterDrawer}
                                filtersDrawerMemo={filtersMemo}
                                isLoading={isTenantJobTaskStatusLoading}
                                isImageColumnPresent
                                userImageInfoColumn='userName'
                                userImageInfoColumnPosition={isMdDown ? 'bottom' : 'right'}
                            />
                        </TableContainer>
                        {!isLgDown && <FiltersWrapper>{filtersMemo}</FiltersWrapper>}
                    </TableFilterWrapper>
                </RootInnerBox>
            </FiltersProvider>
        </RootBox>
    );
};

export default HistoryDetails;
