import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const CategoryWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isContentLoading'
})<{ isContentLoading?: boolean }>(({ theme, isContentLoading = false }) => ({
    display: 'flex',
    gap: '32px',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '16px 24px 24px 24px',
    [theme.breakpoints.down('lg')]: {
        padding: '16px',
        flexDirection: 'column'
    }
}));

export const ActionBox = styled('div')({
    border: '1px solid rgba(0, 0, 0, 0.12)',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '16px',
    padding: '24px'
});

export const ActionTitle = styled(Typography)({
    color: 'rgba(0, 0, 0, 0.88)',
    fontFamily: 'Open Sans',
    fontSize: '16px',
    lineHeight: '1.5',
    letterSpacing: '0.5px',
    textAlign: 'center'
});

export const ActionButtonBox = styled('div')({});

export const ConnectionDateTypography = styled(Typography)({
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.88)',
    fontFamily: 'Open Sans',
    lineHeight: 1.5,
    letterSpacing: '0.5px'
});

export const ActiveConnectionBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    gap: '16px'
});

export const ActionsBox = styled('div')({
    flex: 3,
    display: 'flex',
    gap: '16px',
    flexWrap: 'wrap'
});

export const CollectorBox = styled('div')({
    flex: 2,
    minWidth: '450px'
});
