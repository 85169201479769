import { FC, useCallback, useMemo, KeyboardEvent, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useMediaQuery, useTheme, Drawer } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { CloseOutlined } from '@material-ui/icons';
import { SelectChangeEvent } from '@mui/material/Select';
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';
import { useTranslation } from 'react-i18next';
import SkillCard from '../../ui/cards/skill-card/SkillCard';
import Loading from '../../ui/general/loading/Loading';
import NumberStatusBox from '../../ui/general/number-status-box/NumberStatusBox';
import UpArrowIcon from '../../assets/icons/UpArrowIcon';
import UpArrowFullIcon from '../../assets/icons/UpArrowFullIcon';
import Tooltip from '../../ui/general/tooltip/Tooltip';
import DownArrowFullIcon from '../../assets/icons/DownArrowFullIcon';
import Filters from '../../ui/filters/filters/Filters';
import { FiltersProvider } from '../../contexts/FiltersContext';
import { progressDoughnutChartType } from '../../utils/progressDoughnutChartType';
import { useSkillsStateValue } from '../../contexts/SkillsContext';
import SomethingWentWrongCard from '../../ui/cards/something-went-wrong-card/SomethingWentWrongCard';
import { IUserSkillVM } from '../../interfaces/views/IUserSkillVM';
import { EOrderDirection, ESkillSort } from '../../contexts/util/filterCategories';
import {
    HeaderWrapper,
    MainContentBox,
    RootInnerBox,
    SkillCountBox,
    SkillsStatusOverallWrapper,
    TitleBox,
    SideBox,
    FilterBox,
    FilterLabel,
    FilterSelect,
    OrderSpan,
    SkillListCardWrapper,
    SkillListWrapper,
    ContentHeaderBox,
    TitleSpan,
    FilterDrawerBox,
    CloseDrawerBox,
    CloseDrawerActionWrapper,
    FilterIconSpan,
    RootBox,
    StyledPaper
} from './Style';
import NoDataCard from '../../ui/cards/no-data-card/NoDataCard';
import { ESkillsStatuses } from '../../interfaces/ESkillsStatuses';
import { makeSkillRoute, routes } from '../routes';
import RecommendationSimpleCard from '../../ui/cards/recommendation-simple-card/RecommendationSimpleCard';
import FilterIcon from '../../assets/icons/FilterIcon';
import { useCrumbsStateValue } from '../../contexts/CrumbsContext';
import useFilterDrawer from '../../hooks/useFilterDrawer';
import usePageName from '../../hooks/usePageName';
import useTablePagination from '../../hooks/useTablePagination';
import TablePagination from '../../ui/general/pagination/TablePagination';
import { useApplicationStateValue } from '../../contexts/ApplicationContext';

const Skills: FC = () => {
    const appInsights = useAppInsightsContext();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const trackSkillClick = useTrackEvent(appInsights, 'Skill click', { trackId: 2 });
    const {
        skills,
        isSkillsLoading,
        skillsStatusOverallInfo,
        activeSkillsStatusOverallFilters,
        changeSkillStatusOverallActiveFilters,
        onFilterValueChange,
        isError,
        refetchData,
        filterCategories,
        toggleFavoriteCallback,
        toggleAssignCallback,
        setSortOrder,
        sortOrder,
        emptyQuickFilters,
        isFetchedDataProcessed,
        recommendations,
        isRecommendationsLoading,
        successRecommendationCallback
    } = useSkillsStateValue();
    const { appendCrumb, crumbs, setCrumbs } = useCrumbsStateValue();
    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const { toggleFilterDrawer, isDrawerOpen } = useFilterDrawer();
    usePageName({ pageName: t('pages.skills') });
    const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, setPage } = useTablePagination();
    const { isTeamsEnv } = useApplicationStateValue();

    useEffect(() => {
        setPage(0);
    }, [skills?.length]);

    const onSkillClick = useCallback(
        (id: string) => {
            trackSkillClick({ trackId: Math.random() });
            const pathname = makeSkillRoute(id);
            if (isTeamsEnv) {
                setCrumbs([
                    {
                        name: 'Skills',
                        pathname: routes.SKILLS
                    },
                    {
                        pathname: pathname,
                        name: skills.find((skill) => skill.id.toString() === id)?.title || 'Skill'
                    }
                ]);
            } else {
                appendCrumb({
                    pathname: pathname,
                    name: skills.find((skill) => skill.id.toString() === id)?.title || 'Skill'
                });
            }
            navigate(pathname);
        },
        [skills, crumbs]
    );

    const skillsStatusOverallMemo = useMemo(() => {
        return (
            <SkillsStatusOverallWrapper isDisabled={isSkillsLoading} className='skill-filter-buttons'>
                {skillsStatusOverallInfo.map((status) => {
                    return (
                        <NumberStatusBox
                            key={status.key}
                            clickHandler={(key) => changeSkillStatusOverallActiveFilters(key as ESkillsStatuses)}
                            elementId={status.key}
                            isActive={activeSkillsStatusOverallFilters.includes(status.key)}
                            circleColor={status.circleColor}
                            label={status.label}
                            value={status.value}
                            icon={status.icon}
                            isLoading={status.isLoading}
                        />
                    );
                })}
            </SkillsStatusOverallWrapper>
        );
    }, [skillsStatusOverallInfo, activeSkillsStatusOverallFilters, isSkillsLoading]);

    const handleSkillFilterChange = (event: SelectChangeEvent<unknown>) => {
        const sortBy = event.target.value as ESkillSort;
        setSortOrder({
            sortBy: sortBy,
            direction:
                sortBy === ESkillSort.SCORE || sortBy === ESkillSort.WEEKS_IN_PROGRESS
                    ? EOrderDirection.DESC
                    : sortOrder.direction
        });
    };

    const setAscedingFilterOrderWay = () => {
        setSortOrder({
            sortBy: sortOrder.sortBy,
            direction: EOrderDirection.ASC
        });
    };

    const setDescedingFilterOrderWay = () => {
        setSortOrder({
            sortBy: sortOrder.sortBy,
            direction: EOrderDirection.DESC
        });
    };

    const onToggleSortKeyDown = useCallback(
        (e: KeyboardEvent<HTMLDivElement>) => {
            if (e.key === 'Enter') {
                if (sortOrder.direction === EOrderDirection.ASC) setDescedingFilterOrderWay();
                else setAscedingFilterOrderWay();
                e.stopPropagation();
            }
        },
        [sortOrder]
    );

    const skillsMemo = useMemo(() => {
        if (isSkillsLoading) return <Loading />;
        if (isError) return <SomethingWentWrongCard actionCallback={refetchData}></SomethingWentWrongCard>;
        if (skills.length === 0 && isFetchedDataProcessed)
            return <NoDataCard boxStyle={{ boxShadow: 'none' }} message='No data that match your filter criteria' />;

        return (
            <SkillListCardWrapper>
                {(rowsPerPage > 0 ? skills.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : skills)?.map(
                    (skill: IUserSkillVM, index: number) => {
                        return (
                            <SkillCard
                                key={skill.id}
                                index={index}
                                name={skill.title}
                                description={skill.description}
                                appIcons={skill.apps}
                                id={skill.id}
                                skillClickHandler={onSkillClick}
                                isAssignedControlActive
                                isAssigned={skill.isAssigned}
                                assignment={skill.assignment}
                                score={skill.score}
                                isUserFocus={skill.isUserFavorite}
                                isMasterLevelRequested={skill.isMasterFocus}
                                progressDoughnutChartType={progressDoughnutChartType(skill.assignment, skill.skillType)}
                                level={skill.assignment.level}
                                translations={{
                                    assignTooltipText: t('skills.tooltips.assignSkill'),
                                    unassignTooltipText: t('skills.tooltips.unassignSkill')
                                }}
                                isUserFavoriteControlDisabled={skill.isUserFavoriteControlDisabled}
                                toggleAssignCallback={toggleAssignCallback}
                                toggleFavoriteCallback={toggleFavoriteCallback}
                                skillType={skill.skillType}
                                maxLevel={skill.maxLevel}
                                userActivitySummary={skill.userActivitySummary}
                            ></SkillCard>
                        );
                    }
                )}
                <TablePagination
                    count={skills?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </SkillListCardWrapper>
        );
    }, [
        skills,
        isError,
        isSkillsLoading,
        onSkillClick,
        refetchData,
        toggleAssignCallback,
        toggleFavoriteCallback,
        isFetchedDataProcessed,
        page,
        rowsPerPage
    ]);

    const filtersMemo = useMemo(() => {
        return (
            <Filters
                isDisabled={isSkillsLoading}
                handleFilterValueChange={onFilterValueChange}
                categories={filterCategories}
                emptyQuickFilters={emptyQuickFilters}
                isQuickFiltersSelected={activeSkillsStatusOverallFilters.length > 0}
            />
        );
    }, [onFilterValueChange, isSkillsLoading, filterCategories, activeSkillsStatusOverallFilters, emptyQuickFilters]);

    const recommendationsMemo = useMemo(() => {
        return (
            <RecommendationSimpleCard
                successRecommendationCallback={successRecommendationCallback}
                cardItems={recommendations}
                isLoading={isRecommendationsLoading}
            />
        );
    }, [recommendations, isRecommendationsLoading, successRecommendationCallback]);

    const skillListTitleMemo = useMemo(() => {
        if (activeSkillsStatusOverallFilters.includes(ESkillsStatuses.ASSIGNED_SKILLS))
            return t('skills.statuses.assigned');
        if (activeSkillsStatusOverallFilters.includes(ESkillsStatuses.ATTAINED)) return t('skills.statuses.attained');
        if (activeSkillsStatusOverallFilters.includes(ESkillsStatuses.NEED_ATTENTION))
            return t('skills.statuses.needAttention');
        if (activeSkillsStatusOverallFilters.includes(ESkillsStatuses.PERSONAL_FOCUS))
            return t('skills.statuses.focus');
        if (activeSkillsStatusOverallFilters.includes(ESkillsStatuses.IN_PROGRESS))
            return t('skills.statuses.inProgress');
        return t('skills.skills');
    }, [activeSkillsStatusOverallFilters]);

    return (
        <RootBox>
            <FiltersProvider categories={filterCategories}>
                <RootInnerBox>
                    {isLgDown && (
                        <Drawer anchor='right' open={isDrawerOpen} onClose={toggleFilterDrawer}>
                            <CloseDrawerBox>
                                <CloseDrawerActionWrapper onClick={toggleFilterDrawer}>
                                    <CloseOutlined />
                                </CloseDrawerActionWrapper>
                            </CloseDrawerBox>
                            <FilterDrawerBox>{filtersMemo}</FilterDrawerBox>
                        </Drawer>
                    )}
                    <>
                        <MainContentBox>
                            <ContentHeaderBox>
                                {skillsStatusOverallMemo}
                                {isLgDown && recommendationsMemo}
                            </ContentHeaderBox>
                            <StyledPaper elevation={1}>
                                <HeaderWrapper>
                                    <TitleBox variant='h6'>
                                        {isLgDown && (
                                            <Tooltip title='Filters'>
                                                <FilterIconSpan onClick={toggleFilterDrawer}>
                                                    <FilterIcon />
                                                </FilterIconSpan>
                                            </Tooltip>
                                        )}
                                        <TitleSpan>{skillListTitleMemo}</TitleSpan>
                                        <SkillCountBox variant='body2'>({skills?.length ?? 0})</SkillCountBox>
                                    </TitleBox>
                                    <FilterBox isDisabled={isSkillsLoading}>
                                        {!isMdDown && <FilterLabel>{t('skills.sortBy')}</FilterLabel>}
                                        <FilterSelect
                                            IconComponent={UpArrowIcon}
                                            value={sortOrder.sortBy}
                                            onChange={handleSkillFilterChange}
                                            inputProps={{ 'aria-label': 'Filter by' }}
                                            MenuProps={{ 'aria-label': 'Filter by' }}
                                            aria-label='Filter By'
                                        >
                                            <MenuItem value={ESkillSort.NAME}>{t('skills.filters.name')}</MenuItem>
                                            <MenuItem value={ESkillSort.SCORE}>{t('skills.filters.score')}</MenuItem>
                                            <MenuItem value={ESkillSort.ASSIGNED_DATE}>
                                                {t('skills.filters.assignedDate')}
                                            </MenuItem>
                                            <MenuItem value={ESkillSort.WEEKS_IN_PROGRESS}>
                                                {t('skills.filters.weeksInProgress')}
                                            </MenuItem>
                                        </FilterSelect>
                                        {sortOrder.direction === EOrderDirection.ASC ? (
                                            <Tooltip title={t('tooltips.showDescending')}>
                                                <OrderSpan
                                                    tabIndex={0}
                                                    onClick={setDescedingFilterOrderWay}
                                                    onKeyDown={onToggleSortKeyDown}
                                                >
                                                    <UpArrowFullIcon />
                                                </OrderSpan>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title={t('tooltips.showAscending')}>
                                                <OrderSpan
                                                    tabIndex={0}
                                                    onClick={setAscedingFilterOrderWay}
                                                    onKeyDown={onToggleSortKeyDown}
                                                >
                                                    <DownArrowFullIcon />
                                                </OrderSpan>
                                            </Tooltip>
                                        )}
                                    </FilterBox>
                                </HeaderWrapper>
                                <SkillListWrapper
                                    className={[
                                        'skills-inner-box',
                                        'skill-card-wrapper',
                                        skills ? 'not-empty' : 'empty'
                                    ].join(' ')}
                                >
                                    {skillsMemo}
                                </SkillListWrapper>
                            </StyledPaper>
                        </MainContentBox>
                        {!isLgDown && (
                            <SideBox>
                                {recommendationsMemo}
                                {filtersMemo}
                            </SideBox>
                        )}
                    </>
                </RootInnerBox>
            </FiltersProvider>
        </RootBox>
    );
};

export default Skills;
