import { getTokenAndHeaders, IAuthParams, useAuthParams } from 'nulia-ui';
import { apiInstance } from '../util/apiInstance';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { mapStatements } from '../mappers';
import { EApiQueryKey } from '../../types/enums/EApiQueryKey';
import { IStatementVM } from '../../interfaces/views/IStatementVM';

export const getStatements = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`statements`, headers);
    return mapStatements(data);
};

export const useGetStatements: () => UseQueryResult<IStatementVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.STATEMENTS_FETCH_ALL],
        queryFn: async () => {
            return getStatements(authParams);
        },
        refetchOnWindowFocus: false
    });
};
