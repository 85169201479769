export enum EApiQueryKey {
    // Accounts query service
    ACCOUNTS_FETCH_ALL = 'accounts.getAll',
    ACCOUNTS_DETAILS_FETCH = 'accounts.details.get',
    ACCOUNTS_FETCH_RESELLERS = 'accounts.getResellers',
    ACCOUNTS_FETCH_CUSTOMERS = 'accounts.getCustomers',
    RESOURCES_FETCH_ALL = 'resources.getAll',
    CUSTOMER_ADMINISTRATORS_FETCH = 'customers.administrators.getAll',
    CUSTOMER_SETTINGS_FETCH = 'customers.settings.getAll',
    CUSTOMER_DETAILS_FETCH = 'customers.getDetails',
    CUSTOMER_LICENSE_DETAILS = 'customers.licenseDetails.get',
    CUSTOMER_COLLECTOR_STATUS = 'customers.collectorStatus.get',
    CUSTOMER_NOTES = 'customers.notes.get',
    CUSTOMER_CONNECT_URL = 'customers.connectUrl.get',

    // Partner query service
    PARTNER_FETCH_LICENSE_SUMMARY = 'partner.getLicenseSummary',

    // Folders query service
    FOLDER_LICENSE_SUMMARY_FETCH = 'folders.getFolderLicenseSummary',
    PARTNERS_FETCH_ALL = 'partners.getAll',
    PARTNER_USERS_FETCH_ALL = 'partner.users.getAll',

    STATEMENTS_FETCH_ALL = 'statements.getAll',

    ACTIVITY_LOGS_FOLDER_FETCH_ALL = 'activityLogs.folder.getAll',
    ACTIVITY_LOGS_PARTNER_FETCH_ALL = 'activityLogs.partner.getAll',
    ACTIVITY_LOGS_CUSTOMER_FETCH_ALL = 'activityLogs.customer.getAll'
}
