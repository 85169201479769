import { styled } from '@mui/system';

export const RootBox = styled('div')({
    height: '100vh',
    width: '100%',
    maxWidth: '100%'
});

export const DownloadIconBox = styled('div')<{ isDisabled: boolean }>(({ theme, isDisabled }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    justifySelf: 'flex-end',
    marginRight: '32px',
    cursor: isDisabled ? 'auto' : 'pointer',
    borderRadius: '24px',
    padding: '8px',
    '&:hover': !isDisabled && {
        color: theme.palette.text.primary,
        background: 'rgba(0, 0, 0, 0.04)'
    }
}));
