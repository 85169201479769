import { ChangeEvent, FC, useMemo, useRef } from 'react';
import {
    FiltersWrapper,
    RootInnerBox,
    SearchInputWrapper,
    StyledMain,
    StyledSearchInput,
    StyledTable,
    StyledTableContainer
} from './Style';
import { ITableHeadCell } from '../../../../../interfaces/ITableHeaderCell';
import Table from '../../../../../ui/general/table/Table';
import { useMediaQuery, useTheme } from '@mui/material';
import Filters from '../../../../../ui/filters/filters/Filters';
import { useAdminOutcomesStateValue } from '../../../../../contexts/AdminOutcomesContext';
import { FiltersProvider } from '../../../../../contexts/FiltersContext';
import useFilterDrawer from '../../../../../hooks/useFilterDrawer';
import { ITenantOutcomeUserDTO } from '../../../../../interfaces/dtos/ITenantOutcomeUserDTO';

interface ITableHeader {
    image: string;
    name: string;
    department: string;
    region: string;
    applicableOutcomeCount: string;
    sourceLicenseType: string;
}

const UserSelection: FC = () => {
    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const {
        onFilterValueChangeUsersTab,
        userSelectionFilterCategories,
        users,
        changeSelectedUsersList,
        refetchUserSelectionData,
        isLoadingFetchingUserSelectionData,
        isErrorFetchingUserSelectionData,
        selectedUsersList,
        selectedOutcomesList,
        searchText,
        setSearchText
    } = useAdminOutcomesStateValue();
    const { toggleFilterDrawer, isDrawerOpen } = useFilterDrawer();
    const userSelectionPropertyKeys = useRef<string[]>([
        'image',
        'name',
        'department',
        'applicableOutcomeCount',
        'region'
    ]);
    const tableHeadCells = useRef<ITableHeadCell<ITableHeader>[]>([
        {
            id: 'image',
            numeric: false,
            disablePadding: true,
            label: ''
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Name'
        },
        {
            id: 'department',
            numeric: false,
            disablePadding: false,
            label: 'Department'
        },
        {
            id: 'applicableOutcomeCount',
            numeric: false,
            disablePadding: false,
            label: 'Outcomes',
            align: 'center',
            customRender(value) {
                return <>{`${value} / ${selectedOutcomesList.length}`}</>;
            },
            customSort(a: ITenantOutcomeUserDTO, b: ITenantOutcomeUserDTO, order) {
                if (order === 'asc') {
                    if (a.applicableOutcomeCount === b.applicableOutcomeCount) {
                        return ('' + a.name).localeCompare(b.name);
                    }
                    return a.applicableOutcomeCount < b.applicableOutcomeCount ? -1 : 1;
                }
                if (order === 'desc') {
                    if (a.applicableOutcomeCount === b.applicableOutcomeCount) {
                        return ('' + a.name).localeCompare(b.name);
                    }
                    return a.applicableOutcomeCount > b.applicableOutcomeCount ? -1 : 1;
                }
                return 0;
            }
        },
        {
            id: 'region',
            numeric: false,
            disablePadding: false,
            label: 'Region',
            align: 'center'
        }
    ]);

    const filtersMemo = useMemo(() => {
        return (
            <Filters
                isDisabled={false}
                handleFilterValueChange={onFilterValueChangeUsersTab}
                categories={userSelectionFilterCategories}
                isQuickFiltersSelected={false}
                dropdownMode
                // filterElements={skills}
            />
        );
    }, [onFilterValueChangeUsersTab, userSelectionFilterCategories]);

    const disableRowsIdsMemo = useMemo(() => {
        return users.reduce<(string | number)[]>((arr, user) => {
            if (user.applicableOutcomeCount === 0) arr.push(user.id);
            return arr;
        }, []);
    }, [users]);

    return (
        <StyledMain>
            <FiltersProvider categories={userSelectionFilterCategories}>
                <RootInnerBox>
                    <StyledTableContainer>
                        <SearchInputWrapper className='search-box'>
                            <StyledSearchInput
                                placeholder='Search Users'
                                value={searchText || ''}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    if (setSearchText) setSearchText(e.target.value);
                                }}
                            />
                        </SearchInputWrapper>
                        <StyledTable aria-labelledby='tableTitle' size='medium'>
                            <Table<ITenantOutcomeUserDTO, ITableHeader>
                                setSelectedCallback={changeSelectedUsersList}
                                headCells={tableHeadCells.current}
                                data={users}
                                propertyKeys={userSelectionPropertyKeys.current}
                                tableTitlePlural='Users'
                                isFilterControlVisible={isLgDown}
                                isFilterDrawerOpen={isDrawerOpen}
                                filterControlToggleHandler={toggleFilterDrawer}
                                filtersDrawerMemo={filtersMemo}
                                isMultipleSelectionAvailable
                                isError={isErrorFetchingUserSelectionData}
                                isLoading={isLoadingFetchingUserSelectionData}
                                refetchDataCallback={refetchUserSelectionData}
                                isImageColumnPresent
                                initialSelectedRows={selectedUsersList}
                                userImageInfoColumn='name'
                                userImageInfoColumnPosition='bottom'
                                disableRowsIds={disableRowsIdsMemo}
                                initialOrderBy='applicableOutcomeCount'
                                initialOrderDirection='desc'
                            />
                        </StyledTable>
                    </StyledTableContainer>
                    {!isLgDown && <FiltersWrapper>{filtersMemo}</FiltersWrapper>}
                </RootInnerBox>
            </FiltersProvider>
        </StyledMain>
    );
};

export default UserSelection;
