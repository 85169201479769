import { IPartnerVM } from '../interfaces/views/IPartnerVM';
import { IResellerVM } from '../interfaces/views/IResellerVM';
import { createContext, FC, useContext, PropsWithChildren } from 'react';
import { useGetPartnerResellersQuery } from '../services/AccountsQueryService';
import { useGetPartnersQuery } from '../services/PartnerQueryService';

export interface IAdminContext {
    data?: IPartnerVM[];
    isPartnersDataLoading: boolean;
    isPartnersDataFetchingError: boolean;
    refetchPartnersCallback: () => void;
    resellersData?: IResellerVM[];
    isResellersDataLoading: boolean;
    isResellersDataFetchingError: boolean;
}

export const AdminContext = createContext<IAdminContext>({} as IAdminContext);

interface IProps {}

export const AdminProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const {
        data: fetchedPartnersData,
        isLoading: isPartnersDataLoading,
        isError: isPartnersDataFetchingError,
        refetch: refetchPartnersCallback
    } = useGetPartnersQuery();
    const {
        data: fetchedResellersData,
        isLoading: isResellersDataLoading,
        isError: isResellersDataFetchingError
    } = useGetPartnerResellersQuery();

    const adminState: IAdminContext = {
        data: fetchedPartnersData,
        isPartnersDataLoading,
        isPartnersDataFetchingError,
        refetchPartnersCallback,
        resellersData: fetchedResellersData,
        isResellersDataFetchingError,
        isResellersDataLoading
    };

    return <AdminContext.Provider value={adminState}>{children}</AdminContext.Provider>;
};

export const useAdminStateValue: () => IAdminContext = () => useContext(AdminContext);
