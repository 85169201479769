import { useMutation, UseMutationResult, useQuery, UseQueryResult } from '@tanstack/react-query';
import { getTokenAndHeaders, IAuthParams, useAuthParams } from 'nulia-ui';
import { EApiQueryKey } from '../types/enums/EApiQueryKey';
import { apiInstance } from './util/apiInstance';
import {
    mapAdministrators,
    mapCustomerCollectorStatus,
    mapCustomerDetails,
    mapCustomerLicenseDetails,
    mapCustomers,
    mapCustomerSettings,
    mapResellers
} from './mappers';
import { IAccountDetailsVM } from '../interfaces/views/IAccountDetailsVM';
import { IPostAddResellerDTO } from '../interfaces/dtos/IPostAddResellerDTO';
import { IResellerVM } from '../interfaces/views/IResellerVM';
import { ICustomerVM } from '../interfaces/views/ICustomerVM';
import { IPostAddCustomerDTO } from '../interfaces/dtos/IPostAddCustomerDTO';
import { IAdministratorVM } from '../interfaces/views/IAdministratorVM';
import { ICustomerSettingsVM } from '../interfaces/views/ICustomerSettingsVM';
import { ICustomerSettingsDTO } from '../interfaces/dtos/ICustomerSettingsDTO';
import { ICustomerDetailsVM } from '../interfaces/views/ICustomerDetailsVM';
import { ICustomerLicenseDetailsVM } from '../interfaces/views/ICustomerLicenseDetailsVM';
import { IUpdateCustomerDTO } from '../interfaces/dtos/IUpdateCustomerDTO';
import { ICollectorStatusVM } from '../interfaces/views/ICollectorStatusVM';
import { INotesVM } from '../interfaces/views/INotesVM';

// GET ACCOUNT DETAILS
export const getAccountDetails = async (authParams: IAuthParams) => {
    // const { headers } = await getTokenAndHeaders(authParams);
    // const { data } = await apiInstance.get(`accounts`, headers);
    return [];
};

export const useGetAccountDetailsQuery: () => UseQueryResult<IAccountDetailsVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.ACCOUNTS_DETAILS_FETCH],
        queryFn: async () => {
            return getAccountDetails(authParams);
        },
        refetchOnWindowFocus: false,
        enabled: false
    });
};

// POST ADD RESELLER
export const postAddReseller: (
    variables: IPostAddResellerDTO & { authParams: IAuthParams }
) => Promise<undefined> = async (variables) => {
    const { authParams, ...addResellerRequestData } = variables;
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data }
    } = await apiInstance.post(`folder`, addResellerRequestData, headers);
    return data;
};

export const usePostAddReseller: () => UseMutationResult<undefined, Error, IPostAddResellerDTO> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables: IPostAddResellerDTO) => {
            return postAddReseller({ ...variables, authParams });
        }
    });
};

// GET PARTNER RESELLERS
export const getPartnerResellers = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: {
            data: { folders }
        }
    } = await apiInstance.get(`folder`, headers);
    return mapResellers(folders);
};

export const useGetPartnerResellersQuery: () => UseQueryResult<IResellerVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.ACCOUNTS_FETCH_RESELLERS],
        queryFn: async () => {
            return getPartnerResellers(authParams);
        },
        refetchOnWindowFocus: false
    });
};

// GET PARTNER CUSTOMER
export const getPartnerCustomers = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: {
            data: { customers }
        }
    } = await apiInstance.get(`customer`, headers);
    return mapCustomers(customers);
};

export const useGetPartnerCustomersQuery: () => UseQueryResult<ICustomerVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.ACCOUNTS_FETCH_CUSTOMERS],
        queryFn: async () => {
            return getPartnerCustomers(authParams);
        },
        refetchOnWindowFocus: false
    });
};

// POST ADD CUSTOMER
export const postAddCustomer: (
    variables: IPostAddCustomerDTO & { authParams: IAuthParams }
) => Promise<undefined> = async (variables) => {
    const { authParams, ...addCustomerRequestData } = variables;
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data }
    } = await apiInstance.post(`customer`, addCustomerRequestData, headers);
    return data;
};

export const usePostAddCustomer: () => UseMutationResult<undefined, Error, IPostAddCustomerDTO> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables: IPostAddCustomerDTO) => {
            return postAddCustomer({ ...variables, authParams });
        }
    });
};

// GET PARTNER CUSTOMER ADMINISTRATORS
export const getCustomerAdministrators = async (authParams: IAuthParams, customerId: string | undefined) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`customer/${customerId}/administrator`, headers);
    return mapAdministrators(data);
};

export const useGetCustomerAdministratorsQuery: (
    customerId: string | undefined
) => UseQueryResult<IAdministratorVM[], Error> = (customerId) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.CUSTOMER_ADMINISTRATORS_FETCH, customerId],
        queryFn: async () => {
            return getCustomerAdministrators(authParams, customerId);
        },
        refetchOnWindowFocus: false
    });
};

// POST ENABLE LICENSE SYNC
export const postEnableLicenseSync: (
    authParams: IAuthParams,
    customerId: string | undefined
) => Promise<undefined> = async (authParams, customerId) => {
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data }
    } = await apiInstance.post(`customer/${customerId}/licenseSync`, undefined, headers);
    return data;
};

export const usePostEnableLicenseSync: () => UseMutationResult<undefined, Error, { customerId: string }> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables: { customerId: string }) => {
            return postEnableLicenseSync(authParams, variables.customerId);
        }
    });
};

// DELETE DISABLE LICENSE SYNC
export const deleteDisableLicenseSync: (
    authParams: IAuthParams,
    customerId: string | undefined
) => Promise<undefined> = async (authParams, customerId) => {
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data }
    } = await apiInstance.delete(`customer/${customerId}/licenseSync`, headers);
    return data;
};

export const useDeleteDisableLicenseSync: () => UseMutationResult<undefined, Error, { customerId: string }> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables) => {
            return deleteDisableLicenseSync(authParams, variables.customerId);
        }
    });
};

// GET CUSTOMER SETTINGS
export const getCustomerSettings = async (authParams: IAuthParams, customerId: string | undefined) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`customer/${customerId}/settings`, headers);
    return mapCustomerSettings(data);
};

export const useGetCustomerSettingsQuery: (
    customerId: string | undefined
) => UseQueryResult<ICustomerSettingsVM, Error> = (customerId) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.CUSTOMER_SETTINGS_FETCH, customerId],
        queryFn: async () => {
            return getCustomerSettings(authParams, customerId);
        },
        refetchOnWindowFocus: false
    });
};

// POST CUSTOMER SETTINGS
export const postCustomerSettings: (
    variables: { customerId: string; customerSettings: ICustomerSettingsDTO } & { authParams: IAuthParams }
) => Promise<undefined> = async (variables) => {
    const { headers } = await getTokenAndHeaders(variables.authParams);

    const {
        data: { data }
    } = await apiInstance.post(`customer/${variables.customerId}/settings`, variables.customerSettings, headers);
    return data;
};

export const usePostCustomerSettings: () => UseMutationResult<any, Error, any> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: async (variables: { customerId: string; customerSettings: ICustomerSettingsDTO }) => {
            return postCustomerSettings({ ...variables, authParams });
        }
    });
};

// GET CUSTOMER DETAILS
export const getCustomerDetails = async (authParams: IAuthParams, customerId: string | undefined) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`customer/${customerId}`, headers);
    return mapCustomerDetails(data);
};

export const useGetCustomerDetailsQuery: (
    customerId: string | undefined
) => UseQueryResult<ICustomerDetailsVM, Error> = (customerId) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.CUSTOMER_DETAILS_FETCH, customerId],
        queryFn: async () => {
            return getCustomerDetails(authParams, customerId);
        },
        refetchOnWindowFocus: false
    });
};

// GET CUSTOMER LICENSE DETAILS
export const getCustomerLicenseDetails = async (authParams: IAuthParams, customerId: string | undefined) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`customer/${customerId}/license`, headers);
    return mapCustomerLicenseDetails(data);
};

export const useGetCustomerLicenseDetailsQuery: (
    customerId: string | undefined
) => UseQueryResult<ICustomerLicenseDetailsVM, Error> = (customerId) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.CUSTOMER_LICENSE_DETAILS, customerId],
        queryFn: async () => {
            return getCustomerLicenseDetails(authParams, customerId);
        },
        refetchOnWindowFocus: false
    });
};

// POST ADD CUSTOMER
export const postAddCustomerAdministrator: (
    variables: { customerId: string; email: string; name: string } & { authParams: IAuthParams }
) => Promise<undefined> = async (variables) => {
    const { headers } = await getTokenAndHeaders(variables.authParams);

    const {
        data: { data }
    } = await apiInstance.post(
        `customer/${variables.customerId}/administrator`,
        {
            name: variables.name,
            email: variables.email
        },
        headers
    );
    return data;
};

export const usePostAddCustomerAdministrator: () => UseMutationResult<any, Error, any> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: async (variables: { customerId: string; email: string; name: string }) => {
            return postAddCustomerAdministrator({ ...variables, authParams });
        }
    });
};

// POST ADD CUSTOMER LICENSES
export const postAddCustomerLicenses: (
    variables: { customerId: string; numLicenses: number } & { authParams: IAuthParams }
) => Promise<undefined> = async (variables) => {
    const { headers } = await getTokenAndHeaders(variables.authParams);

    const {
        data: { data }
    } = await apiInstance.post(
        `customer/${variables.customerId}/license`,
        {
            numLicenses: variables.numLicenses
        },
        headers
    );
    return data;
};

export const usePostAddCustomerLicenses: () => UseMutationResult<any, Error, any> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: async (variables: { customerId: string; numLicenses: number }) => {
            return postAddCustomerLicenses({ ...variables, authParams });
        }
    });
};

// POST REMOVE CUSTOMER LICENSES
export const postRemoveCustomerLicenses: (
    variables: { customerId: string; numLicenses: number } & { authParams: IAuthParams }
) => Promise<undefined> = async (variables) => {
    const { headers } = await getTokenAndHeaders(variables.authParams);

    const {
        data: { data }
    } = await apiInstance.delete(`customer/${variables.customerId}/license`, {
        data: {
            numLicenses: variables.numLicenses
        },
        headers: headers.headers
    });
    return data;
};

export const usePostRemoveCustomerLicenses: () => UseMutationResult<any, Error, any> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: async (variables: { customerId: string; numLicenses: number }) => {
            return postRemoveCustomerLicenses({ ...variables, authParams });
        }
    });
};

// DELETE CUSTOMER ADMINISTRATOR
export const deleteCustomerAdministrator: (
    authParams: IAuthParams,
    customerId: string | undefined,
    administratorId: string
) => Promise<undefined> = async (authParams, customerId, administratorId) => {
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data }
    } = await apiInstance.delete(`customer/${customerId}/administrator/${administratorId}`, headers);
    return data;
};

export const useDeleteCustomerAdministrator: () => UseMutationResult<
    undefined,
    Error,
    { customerId: string; administratorId: string }
> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables) => {
            return deleteCustomerAdministrator(authParams, variables.customerId, variables.administratorId);
        }
    });
};

// POST UPDATE CUSTOMER
export const postUpdateCustomer: (
    variables: { customerId: string; updateRequestDTO: IUpdateCustomerDTO } & { authParams: IAuthParams }
) => Promise<undefined> = async (variables) => {
    const { headers } = await getTokenAndHeaders(variables.authParams);

    const {
        data: { data }
    } = await apiInstance.post(`customer/${variables.customerId}`, variables.updateRequestDTO, headers);
    return data;
};

export const usePostUpdateCustomer: () => UseMutationResult<any, Error, any> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: async (variables: { customerId: string; updateRequestDTO: IUpdateCustomerDTO }) => {
            return postUpdateCustomer({ ...variables, authParams });
        }
    });
};

// GET CUSTOMER COLLECTOR STATUS
export const getCustomerCollectorStatus = async (authParams: IAuthParams, customerId: string | undefined) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`customer/${customerId}/status`, headers);
    return mapCustomerCollectorStatus(data);
};

export const useGetCustomerCollectorStatusQuery: (
    customerId: string | undefined
) => UseQueryResult<ICollectorStatusVM, Error> = (customerId) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.CUSTOMER_COLLECTOR_STATUS, customerId],
        queryFn: async () => {
            return getCustomerCollectorStatus(authParams, customerId);
        },
        refetchOnWindowFocus: false
    });
};

// GET CUSTOMER NOTES
export const getCustomerNotes = async (authParams: IAuthParams, customerId: string | undefined) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`customer/${customerId}/notes`, headers);
    return data;
};

export const useGetCustomerNotesQuery: (customerId: string | undefined) => UseQueryResult<INotesVM, Error> = (
    customerId
) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.CUSTOMER_NOTES, customerId],
        queryFn: async () => {
            return getCustomerNotes(authParams, customerId);
        },
        refetchOnWindowFocus: false
    });
};

// PUT UPDATE NOTES
export const putNotes: (
    variables: { customerId: string; notes: string } & { authParams: IAuthParams }
) => Promise<undefined> = async (variables) => {
    const { headers } = await getTokenAndHeaders(variables.authParams);

    const {
        data: { data }
    } = await apiInstance.put(
        `customer/${variables.customerId}/notes`,
        {
            notes: variables.notes
        },
        {
            headers: {
                ...headers.headers
            }
        }
    );
    return data;
};

export const usePutNotes: () => UseMutationResult<any, Error, any> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: async (variables: { customerId: string; notes: string }) => {
            return putNotes({ ...variables, authParams });
        }
    });
};
