import { FC } from 'react';

interface IProps {
    color?: string;
    width?: string;
    height?: string;
}

const BlueWarningIcon: FC<IProps> = ({ color, width, height }) => {
    return (
        <svg
            width={width || '17'}
            height={height || '20'}
            viewBox='0 0 17 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M1 6.25099L8.30251 2L15.8387 6.25099V14.5806L8.30251 18.7742L1 14.5806V6.25099Z'
                stroke={color || '#349FEE'}
                strokeWidth='2'
            />
            <path
                d='M8.41919 6.41934V10.4193'
                stroke={color || '#349FEE'}
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M8.41919 14.4193H8.42919'
                stroke={color || '#349FEE'}
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M8.41919 14.4193H8.42919'
                stroke={color || '#349FEE'}
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default BlueWarningIcon;
