import { FC, useMemo } from 'react';
import {
    CollectorStatusInfoItemsWrapper,
    RootBox,
    TenantStatusBox,
    TenantStatusContentTypography,
    TenantStatusTitleTypography
} from './Style';
import { useTranslation } from 'react-i18next';
import {
    BasicCard,
    EAnonymizedReportStatus,
    ETenantStatusColor,
    formatDate,
    Loading,
    SettingInfoItem,
    SomethingWentWrongCard
} from 'nulia-ui';
import { ICollectorStatusVM } from '../../../../interfaces/views/ICollectorStatusVM';
import { EStatus } from '../../../../components/basic-status-card/BasicStatusCard';

interface IProps {
    collectorStatus?: ICollectorStatusVM;
    isLoading: boolean;
    isError: boolean;
    refetch: () => void;
}

const CollectorStatus: FC<IProps> = ({ collectorStatus, isError, isLoading, refetch }) => {
    const { t } = useTranslation();

    const collectorStatusMemo = useMemo(() => {
        if (collectorStatus)
            return (
                <>
                    <CollectorStatusInfoItemsWrapper>
                        <SettingInfoItem
                            status={collectorStatus.anonymizedReportsState}
                            label={t('settings.organization.anonimyzedReports')}
                            value={
                                collectorStatus?.anonymizedReports === EAnonymizedReportStatus.ON
                                    ? t('settings.organization.anonymizedReportsOn')
                                    : t('settings.organization.anonymizedReportsOff')
                            }
                        />
                        <SettingInfoItem
                            status={collectorStatus.lastScoreDateState}
                            label={t('settings.organization.lastScoreDate')}
                            value={formatDate(collectorStatus?.lastScoreDate)}
                        />
                        <SettingInfoItem
                            status={collectorStatus.usersScoredState}
                            label={t('settings.organization.usersScored')}
                            value={`${collectorStatus?.actualNumUsersScored}/${
                                collectorStatus?.possibleNumUsersScored
                            }`}
                        />
                    </CollectorStatusInfoItemsWrapper>
                    <TenantStatusBox>
                        <TenantStatusTitleTypography variant='overline'>
                            {t('settings.organization.recommendation')}
                        </TenantStatusTitleTypography>
                        <TenantStatusContentTypography variant='caption'>
                            {collectorStatus?.recommendation}
                        </TenantStatusContentTypography>
                    </TenantStatusBox>
                </>
            );
        return <></>;
    }, [collectorStatus]);

    const collectorStatusOverallMemo = useMemo(() => {
        switch (collectorStatus?.overallState) {
            case ETenantStatusColor.GREEN:
                return EStatus.SUCCESS;
            case ETenantStatusColor.RED:
                return EStatus.ERROR;
            case ETenantStatusColor.YELLOW:
                return EStatus.WARNING;
            default:
                return undefined;
        }
    }, [collectorStatus]);

    const contentMemo = useMemo(() => {
        if (isLoading) return <Loading />;
        if (isError) return <SomethingWentWrongCard actionCallback={refetch} boxStyle={{ boxShadow: 'none' }} />;
        return collectorStatusMemo;
    }, [collectorStatusMemo, isLoading, isError, refetch]);

    return (
        <RootBox>
            <BasicCard title='Collector status' status={collectorStatusOverallMemo}>
                {contentMemo}
            </BasicCard>
        </RootBox>
    );
};

export default CollectorStatus;
