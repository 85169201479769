import { Typography, Select, MenuItem } from '@mui/material';
import { styled } from '@mui/system';

export const CustomersBox = styled('div')(({ theme }) => ({
    minWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
}));

export const CardBox = styled('div')(({ theme }) => ({
    width: '100%',
    borderRadius: 4,
    boxShadow: '0 0.5px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 4px 0.5px rgba(0, 0, 0, 0.08)',
    backgroundColor: '#ffffff',
    paddingTop: 24
}));

export const HeaderActionBox = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px'
});

export const ButtonWrapperBox = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px'
});

export const MainContentBox = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: '16px',
    marginTop: '16px',
    [theme.breakpoints.down('lg')]: {
        display: 'flex',
        flexDirection: 'column-reverse'
    }
}));

export const LicenseSummaryFilterBox = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '24px',
    [theme.breakpoints.down('lg')]: {
        flexDirection: 'column-reverse'
    }
}));

export const FiltersBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    minWidth: '300px'
});

export const FilterByTypography = styled(Typography)(() => ({
    fontSize: '12px',
    fontFamily: 'Ubuntu',
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.88)',
    letterSpacing: '2px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    paddingBottom: '2px'
}));

export const StyledSelect = styled(Select<string[]>)(({ theme }) => ({
    '.MuiSvgIcon-root': {
        top: 3
    },
    '.MuiSelect-select': {
        outline: '0 !important',
        width: '100% !important',
        overflow: 'hidden',
        display: 'inline-block !important',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        position: 'relative',
        top: '2px',
        fontWeight: 'bold !important',
        color: 'rgba(0, 0, 0, 0.88) !important',
        letterSpacing: '0.15px',
        [theme.breakpoints.down('lg')]: {
            fontSize: '14px !important',
            width: '120px'
        }
    },
    '.MuiSelect-icon': {
        transform: 'rotate(180deg)'
    },
    '.MuiSelect-iconOpen': {
        transform: 'rotate(0deg)'
    }
}));

export const StyledMenuItem = styled(MenuItem)({});

export const MenuItemTitleSpan = styled('span')({
    paddingLeft: 6,
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '28px',
    letterSpacing: '0.5px'
});
