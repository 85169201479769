import { FC } from 'react';
import { ExternalLink, Play, File } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { EActivityType } from '../../../interfaces/enums/EActivityType';
import { ButtonRoot, IconBox } from './Style';

interface IProps {
    clickHandler: () => void;
    type: EActivityType;
}

const ActivityButton: FC<IProps> = ({ clickHandler, type }) => {
    const { t } = useTranslation();
    return (
        <ButtonRoot onClick={clickHandler}>
            <IconBox aria-hidden component='span'>
                {type === EActivityType.TRY_IT || type === EActivityType.LEARN ? (
                    <ExternalLink style={{ width: 16, height: 16 }} />
                ) : type === EActivityType.WATCH ? (
                    <Play style={{ width: 16, height: 16 }} />
                ) : type === EActivityType.VIEW ? (
                    <File style={{ width: 16, height: 16 }} />
                ) : undefined}
            </IconBox>
            {type === EActivityType.TRY_IT
                ? t('components.activityButton.tryIt')
                : type === EActivityType.WATCH
                  ? t('components.activityButton.watch')
                  : type === EActivityType.VIEW
                    ? t('components.activityButton.view')
                    : type === EActivityType.LEARN
                      ? t('components.activityButton.learn')
                      : undefined}
        </ButtonRoot>
    );
};

export default ActivityButton;
