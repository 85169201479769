import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Page from '../../components/page/Page';
import PageContent from '../../components/page-content/PageContent';
// import UsageSummary from './usage-summary/UsageSummary';
import {
    ButtonWrapperBox,
    CardBox,
    CustomersBox,
    FilterByTypography,
    FiltersBox,
    HeaderActionBox,
    LicenseSummaryFilterBox,
    MainContentBox,
    MenuItemTitleSpan,
    StyledMenuItem,
    StyledSelect
} from './Style';
import AccountsTable from './table/AccountsTable';
import { useAccountsStateValue } from '../../contexts/AccountsContext';
import UsageSummarySimple from './usage-summary-simple/UsageSummarySimple';
import { Input, SelectChangeEvent } from '@mui/material';
import { PrimaryButton, useApiStateValue, useModal, UpArrowIcon } from 'nulia-ui';
import ActivityLog from '../../components/accounts/activity-log/ActivityLog';
import AddCustomerDialog from '../../components/dialogs/add-customer-dialog/AddCustomerDialog';
import AddResellerDialog from '../../components/dialogs/add-reseller-dialog/AddResellerDialog';
import { EApiQueryKey } from '../../types/enums/EApiQueryKey';
import { useTranslation } from 'react-i18next';

const initialFilterMenuItems = [
    {
        id: 'All Customers',
        name: 'All Customers'
    },
    {
        id: 'My Customers',
        name: 'My Customers'
    }
];

const Accounts: FC = () => {
    const [resellerFilter, setResellerFilter] = useState<string | 'All Customers' | 'My Customers'>('All Customers');
    // const getAccountData = useGetAccountData();
    const {
        customersData,
        licenseSummaryData,
        isLicenseSummaryDataLoading,
        isLicenseSummaryDataFetchingError,
        refetchLicenseSummaryDataCallback,
        resellersData,
        isCustomersDataLoading,
        refetchUsageSummary,
        selectedResellerId,
        refetchCustomersData,
        activityLogData,
        isActivityLogDataError,
        isActivityLogDataLoading
    } = useAccountsStateValue();
    const { isOpen: isAddCustomerOpen, setOpen: setAddCustomerOpen } = useModal({ defaultOpen: false });
    const { isOpen: isAddResellerOpen, setOpen: setAddResellerOpen } = useModal({ defaultOpen: false });
    const { invalidateQueryCache } = useApiStateValue();
    const { t } = useTranslation();
    const [filterMenuItems, setFilterMenuItems] = useState<{ id: string; name: string }[]>([]);

    useEffect(() => {
        if (resellersData)
            setFilterMenuItems([
                ...initialFilterMenuItems,
                ...resellersData.map((reseller) => {
                    return {
                        id: reseller.id,
                        name: reseller.name
                    };
                })
            ]);
    }, [resellersData]);

    const toggleAddCustomer = () => {
        setAddCustomerOpen(!isAddCustomerOpen);
    };
    const toggleAddReseller = () => {
        setAddResellerOpen(!isAddResellerOpen);
    };

    const onChangeLicenseCountCallback = useCallback(() => {
        refetchCustomersData();
        invalidateQueryCache(EApiQueryKey.FOLDER_LICENSE_SUMMARY_FETCH);
        refetchUsageSummary(selectedResellerId);
    }, [selectedResellerId]);

    const onChangeResellerFilter = (event: SelectChangeEvent<unknown>) => {
        let resellerId: string | undefined = event.target.value as string;
        setResellerFilter(resellerId);
        if ('All Customers' === resellerId) resellerId = undefined;
        refetchUsageSummary(resellerId);
    };

    const headerLabelMemo: string | undefined = useMemo(() => {
        let selectedReseller = undefined;
        if (selectedResellerId)
            selectedReseller = resellersData?.find((reseller) => reseller.id === selectedResellerId);
        let headerTitle = undefined;
        if (resellerFilter === 'All Customers' || resellerFilter === 'My Customers') headerTitle = resellerFilter;
        else headerTitle = selectedReseller?.name;
        return headerTitle;
    }, [selectedResellerId, resellersData]);

    const licenseSummaryMemo = useMemo(() => {
        let selectedReseller = undefined;
        if (selectedResellerId)
            selectedReseller = resellersData?.find((reseller) => reseller.id === selectedResellerId);
        return (
            <UsageSummarySimple
                isError={isLicenseSummaryDataFetchingError}
                isLoading={licenseSummaryData === undefined || isLicenseSummaryDataLoading}
                allocatedNuliaLicenses={licenseSummaryData?.nuliaLicensesAllocated || 0}
                assignedNuliaLicenses={licenseSummaryData?.nuliaLicensesAssigned || 0}
                unassignedNuliaLicenses={licenseSummaryData?.nuliaLicensesUnassigned || 0}
                totalOfficeLicenses={licenseSummaryData?.m365LicensedUsers || 0}
                totalOfficeWithNoLicenses={licenseSummaryData?.m365UsersWithoutNuliaLicenses || 0}
                totalNumberOfCustomers={licenseSummaryData?.totalCustomers || 0}
                totalNumberOfResellers={licenseSummaryData?.totalResellers}
                refetchDataCallback={refetchLicenseSummaryDataCallback}
                isRenameResellerOptionAvailable={!!selectedReseller}
                initialReseller={selectedReseller ? selectedReseller.id : undefined}
                headerTitle={headerLabelMemo}
            />
        );
    }, [
        isLicenseSummaryDataLoading,
        isLicenseSummaryDataFetchingError,
        licenseSummaryData,
        selectedResellerId,
        resellersData,
        headerLabelMemo
    ]);

    const filtersMemo = useMemo(() => {
        return (
            <>
                <StyledSelect
                    placeholder='Filter'
                    value={resellerFilter ? [resellerFilter] : undefined}
                    onChange={onChangeResellerFilter}
                    input={<Input disableUnderline />}
                    renderValue={(selected) => {
                        if (selected?.length === 1)
                            return filterMenuItems.find((filterItem) => filterItem.id === selected[0].toString())?.name;
                        return undefined;
                    }}
                    IconComponent={UpArrowIcon}
                >
                    {filterMenuItems.map((filterMenuItem) => {
                        return (
                            <StyledMenuItem
                                sx={{ height: 32, paddingLeft: '4px' }}
                                key={filterMenuItem.id}
                                value={filterMenuItem.id}
                            >
                                <MenuItemTitleSpan>{filterMenuItem.name}</MenuItemTitleSpan>
                            </StyledMenuItem>
                        );
                    })}
                </StyledSelect>
            </>
        );
    }, [resellersData, resellerFilter, filterMenuItems, selectedResellerId]);

    return (
        <Page>
            <PageContent>
                <HeaderActionBox>
                    <ButtonWrapperBox>
                        <PrimaryButton
                            style={{
                                padding: '6px 24px'
                            }}
                            title='+ Reseller'
                            clickHandler={toggleAddReseller}
                        />
                    </ButtonWrapperBox>
                    <ButtonWrapperBox>
                        <PrimaryButton
                            style={{
                                padding: '6px 24px'
                            }}
                            title='+ Customer'
                            clickHandler={toggleAddCustomer}
                        />
                    </ButtonWrapperBox>
                </HeaderActionBox>
                <MainContentBox>
                    <CustomersBox>
                        <LicenseSummaryFilterBox>
                            {licenseSummaryMemo}
                            <FiltersBox>
                                <FilterByTypography>{t('accounts.filterCustomersBy')}</FilterByTypography>
                                {filtersMemo}
                            </FiltersBox>
                        </LicenseSummaryFilterBox>
                        <CardBox>
                            <AccountsTable
                                isLoading={isCustomersDataLoading}
                                customersData={customersData}
                                resellerFilter={resellerFilter}
                                onChangeLicenseSyncCallback={() => {
                                    refetchCustomersData();
                                }}
                                onChangeLicenseCountCallback={onChangeLicenseCountCallback}
                                headerLabel={headerLabelMemo}
                            />
                        </CardBox>
                        <ActivityLog
                            isLoading={isActivityLogDataLoading}
                            isError={isActivityLogDataError}
                            data={activityLogData}
                            isCustomerNameColumnPresent={true}
                            isDefaultExpanded
                        />
                    </CustomersBox>
                </MainContentBox>
                <AddCustomerDialog open={isAddCustomerOpen} onClose={toggleAddCustomer} />
                <AddResellerDialog open={isAddResellerOpen} onClose={toggleAddReseller} />
            </PageContent>
        </Page>
    );
};

export default Accounts;
