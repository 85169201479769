import { createContext, FC, useContext, PropsWithChildren } from 'react';
import {
    useGetAccountDetailsQuery,
    useGetCustomerAdministratorsQuery,
    useGetCustomerDetailsQuery,
    useGetCustomerLicenseDetailsQuery,
    useGetCustomerNotesQuery,
    useGetCustomerSettingsQuery
} from '../services/AccountsQueryService';
// import { EAccountStatus } from '../interfaces/enums/EAccountStatus';
// import { EAccountType } from '../interfaces/enums/EAccountType';
import { IAccountDetailsVM } from '../interfaces/views/IAccountDetailsVM';
import { IActivityVM } from '../interfaces/views/IActivityVM';
import { useParams } from 'react-router';
import { IAdministratorVM } from '../interfaces/views/IAdministratorVM';
import { ICustomerSettingsVM } from '../interfaces/views/ICustomerSettingsVM';
import { ICustomerDetailsVM } from '../interfaces/views/ICustomerDetailsVM';
import { ICustomerLicenseDetailsVM } from '../interfaces/views/ICustomerLicenseDetailsVM';
import { useGetCustomerActivityLogsQuery } from '../services/ActivityLogsQueryService';
import { INotesVM } from '../interfaces/views/INotesVM';
import { useGetCustomerConnectUrl } from '../services/customer/useGetCustomerConnectUrl';
import { ICustomerConnectUrl } from '../interfaces/views/ICustomerConnectUrl';

export interface IAccountDetailsContext {
    data?: IAccountDetailsVM[];
    activityData?: IActivityVM[];
    isAccountDetailsDataLoading: boolean;
    isAccountDetailsDataFetchingError: boolean;
    isActivityDataLoading: boolean;
    isActivityDataFetchingError: boolean;
    administratorsList?: IAdministratorVM[];
    customerId?: string;
    isAdministratorsLoading: boolean;
    isAdministratorsError: boolean;
    refetchAdministratorsApiCall: () => void;
    settingsData?: ICustomerSettingsVM;
    isSettingsLoading: boolean;
    isSettingsError: boolean;
    refetchSettingsApiCall: () => void;
    isCustomerDetailsLoading: boolean;
    isCustomerDetailsError: boolean;
    customerDetailsData?: ICustomerDetailsVM;
    refetchCustomerDetailsApiCall: () => void;
    isCustomerLicenseDetailsError: boolean;
    isCustomerLicenseDetailsLoading: boolean;
    licenseDetailsData?: ICustomerLicenseDetailsVM;
    refetchLicenseDetailsApiCall: () => void;
    activityLogsData?: IActivityVM[];
    notesData?: INotesVM;
    isNotesLoading: boolean;
    isNotesError: boolean;
    refetchNotesApiCall: () => void;
    customerConnectUrlData?: ICustomerConnectUrl;
    isCustomerConnectUrlLoading: boolean;
    isCustomerConnectUrlError: boolean;
}

export const AccountDetailsContext = createContext<IAccountDetailsContext>({} as IAccountDetailsContext);

interface IProps {}

export const AccountDetailsProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const params = useParams<{ id: string }>();
    const {
        data: fetchedCustomerActivityData,
        isLoading: isActivityDataLoading,
        isError: isActivityDataError
    } = useGetCustomerActivityLogsQuery(params?.id);
    const {
        data: fetchedAccountsData,
        isLoading: isAccountDetailsDataLoading,
        isError: isAccountDetailsDataFetchingError
    } = useGetAccountDetailsQuery();
    const {
        data: fetchedCustomerAdministratorsData,
        isLoading: isAdministratorsLoading,
        isError: isAdministratorsError,
        refetch: refetchAdministratorsApiCall
    } = useGetCustomerAdministratorsQuery(params?.id);
    const {
        data: fetchedCustomerSettingsData,
        isLoading: isSettingsLoading,
        isError: isSettingsError,
        refetch: refetchSettingsApiCall
    } = useGetCustomerSettingsQuery(params?.id);
    const {
        data: fetchedCustomerDetailsData,
        isLoading: isCustomerDetailsLoading,
        isError: isCustomerDetailsError,
        refetch: refetchCustomerDetailsApiCall
    } = useGetCustomerDetailsQuery(params?.id);
    const {
        data: fetchedCustomerLicenseDetailsData,
        isLoading: isCustomerLicenseDetailsLoading,
        isError: isCustomerLicenseDetailsError,
        refetch: refetchLicenseDetailsApiCall
    } = useGetCustomerLicenseDetailsQuery(params?.id);
    const {
        data: fetchedNotesData,
        isLoading: isNotesLoading,
        isError: isNotesError,
        refetch: refetchNotesApiCall
    } = useGetCustomerNotesQuery(params?.id);
    const {
        data: fetchedCustomerConnectUrlData,
        isError: isCustomerConnectUrlError,
        isLoading: isCustomerConnectUrlLoading
    } = useGetCustomerConnectUrl(params?.id);

    const accountDetailsState: IAccountDetailsContext = {
        data: fetchedAccountsData || [],
        isAccountDetailsDataLoading,
        isAccountDetailsDataFetchingError,
        activityData: fetchedCustomerActivityData,
        isActivityDataFetchingError: isActivityDataError,
        isActivityDataLoading: isActivityDataLoading,
        administratorsList: fetchedCustomerAdministratorsData,
        customerId: params.id,
        refetchAdministratorsApiCall,
        isAdministratorsLoading,
        isAdministratorsError,
        settingsData: fetchedCustomerSettingsData,
        isSettingsError,
        isSettingsLoading,
        refetchSettingsApiCall,
        isCustomerDetailsError,
        isCustomerDetailsLoading,
        customerDetailsData: fetchedCustomerDetailsData,
        refetchCustomerDetailsApiCall,
        licenseDetailsData: fetchedCustomerLicenseDetailsData,
        isCustomerLicenseDetailsError,
        isCustomerLicenseDetailsLoading,
        refetchLicenseDetailsApiCall,
        activityLogsData: fetchedCustomerActivityData,
        refetchNotesApiCall,
        isNotesLoading,
        isNotesError,
        notesData: fetchedNotesData,
        customerConnectUrlData: fetchedCustomerConnectUrlData,
        isCustomerConnectUrlError: isCustomerConnectUrlError,
        isCustomerConnectUrlLoading: isCustomerConnectUrlLoading
    };

    return <AccountDetailsContext.Provider value={accountDetailsState}>{children}</AccountDetailsContext.Provider>;
};

export const useAccountDetailsStateValue: () => IAccountDetailsContext = () => useContext(AccountDetailsContext);
