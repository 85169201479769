import { getTokenAndHeaders, IAuthParams, useAuthParams } from 'nulia-ui';
import { apiInstance } from './util/apiInstance';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { EApiQueryKey } from '../types/enums/EApiQueryKey';
import { IActivityVM } from '../interfaces/views/IActivityVM';
import { mapActivityLogs } from './mappers';

// GET FOLDER ACTIVITY LOGS
export const getFolderActivityLogs = async (authParams: IAuthParams, folderId: string | undefined) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`folder/${folderId}/activities`, headers);
    return mapActivityLogs(data);
};

export const useGetFolderActivityLogsQuery: (folderId: string | undefined) => UseQueryResult<IActivityVM[], Error> = (
    folderId
) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.ACTIVITY_LOGS_FOLDER_FETCH_ALL, folderId],
        queryFn: async () => {
            return getFolderActivityLogs(authParams, folderId);
        },
        refetchOnWindowFocus: false,
        enabled: false
    });
};

// GET PARTNER ACTIVITY LOGS
export const getPartnerActivityLogs = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`/activities`, headers);
    return mapActivityLogs(data);
};

export const useGetPartnerActivityLogsQuery: () => UseQueryResult<IActivityVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.ACTIVITY_LOGS_PARTNER_FETCH_ALL],
        queryFn: async () => {
            return getPartnerActivityLogs(authParams);
        },
        refetchOnWindowFocus: false,
        enabled: false
    });
};

// GET CUSTOMER ACTIVITY LOGS
export const getCustomerActivityLogs = async (authParams: IAuthParams, customerId: string | undefined) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`customer/${customerId}/activities`, headers);
    return mapActivityLogs(data);
};

export const useGetCustomerActivityLogsQuery: (
    customerId: string | undefined
) => UseQueryResult<IActivityVM[], Error> = (customerId) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.ACTIVITY_LOGS_CUSTOMER_FETCH_ALL, customerId],
        queryFn: async () => {
            return getCustomerActivityLogs(authParams, customerId);
        },
        refetchOnWindowFocus: false,
        enabled: true
    });
};
