import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const ContentRootBox = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: '100%',
    padding: '0px 24px 24px 24px',
    [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
        '& > div': {
            paddingBottom: '16px',
            paddingTop: '16px'
        }
    },
    [theme.breakpoints.up('md')]: {
        '& > div:nth-of-type(2n)': {
            borderRight: 0,
            paddingLeft: '16px',
            paddingBottom: '16px'
        },
        '& > div:nth-last-of-type(-n + 2)': {
            borderBottom: 0,
            paddingTop: '16px'
        },
        '& > div:nth-of-type(1), & > div:nth-of-type(2)': {
            paddingBottom: '16px'
        },
        '& > div:nth-of-type(3), & > div:nth-of-type(4)': {
            paddingTop: '16px'
        }
    }
}));

export const SubmitButtonBox = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '24px'
});

export const SettingsGroupBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid rgba(0, 0, 0, 0.16)',
    borderRight: '1px solid rgba(0, 0, 0, 0.16)'
});

export const TitleTypography = styled(Typography)({
    textTransform: 'uppercase',
    paddingBottom: '8px'
});
