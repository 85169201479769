import { FC, useMemo, useRef, useState } from 'react';
import {
    RootInnerBox,
    StyledMain,
    HeaderTitleTypography,
    ReviewDataBox,
    HeaderCount,
    ReviewTypeSwitch,
    HeaderBox,
    UsersTableBox,
    OutcomeBox,
    OutcomeTitle
} from './Style';
import { IAdminOutcomeVM } from '../../../../../interfaces/views/IAdminOutcomeVM';
import { ITenantOutcomeUserDTO } from '../../../../../interfaces/dtos/ITenantOutcomeUserDTO';
import { ITableHeadCell } from '../../../../../interfaces/ITableHeaderCell';
import Table from '../../../../../ui/general/table/Table';
import { createUrl } from '../../../../../utils/createUrl';
import { useTranslation } from 'react-i18next';

interface IProps {
    selectedOutcomesList: IAdminOutcomeVM[];
    selectedUsersList: ITenantOutcomeUserDTO[];
}

interface IUsersTableHeader {
    image: string;
    name: string;
    department: string;
    region: string;
    applicableOutcomeCount: string;
    sourceLicenseType: string;
}

enum EReviewTableType {
    OUTCOMES = 'Outcomes',
    USERS = 'Users'
}

const Review: FC<IProps> = ({ selectedOutcomesList, selectedUsersList }) => {
    const [reviewTableType, setReviewTableType] = useState<EReviewTableType>(EReviewTableType.OUTCOMES);
    const { t } = useTranslation();

    const userSelectionPropertyKeys = useRef<string[]>([
        'image',
        'name',
        'department',
        'applicableOutcomeCount',
        'region'
    ]);
    const usersTableHeadCells = useRef<ITableHeadCell<IUsersTableHeader>[]>([
        {
            id: 'image',
            numeric: false,
            disablePadding: true,
            label: ''
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Name'
        },
        {
            id: 'department',
            numeric: false,
            disablePadding: false,
            label: 'Department'
        },
        {
            id: 'applicableOutcomeCount',
            numeric: false,
            disablePadding: false,
            label: 'Outcomes',
            align: 'center',
            customRender(value) {
                return <>{`${value} / ${selectedOutcomesList.length}`}</>;
            }
        },
        {
            id: 'region',
            numeric: false,
            disablePadding: false,
            label: 'Region',
            align: 'center'
        }
    ]);

    const usersTableMemo = useMemo(() => {
        return (
            <UsersTableBox>
                <Table<ITenantOutcomeUserDTO, IUsersTableHeader>
                    headCells={usersTableHeadCells.current}
                    data={selectedUsersList}
                    propertyKeys={userSelectionPropertyKeys.current}
                    tableTitlePlural='Users'
                    // isFilterControlVisible={isLgDown}
                    // isFilterDrawerOpen={isDrawerOpen}
                    // filterControlToggleHandler={toggleFilterDrawer}
                    // filtersDrawerMemo={filtersMemo}
                    // isMultipleSelectionAvailable
                    // isError={isErrorFetchingUserSelectionData}
                    // isLoading={isLoadingFetchingUserSelectionData}
                    // refetchDataCallback={refetchUserSelectionData}
                    isImageColumnPresent
                    // initialSelectedRows={selectedUsersList}
                    userImageInfoColumn='name'
                    userImageInfoColumnPosition='bottom'
                />
            </UsersTableBox>
        );
    }, []);

    const outcomesListMemo = useMemo(() => {
        return (
            <div>
                {selectedOutcomesList.map((outcome) => {
                    return (
                        <OutcomeBox key={outcome.outcome.id}>
                            <img
                                width='42px'
                                height='42px'
                                alt='outcome'
                                src={createUrl(outcome.outcome.image)?.generatedUrl}
                            />
                            <OutcomeTitle variant='subtitle2'>{outcome.outcome.title}</OutcomeTitle>
                        </OutcomeBox>
                    );
                })}
            </div>
        );
    }, []);

    const changeReviewTableType = () => {
        setReviewTableType((reviewTableType) =>
            reviewTableType === EReviewTableType.USERS ? EReviewTableType.OUTCOMES : EReviewTableType.USERS
        );
    };

    return (
        <StyledMain>
            <RootInnerBox>
                <HeaderBox>
                    <HeaderTitleTypography variant='h6'>
                        Assign <HeaderCount>{selectedOutcomesList.length}</HeaderCount> Outcomes to{' '}
                        <HeaderCount>{selectedUsersList.length}</HeaderCount> Users
                    </HeaderTitleTypography>
                    <ReviewTypeSwitch onClick={changeReviewTableType}>
                        {reviewTableType === EReviewTableType.USERS
                            ? t('adminOutcomes.seeOutcomes')
                            : t('adminOutcomes.seeUsers')}
                    </ReviewTypeSwitch>
                </HeaderBox>
                <ReviewDataBox>
                    {reviewTableType === EReviewTableType.USERS ? usersTableMemo : outcomesListMemo}
                </ReviewDataBox>
            </RootInnerBox>
        </StyledMain>
    );
};

export default Review;
