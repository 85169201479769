import { useCallback, useState } from 'react';
import { apiInstance } from '../services/helpers/apiInstance';
import useAuthParams from './useAuthParams';
import { getTokenAndHeaders } from '../services/helpers/apiUtil';
import { isAfter } from 'date-fns';
import * as d3 from 'd3';

export interface IDownloadReport {
    downloadReportCallback: (newUrl?: string) => void;
    downloadReportWithTimespan: (data: any) => void;
    isLoading: boolean;
}

export interface IDownloadReportProps<T> {
    url: string;
    reportFileName?: string;
    mapperFn?: (data: any) => T[];
    generateFileNameFromHeader?: boolean;
}

function useDownloadReport<T extends { date?: Date; id?: string }>({
    url,
    reportFileName,
    mapperFn,
    generateFileNameFromHeader = false
}: IDownloadReportProps<T>): IDownloadReport {
    const authParams = useAuthParams();
    const [isLoading, setLoading] = useState<boolean>(false);

    const downloadReportCallback = useCallback(
        async (newUrl?: string) => {
            try {
                setLoading(true);
                const { headers } = await getTokenAndHeaders(authParams);
                apiInstance.get(newUrl || url, { ...headers, responseType: 'blob' }).then((response) => {
                    const href = URL.createObjectURL(response.data);

                    let generatedFileName = '';
                    try {
                        if (generateFileNameFromHeader) {
                            generatedFileName = response.headers['content-disposition'].split('"')[1];
                        }
                    } catch (err) {
                        console.error(err);
                    }

                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute(
                        'download',
                        generateFileNameFromHeader ? generatedFileName : `${reportFileName}.csv`
                    );
                    document.body.appendChild(link);
                    link.click();

                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                    setLoading(false);
                });
            } catch (err) {
                console.error(err);
                setLoading(false);
            }
        },
        [url, reportFileName, authParams]
    );

    function convertToCSV(columnNames: string[], arr: any[]) {
        const array = [columnNames.filter((columnName) => columnName !== 'id')].concat(arr);

        return array
            .map((it, index) => {
                if (index === 0) {
                    const text = Object.values(it).toString();
                    const result = text.replace(/([A-Z])/g, ' $1');
                    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
                    return finalResult;
                }
                return Object.values(it).toString();
            })
            .join('\n');
    }

    const downloadReportWithTimespan = useCallback(
        async (fromDate: Date) => {
            try {
                setLoading(true);
                const { headers } = await getTokenAndHeaders(authParams);
                apiInstance.get(url, { ...headers }).then((response) => {
                    if (mapperFn) {
                        const fetchedData: T[] = mapperFn(response.data);
                        const filteredFetchedData = fetchedData.filter((item) => {
                            if (item.date) {
                                delete item?.id;
                                return isAfter(item.date, fromDate);
                            }
                            return false;
                        });

                        const parsedRows = d3.csvParseRows(response.data);

                        const blob = new Blob([convertToCSV(parsedRows[0], filteredFetchedData)], {
                            type: 'text/plain'
                        });
                        const href = URL.createObjectURL(blob);

                        const link = document.createElement('a');
                        link.href = href;
                        link.setAttribute('download', `${reportFileName}.csv`);
                        document.body.appendChild(link);
                        link.click();

                        document.body.removeChild(link);
                        URL.revokeObjectURL(href);
                    }
                    setLoading(false);
                });
            } catch (err) {
                console.error(err);
                setLoading(false);
            }
        },
        [url, reportFileName, authParams]
    );

    return {
        downloadReportCallback,
        downloadReportWithTimespan,
        isLoading
    };
}

export default useDownloadReport;
