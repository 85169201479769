import { createContext, FC, useContext, PropsWithChildren } from 'react';
import { IPartnerUserVM } from '../interfaces/views/IPartnerUserVM';
import { useGetPartnersUsersQuery } from '../services/PartnerQueryService';
import { useParams } from 'react-router-dom';

export interface IPartnerUserContext {
    partnerUsersData?: IPartnerUserVM[];
    isPartnerUsersDataLoading: boolean;
    isPartnerUsersDataFetchingError: boolean;
    refetchPartnerUsersCallback: () => void;
}

export const PartnerUserContext = createContext<IPartnerUserContext>({} as IPartnerUserContext);

interface IProps {}

export const PartnerUserProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const params = useParams<{ id?: string }>();
    const {
        data: fetchedPartnerUsersData,
        isLoading: isPartnerUsersDataLoading,
        isError: isPartnerUsersDataFetchingError,
        refetch: refetchPartnerUsersCallback
    } = useGetPartnersUsersQuery(params?.id);

    const partnerUserState: IPartnerUserContext = {
        partnerUsersData: fetchedPartnerUsersData,
        isPartnerUsersDataLoading,
        isPartnerUsersDataFetchingError,
        refetchPartnerUsersCallback
    };

    return <PartnerUserContext.Provider value={partnerUserState}>{children}</PartnerUserContext.Provider>;
};

export const usePartnerUserStateValue: () => IPartnerUserContext = () => useContext(PartnerUserContext);
