import { getTokenAndHeaders, IAuthParams, useAuthParams } from 'nulia-ui';
import { apiInstance } from '../util/apiInstance';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { EApiQueryKey } from '../../types/enums/EApiQueryKey';
import { ICustomerConnectUrl } from '../../interfaces/views/ICustomerConnectUrl';

export const getCustomerConnectUrl = async (authParams: IAuthParams, customerId: string | undefined) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`customer/${customerId}/connectUrl`, headers);
    return data;
};

export const useGetCustomerConnectUrl: (
    customerId: string | undefined
) => UseQueryResult<ICustomerConnectUrl, Error> = (customerId) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.CUSTOMER_CONNECT_URL, customerId],
        queryFn: async () => {
            return getCustomerConnectUrl(authParams, customerId);
        },
        refetchOnWindowFocus: false
    });
};
