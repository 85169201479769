import { styled } from '@mui/system';

export const SearchIconBox = styled('div')({
    marginRight: 8,
    height: 24,
    width: 24
});

export const SearchWrapper = styled('div')({
    backgroundColor: '#FFF',
    height: 36,
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    filter: 'drop-shadow(0px 0.5px 1.5px rgba(0, 0, 0, 0.08)) drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.08))'
});

export const RootBox = styled('div')({
    position: 'relative'
});

export const StyledSearchInput = styled('input')({
    width: '100%',
    border: 0,
    margin: 0,
    padding: 0,
    fontFamily: 'Open Sans',
    fontSize: 16,
    letterSpacing: 0.5,
    lineHeight: '24px',
    color: '#666666',
    '&:focus': {
        outline: 'none'
    },
    '&::placeholder': {
        color: '#adadad'
    }
});

export const SearchResultsWrapper = styled('div')({
    paddingTop: 4,
    paddingBottom: 8,
    backgroundColor: '#FFF',
    borderRadius: 4,
    boxShadow: '0 5px 16px 1px rgba(0,0,0,0.08), 0 2px 8px 0 rgba(0,0,0,0.08)',
    marginTop: 4,
    position: 'absolute',
    zIndex: 111111,
    width: 356,
    marginLeft: 10
});

export const SearchResultBox = styled('div')({
    zIndex: 111111,
    color: '#666666',
    fontFamily: 'Open Sans',
    fontSize: 14,
    letterSpacing: 0.25,
    lineHeight: '20px',
    height: 40,
    minHeight: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: 8,
    paddingRight: 8,
    cursor: 'pointer',
    gap: '15px',
    '&:hover': {
        backgroundColor: 'rgba(101,78,163,0.04) !important'
    }
});

export const AccountTypeBox = styled('div')({
    width: '23px',
    height: '16px',
    borderRadius: '16px',
    padding: '2px 8px',
    fontFamily: 'Open Sans',
    textAlign: 'center',
    fontSize: '10px',
    fontWeight: 600,
    lineHeight: '12px',
    letterSpacing: '0.5px',
    textTransform: 'uppercase'
});
