import { FC } from 'react';
import { RovingTabIndexProvider } from 'react-roving-tabindex';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { CategoriesWrapper, RootBox, RootInnerBox, RootPaper, TitleTypography } from './Style';
import { useHowToStateValue } from '../../../contexts/HowToContext';
import { makeHowToPlaylistRoute } from '../../../pages/routes';
import Loading from '../../../ui/general/loading/Loading';
import { useCrumbsStateValue } from '../../../contexts/CrumbsContext';
import PlaylistItem from '../components/playlist-item/PlaylistItem';

const HowTo: FC = () => {
    const { howToList, isDataLoading } = useHowToStateValue();
    const { appendCrumb } = useCrumbsStateValue();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const clickHandler = (categoryId: string, playlistId: string) => {
        const route = makeHowToPlaylistRoute(categoryId, playlistId);
        appendCrumb({
            name: howToList && howToList.length > 0 ? howToList[0].categoryName : t('pages.howTo'),
            pathname: route
        });
        navigate(route);
    };
    return (
        <RootBox>
            <RootInnerBox>
                <RootPaper elevation={1}>
                    <TitleTypography variant='h5'>
                        {howToList && howToList.length > 0 ? `${howToList[0].categoryName} How To's` : ''}
                    </TitleTypography>
                    <CategoriesWrapper isLoading={!!isDataLoading}>
                        {isDataLoading ? (
                            <Loading />
                        ) : (
                            <RovingTabIndexProvider options={{ direction: 'vertical' }}>
                                {howToList?.map((howTo) => {
                                    return (
                                        <PlaylistItem
                                            categoryId={howTo.categoryId}
                                            description={howTo.description}
                                            id={howTo.id}
                                            image={howTo.image}
                                            title={howTo.title}
                                            itemClickHandler={clickHandler}
                                            key={howTo.id}
                                        />
                                    );
                                })}
                            </RovingTabIndexProvider>
                        )}
                    </CategoriesWrapper>
                </RootPaper>
            </RootInnerBox>
        </RootBox>
    );
};

export default HowTo;
