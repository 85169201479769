import { FC, useCallback, useMemo, useState } from 'react';
import {
    StyledMain,
    RootInnerBox,
    HeaderWrapper,
    AssignmentSpan,
    StyledTabs,
    HistoryWrapper,
    StyledTab,
    TabLabel,
    AssignmentButtonGroup
} from './Style';
import TabPanel from '../../../ui/general/tab-panel/TabPanel';
import OutcomeSelection from './tabs/outcome-selection/OutcomeSelection';
import TextButton from '../../../ui/buttons/text-button/TextButton';
import {
    EAssignmentMode,
    EBulkAssignmentStep,
    useAdminOutcomesStateValue
} from '../../../contexts/AdminOutcomesContext';
import UserSelection from './tabs/user-selection/UserSelection';
import { useNavigate } from 'react-router';
import { routes } from '../../../pages/routes';
import { useCrumbsStateValue } from '../../../contexts/CrumbsContext';
import usePageName from '../../../hooks/usePageName';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { PrimaryButton, SecondaryButton } from '../../../ui/index.ui';
import Review from './tabs/review/Review';
import { useTranslation } from 'react-i18next';

const AdminOutcomes: FC = () => {
    const {
        bulkAssignmentStep,
        changeBulkAssignmentStep,
        clearAssignment,
        selectedOutcomesList,
        selectedUsersList,
        assignmentMode,
        changeAssignmentMode,
        assignOutcomesCallback,
        unassignOutcomesCallback
    } = useAdminOutcomesStateValue();
    const navigate = useNavigate();
    const { appendCrumb } = useCrumbsStateValue();
    usePageName({ pageName: 'Outcomes' });
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation();
    const [isSubmitPending, setSubmitPending] = useState<boolean>(false);

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        changeBulkAssignmentStep(newValue as EBulkAssignmentStep);
    };

    const viewOutcomeHistoryCallback = useCallback(() => {
        appendCrumb({
            name: 'Admin History',
            pathname: routes.HISTORY_OUTCOMES
        });
        navigate(routes.HISTORY_OUTCOMES);
    }, []);

    const clearAssignmentHandler = () => {
        clearAssignment();
    };

    const outcomeAssignmentHistoryMemo = useMemo(() => {
        return (
            <HistoryWrapper>
                <TextButton
                    onClick={viewOutcomeHistoryCallback}
                    mode='primary'
                    style={{ paddingLeft: isMdDown ? 0 : '10px' }}
                >
                    VIEW OUTCOME ASSIGNMENT HISTORY
                </TextButton>
            </HistoryWrapper>
        );
    }, [viewOutcomeHistoryCallback]);

    const assignOutcomesHandler = useCallback(() => {
        changeBulkAssignmentStep(EBulkAssignmentStep.USER_SELECTION);
        changeAssignmentMode(EAssignmentMode.ASSIGN);
    }, []);

    const unassignOutcomesHandler = useCallback(() => {
        changeBulkAssignmentStep(EBulkAssignmentStep.USER_SELECTION);
        changeAssignmentMode(EAssignmentMode.UNASSIGN);
    }, []);

    const backToOutcomesSelection = useCallback(() => {
        changeAssignmentMode(undefined);
        changeBulkAssignmentStep(EBulkAssignmentStep.OUTCOME_SELECTION);
    }, []);

    const backToUsersSelection = useCallback(() => {
        changeBulkAssignmentStep(EBulkAssignmentStep.USER_SELECTION);
    }, []);

    const goToConfirmStep = useCallback(() => {
        changeBulkAssignmentStep(EBulkAssignmentStep.ASSIGNMENT);
    }, []);

    const submitHandler = async () => {
        setSubmitPending(true);
        if (assignmentMode === EAssignmentMode.ASSIGN) {
            await assignOutcomesCallback();
            setSubmitPending(false);
        } else {
            await unassignOutcomesCallback();
            setSubmitPending(false);
        }
        navigate(routes.HISTORY_OUTCOMES);
    };

    const assignmentTabsMemo = useMemo(() => {
        switch (bulkAssignmentStep) {
            case EBulkAssignmentStep.OUTCOME_SELECTION:
                if (selectedOutcomesList.length === 0) {
                    return <StyledTab label='1. Outcome selection' id='0' value={0} />;
                }
                return (
                    <>
                        <StyledTab label='1. Outcome selection' id='0' value={0} />
                        <AssignmentButtonGroup>
                            <PrimaryButton title='Assign' clickHandler={assignOutcomesHandler} />
                            <TabLabel>OR</TabLabel>
                            <PrimaryButton title='Unassign' clickHandler={unassignOutcomesHandler} />
                        </AssignmentButtonGroup>
                    </>
                );
            case EBulkAssignmentStep.USER_SELECTION:
                return (
                    <>
                        <SecondaryButton style={{ width: '170px', fontWeight: 500 }} onClick={backToOutcomesSelection}>
                            Back: Outcomes
                        </SecondaryButton>
                        <StyledTab label='2. User selection' id='0' value={0} />
                        {selectedUsersList.length > 0 && (
                            <PrimaryButton title='Next: Confirm' clickHandler={goToConfirmStep} />
                        )}
                    </>
                );
            case EBulkAssignmentStep.ASSIGNMENT:
                return (
                    <>
                        <SecondaryButton style={{ width: '170px', fontWeight: 500 }} onClick={backToUsersSelection}>
                            Back: Users
                        </SecondaryButton>
                        <StyledTab label='3. Review' id='0' value={0} />
                        {selectedUsersList.length > 0 && (
                            <PrimaryButton disabled={isSubmitPending} title='Submit' clickHandler={submitHandler} />
                        )}
                    </>
                );
            default:
                return <></>;
        }
    }, [
        bulkAssignmentStep,
        selectedOutcomesList,
        selectedUsersList,
        assignOutcomesHandler,
        unassignOutcomesHandler,
        backToOutcomesSelection,
        backToUsersSelection,
        goToConfirmStep,
        isSubmitPending
    ]);

    return (
        <StyledMain>
            <RootInnerBox>
                <HeaderWrapper>
                    {isMdDown && outcomeAssignmentHistoryMemo}
                    <StyledTabs
                        id='organizational-outcomes-tabs'
                        value={0}
                        onChange={handleChange}
                        aria-label='organizational outcomes tabs'
                        tab={bulkAssignmentStep}
                        // orientation={isLgDown ? 'vertical' : 'horizontal'}
                    >
                        {assignmentTabsMemo}
                        {assignmentMode && selectedOutcomesList.length > 0 && (
                            <AssignmentSpan>
                                <TextButton
                                    style={{
                                        whiteSpace: 'pre-wrap',
                                        color: 'rgba(0, 0, 0, 0.8)',
                                        fontSize: '12px',
                                        letterSpacing: '0.5px',
                                        fontFamily: 'Open Sans',
                                        fontWeight: 400,
                                        textTransform: 'none'
                                    }}
                                    onClick={clearAssignmentHandler}
                                    mode='primary'
                                >
                                    {assignmentMode === EAssignmentMode.ASSIGN
                                        ? t('adminOutcomes.clearAssignOutcomes')
                                        : t('adminOutcomes.clearUnassignOutcomes')}
                                </TextButton>
                            </AssignmentSpan>
                        )}
                    </StyledTabs>
                    {!isMdDown && outcomeAssignmentHistoryMemo}
                </HeaderWrapper>
                <TabPanel value={bulkAssignmentStep} index={0}>
                    <OutcomeSelection />
                </TabPanel>
                <TabPanel value={bulkAssignmentStep} index={1}>
                    <UserSelection />
                </TabPanel>
                <TabPanel value={bulkAssignmentStep} index={2}>
                    <Review selectedOutcomesList={selectedOutcomesList} selectedUsersList={selectedUsersList} />
                </TabPanel>
            </RootInnerBox>
        </StyledMain>
    );
};

export default AdminOutcomes;
