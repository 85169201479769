import { createContext, FC, useContext, PropsWithChildren, useState, useEffect } from 'react';

export interface IApplicationContext {
    isTeamsEnv: boolean;
}

export const ApplicationContext = createContext<IApplicationContext>({} as IApplicationContext);

interface IProps {
    isTeamsEnv: boolean;
}

export const ApplicationProvider: FC<PropsWithChildren<IProps>> = ({ children, isTeamsEnv: isTeamsEnvProp }) => {
    const [isTeamsEnv, setTeamsEnv] = useState<boolean>(false);

    useEffect(() => {
        setTeamsEnv(isTeamsEnvProp);
    }, [isTeamsEnvProp]);

    const appState: IApplicationContext = {
        isTeamsEnv
    };

    return <ApplicationContext.Provider value={appState}>{children}</ApplicationContext.Provider>;
};

export const useApplicationStateValue: () => IApplicationContext = () => useContext(ApplicationContext);
