import React, { FC } from 'react';
import { RouterProvider, Outlet } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';
import {
    HomeProvider,
    HomePage,
    LicensesProvider,
    OutcomePage,
    SkillsPage,
    SkillPage,
    SkillProvider,
    SkillsProvider,
    ToastProvider,
    useNavigationStateValue,
    styled,
    OutcomeProvider,
    SettingsPage,
    RolesProvider,
    AdminPage,
    SettingsProvider,
    ProtectedRoute,
    useUserAuthStateValue,
    routes,
    ERoute,
    Loading,
    OutcomesTabsPage,
    FeedbackPage,
    InsightsPage,
    LinkedinPage,
    InstructionsPage,
    AdminHistoryProvider,
    HistoryDetails,
    LicensesPage,
    RolesPage,
    HistoryPage,
    AdminHistoryDetailsProvider,
    TabsProvider,
    OutcomesProvider,
    AdminOutcomesProvider,
    OutcomesPage,
    AdminOutcomesPage,
    PersonalSettingsPage,
    OrganizationalSettingsPage,
    PersonalInsightsSkillsTab,
    PersonalInsightsOutcomesTab,
    PersonalInsightsEngagementTab,
    PersonalInsightsBadgesTab,
    OrganizationalInsightsUsersTab,
    OrganizationalInsightsOutcomesTab,
    OrganizationalInsightsBadgesTab,
    OrganizationalInsightsAssessmentTab,
    OrganizationalInsightsOutcomesDetails,
    InsightsPersonalSkillsProvider,
    InsightsPersonalOutcomesProvider,
    InsightsPersonalProvider,
    InsightsPersonalEngagementProvider,
    InsightsPersonalBadgesProvider,
    InsightsOrganizationalUsersProvider,
    InsightsOrganizationalOutcomesProvider,
    InsightsOrganizationEngagementProvider,
    OrganizationalInsightsEngagementTab,
    InsightsOrganizationBadgesProvider,
    InsightsOrganizationOutcomesDetailsProvider,
    InsightsOrganizationalAssessmentProvider,
    OrganizationalAssessmentDetails,
    InsightsOrganizationalAssessmentDetailsProvider,
    SomethingWentWrong,
    PageNotFound,
    InsightsProvider,
    OrganizationalSkillDetailsTab,
    InsightsOrganizationalSkillProvider,
    InsightsOrganizationSingleBadgeProvider,
    OrganizationalInsightsSingleBadgeTab,
    ShowcaseHome,
    HowTosHomepage,
    HowTosProvider,
    HowToProvider,
    HowTo,
    HowToPlaylist,
    HowToPlaylistProvider,
    ShowcaseProvider,
    AchievementsPreview,
    AchievementsDetail,
    AchievementsDetailProvider,
    BadgesAllByCategory,
    ScrollToTop,
    PageForbidden,
    NoTenantNuliaLicenseStatus,
    NoLicenseNuliaLicenseStatus,
    InsightsOrganizationProvider
} from 'nulia-ui';
import {
    Accounts,
    PartnerPortalThemeProvider,
    Admin,
    AccountsProvider,
    Resources,
    Contact,
    AccountDetailsProvider,
    AccountDetails,
    ResourcesProvider,
    AdminProvider,
    Statements,
    StatementsProvider,
    PartnerUser,
    PartnerUserProvider
} from 'partner-portal';
import MainLayout from './MainLayout';
import MainPartnerPortalLayout from './MainPartnerPortalLayout';

interface IProps {}

const MainContentBox = styled('div')<{ isSidemenuOpen: boolean }>(({ isSidemenuOpen }) => ({
    '& main': {
        marginLeft: isSidemenuOpen ? 110 : 0
    }
}));

const Routes: FC<IProps> = () => {
    const { showNav } = useNavigationStateValue();
    const { currentUserData, isCurrentUserDataLoading } = useUserAuthStateValue();

    const defaultCrumbHandler = () => {};

    const router = createBrowserRouter([
        {
            path: '/',
            element: (
                <TabsProvider>
                    <ScrollToTop />
                    <MainLayout />
                </TabsProvider>
            ),
            errorElement: <SomethingWentWrong />,
            children: [
                {
                    path: '/',
                    element: (
                        <ProtectedRoute routeKey={ERoute.HOME}>
                            <HomeProvider>
                                <HomePage />
                            </HomeProvider>
                        </ProtectedRoute>
                    )
                },
                {
                    path: routes.HOME,
                    element: (
                        <ProtectedRoute routeKey={ERoute.HOME}>
                            <HomeProvider>
                                <HomePage />
                            </HomeProvider>
                        </ProtectedRoute>
                    )
                },
                {
                    path: routes.SKILL,
                    element: (
                        <ProtectedRoute routeKey={ERoute.SKILL}>
                            <SkillProvider>
                                <SkillPage />
                            </SkillProvider>
                        </ProtectedRoute>
                    ),
                    handle: defaultCrumbHandler()
                },
                {
                    path: routes.OUTCOME,
                    element: (
                        <ProtectedRoute routeKey={ERoute.OUTCOME}>
                            <OutcomeProvider>
                                <OutcomePage></OutcomePage>
                            </OutcomeProvider>
                        </ProtectedRoute>
                    ),
                    handle: defaultCrumbHandler()
                },
                {
                    path: routes.OUTCOMES,
                    element: (
                        <ProtectedRoute routeKey={ERoute.OUTCOMES}>
                            <OutcomesTabsPage />
                        </ProtectedRoute>
                    ),
                    children: [
                        {
                            path: routes.USER_OUTCOMES,
                            element: (
                                <ProtectedRoute routeKey={ERoute.USER_OUTCOMES}>
                                    <OutcomesProvider>
                                        <OutcomesPage />
                                    </OutcomesProvider>
                                </ProtectedRoute>
                            ),
                            handle: defaultCrumbHandler()
                        },
                        {
                            path: routes.ADMIN_OUTCOMES,
                            element: (
                                <ProtectedRoute routeKey={ERoute.ADMIN_OUTCOMES}>
                                    <AdminOutcomesProvider>
                                        <AdminOutcomesPage />
                                    </AdminOutcomesProvider>
                                </ProtectedRoute>
                            ),
                            handle: defaultCrumbHandler()
                        },
                        {
                            path: routes.HISTORY_OUTCOMES,
                            element: (
                                <ProtectedRoute routeKey={ERoute.OUTCOMES_HISTORY}>
                                    <AdminHistoryProvider>
                                        <HistoryPage />
                                    </AdminHistoryProvider>
                                </ProtectedRoute>
                            ),
                            handle: defaultCrumbHandler()
                        }
                    ],
                    handle: defaultCrumbHandler()
                },
                {
                    path: routes.SKILLS,
                    element: (
                        <ProtectedRoute routeKey={ERoute.SKILLS}>
                            <SkillsProvider>
                                <SkillsPage />
                            </SkillsProvider>
                        </ProtectedRoute>
                    ),
                    handle: defaultCrumbHandler()
                },
                {
                    path: routes.SETTINGS,
                    element: (
                        <ProtectedRoute routeKey={ERoute.SETTINGS}>
                            <SettingsProvider>
                                <SettingsPage />
                            </SettingsProvider>
                        </ProtectedRoute>
                    ),
                    children: [
                        {
                            path: routes.SETTINGS_PERSONAL,
                            element: <PersonalSettingsPage />
                        },
                        {
                            path: routes.SETTINGS_ORGANIZATIONAL,
                            element: <OrganizationalSettingsPage />
                        }
                    ],
                    handle: defaultCrumbHandler()
                },
                {
                    path: routes.ADMIN,
                    element: (
                        <ProtectedRoute routeKey={ERoute.ADMIN}>
                            <AdminPage></AdminPage>
                        </ProtectedRoute>
                    ),
                    children: [
                        {
                            path: routes.ADMIN_LICENSES,
                            element: (
                                <ProtectedRoute routeKey={ERoute.ADMIN_LICENSES}>
                                    <LicensesProvider>
                                        <LicensesPage />
                                    </LicensesProvider>
                                </ProtectedRoute>
                            )
                        },
                        {
                            path: routes.ADMIN_ROLES,
                            element: (
                                <ProtectedRoute routeKey={ERoute.ADMIN_ROLES}>
                                    <RolesProvider>
                                        <RolesPage />
                                    </RolesProvider>
                                </ProtectedRoute>
                            )
                        },
                        {
                            path: '',
                            element: (
                                <AdminHistoryProvider>
                                    <AdminHistoryDetailsProvider>
                                        <Outlet />
                                    </AdminHistoryDetailsProvider>
                                </AdminHistoryProvider>
                            ),
                            children: [
                                {
                                    path: routes.ADMIN_HISTORY,
                                    element: (
                                        <ProtectedRoute routeKey={ERoute.ADMIN_HISTORY}>
                                            <HistoryPage />
                                        </ProtectedRoute>
                                    )
                                },
                                {
                                    path: routes.ADMIN_HISTORY_DETAILS,
                                    element: (
                                        <ProtectedRoute routeKey={ERoute.ADMIN_HISTORY_DETAILS}>
                                            <HistoryDetails />
                                        </ProtectedRoute>
                                    )
                                }
                            ]
                        }
                    ],
                    handle: defaultCrumbHandler()
                },
                {
                    path: routes.FEEDBACK,
                    element: (
                        <ProtectedRoute routeKey={ERoute.FEEDBACK}>
                            <FeedbackPage />
                        </ProtectedRoute>
                    )
                },
                {
                    path: routes.INSIGHTS,
                    element: (
                        <InsightsProvider>
                            <Outlet />
                        </InsightsProvider>
                    ),
                    children: [
                        {
                            path: routes.INSIGHTS_PERSONAL,
                            element: (
                                <ProtectedRoute routeKey={ERoute.INSIGHTS_PERSONAL}>
                                    <InsightsPersonalProvider>
                                        <InsightsOrganizationProvider>
                                            <InsightsPage />
                                        </InsightsOrganizationProvider>
                                    </InsightsPersonalProvider>
                                </ProtectedRoute>
                            ),
                            children: [
                                {
                                    path: routes.INSIGHTS_PERSONAL_SKILLS,
                                    element: (
                                        <InsightsPersonalSkillsProvider>
                                            <PersonalInsightsSkillsTab />
                                        </InsightsPersonalSkillsProvider>
                                    )
                                },
                                {
                                    path: routes.INSIGHTS_PERSONAL_OUTCOMES,
                                    element: (
                                        <InsightsPersonalOutcomesProvider>
                                            <PersonalInsightsOutcomesTab />
                                        </InsightsPersonalOutcomesProvider>
                                    )
                                },
                                {
                                    path: routes.INSIGHTS_PERSONAL_ENGAGEMENT,
                                    element: (
                                        <InsightsPersonalEngagementProvider>
                                            <PersonalInsightsEngagementTab />
                                        </InsightsPersonalEngagementProvider>
                                    )
                                },
                                {
                                    path: routes.INSIGHTS_PERSONAL_BADGES,
                                    element: (
                                        <InsightsPersonalBadgesProvider>
                                            <PersonalInsightsBadgesTab />
                                        </InsightsPersonalBadgesProvider>
                                    )
                                },
                                {
                                    path: routes.INSIGHTS_ORGANIZATIONAL_USER_SKILLS,
                                    element: (
                                        <InsightsPersonalSkillsProvider>
                                            <PersonalInsightsSkillsTab />
                                        </InsightsPersonalSkillsProvider>
                                    )
                                },
                                {
                                    path: routes.INSIGHTS_ORGANIZATIONAL_USER_OUTCOMES,
                                    element: (
                                        <InsightsPersonalOutcomesProvider>
                                            <PersonalInsightsOutcomesTab />
                                        </InsightsPersonalOutcomesProvider>
                                    )
                                },
                                {
                                    path: routes.INSIGHTS_ORGANIZATIONAL_USER_BADGES,
                                    element: (
                                        <InsightsPersonalBadgesProvider>
                                            <PersonalInsightsBadgesTab />
                                        </InsightsPersonalBadgesProvider>
                                    )
                                },
                                {
                                    path: routes.INSIGHTS_ORGANIZATIONAL_USER_ENGAGEMENT,
                                    element: (
                                        <InsightsPersonalEngagementProvider>
                                            <PersonalInsightsEngagementTab />
                                        </InsightsPersonalEngagementProvider>
                                    )
                                }
                            ]
                        },
                        {
                            path: routes.INSIGHTS_ORGANIZATIONAL,
                            element: (
                                <ProtectedRoute routeKey={ERoute.INSIGHTS_ORGANIZATION}>
                                    <InsightsOrganizationProvider>
                                        <InsightsPage />
                                    </InsightsOrganizationProvider>
                                </ProtectedRoute>
                            ),
                            children: [
                                {
                                    path: routes.INSIGHTS_ORGANIZATIONAL_USERS,
                                    element: (
                                        <InsightsOrganizationalUsersProvider>
                                            <OrganizationalInsightsUsersTab />
                                        </InsightsOrganizationalUsersProvider>
                                    )
                                },
                                {
                                    path: routes.INSIGHTS_ORGANIZATIONAL_OUTCOMES,
                                    element: (
                                        <InsightsOrganizationalOutcomesProvider>
                                            <OrganizationalInsightsOutcomesTab />
                                        </InsightsOrganizationalOutcomesProvider>
                                    )
                                },
                                {
                                    path: routes.INSIGHTS_ORGANIZATIONAL_OUTCOMES_DETAILS,
                                    element: (
                                        <InsightsOrganizationOutcomesDetailsProvider>
                                            <OrganizationalInsightsOutcomesDetails />
                                        </InsightsOrganizationOutcomesDetailsProvider>
                                    )
                                },
                                {
                                    path: routes.INSIGHTS_ORGANIZATIONAL_SKILL_DETAILS,
                                    element: (
                                        <InsightsOrganizationalSkillProvider>
                                            <OrganizationalSkillDetailsTab />
                                        </InsightsOrganizationalSkillProvider>
                                    )
                                },
                                {
                                    path: routes.INSIGHTS_ORGANIZATIONAL_ENGAGEMENT,
                                    element: (
                                        <InsightsOrganizationEngagementProvider>
                                            <OrganizationalInsightsEngagementTab />
                                        </InsightsOrganizationEngagementProvider>
                                    )
                                },
                                {
                                    path: routes.INSIGHTS_ORGANIZATIONAL_BADGES,
                                    element: (
                                        <InsightsOrganizationBadgesProvider>
                                            <OrganizationalInsightsBadgesTab />
                                        </InsightsOrganizationBadgesProvider>
                                    )
                                },
                                {
                                    path: routes.INSIGHTS_ORGANIZATIONAL_SINGLE_BADGE,
                                    element: (
                                        <InsightsOrganizationSingleBadgeProvider>
                                            <OrganizationalInsightsSingleBadgeTab />
                                        </InsightsOrganizationSingleBadgeProvider>
                                    )
                                },
                                {
                                    path: routes.INSIGHTS_ORGANIZATIONAL_ASSESSMENT,
                                    element: (
                                        <InsightsOrganizationalAssessmentProvider>
                                            <OrganizationalInsightsAssessmentTab />
                                        </InsightsOrganizationalAssessmentProvider>
                                    )
                                },
                                {
                                    path: routes.INSIGHTS_ORGANIZATIONAL_ASSESSMENT_DETAILS,
                                    element: (
                                        <InsightsOrganizationalAssessmentDetailsProvider>
                                            <OrganizationalAssessmentDetails />
                                        </InsightsOrganizationalAssessmentDetailsProvider>
                                    )
                                }
                            ]
                        }
                    ],
                    handle: defaultCrumbHandler()
                },
                {
                    path: routes.LINKEDIN,
                    element: (
                        <ProtectedRoute routeKey={ERoute.LINKEDIN}>
                            <LinkedinPage />
                        </ProtectedRoute>
                    )
                },
                {
                    path: routes.INSTRUCTIONS,
                    element: (
                        <ProtectedRoute routeKey={ERoute.INSTRUCTIONS}>
                            <InstructionsPage />
                        </ProtectedRoute>
                    )
                },
                {
                    path: routes.SHOWCASE_HOME,
                    element: (
                        <ProtectedRoute routeKey={ERoute.SHOWCASE_HOME}>
                            <ShowcaseProvider>
                                <ShowcaseHome />
                            </ShowcaseProvider>
                        </ProtectedRoute>
                    )
                },
                {
                    path: '',
                    element: (
                        <ProtectedRoute routeKey={ERoute.SHOWCASE_HOME}>
                            <ShowcaseProvider>
                                <Outlet />
                            </ShowcaseProvider>
                        </ProtectedRoute>
                    ),
                    children: [
                        {
                            path: routes.SHOWCASE_HOME,
                            element: <ShowcaseHome />
                        },
                        {
                            path: routes.ACHIEVEMENTS_ALL_BY_STATE,
                            element: <AchievementsPreview />
                        },
                        {
                            path: routes.ACHIEVEMENT_DETAIL,
                            element: (
                                <AchievementsDetailProvider>
                                    <AchievementsDetail />
                                </AchievementsDetailProvider>
                            )
                        },
                        {
                            path: routes.BADGES_ALL_BY_CATEGORY,
                            element: <BadgesAllByCategory />
                        }
                    ]
                },
                {
                    path: routes.HOW_TO,
                    element: (
                        <ProtectedRoute routeKey={ERoute.HOW_TO}>
                            <HowToProvider>
                                <HowTo />
                            </HowToProvider>
                        </ProtectedRoute>
                    )
                },
                {
                    path: routes.HOW_TO_PLAYLIST,
                    element: (
                        <ProtectedRoute routeKey={ERoute.HOW_TO_PLAYLIST}>
                            <HowToPlaylistProvider>
                                <HowToPlaylist />
                            </HowToPlaylistProvider>
                        </ProtectedRoute>
                    )
                },
                {
                    path: routes.HOW_TOS_HOMEPAGE,
                    element: (
                        <ProtectedRoute routeKey={ERoute.HOW_TOS_HOMEPAGE}>
                            <HowTosProvider>
                                <HowTosHomepage />
                            </HowTosProvider>
                        </ProtectedRoute>
                    )
                },
                {
                    path: routes.PAGE_FORBIDDEN,
                    element: <PageForbidden />
                },
                {
                    path: '*',
                    element: <PageNotFound />
                }
            ]
        },

        {
            path: '/partner',
            element: (
                <PartnerPortalThemeProvider>
                    <TabsProvider>
                        <AccountsProvider>
                            <ScrollToTop />
                            <MainPartnerPortalLayout />
                        </AccountsProvider>
                    </TabsProvider>
                </PartnerPortalThemeProvider>
            ),
            children: [
                {
                    path: routes.PARTNER_ACCOUNTS,
                    element: (
                        <ProtectedRoute routeKey={ERoute.PARTNER_ACCOUNTS}>
                            <AccountsProvider>
                                <Accounts />
                            </AccountsProvider>
                        </ProtectedRoute>
                    )
                },
                {
                    path: routes.PARTNER_ACCOUNT_DETAILS,
                    element: (
                        <ProtectedRoute routeKey={ERoute.PARTNER_ACCOUNT_DETAILS}>
                            <AccountDetailsProvider>
                                <AccountDetails />
                            </AccountDetailsProvider>
                        </ProtectedRoute>
                    )
                },
                {
                    path: routes.PARTNER_PARTNERS,
                    element: (
                        <ProtectedRoute routeKey={ERoute.PARTNER_PARTNERS}>
                            <AdminProvider>
                                <Admin />
                            </AdminProvider>
                        </ProtectedRoute>
                    )
                },
                {
                    path: routes.PARTNER_RESOURCES,
                    element: (
                        <ProtectedRoute routeKey={ERoute.PARTNER_RESOURCES}>
                            <ResourcesProvider>
                                <Resources />
                            </ResourcesProvider>
                        </ProtectedRoute>
                    )
                },
                {
                    path: routes.PARTNER_CONTACT,
                    element: (
                        <ProtectedRoute routeKey={ERoute.PARTNER_CONTACT}>
                            <Contact />
                        </ProtectedRoute>
                    )
                },
                {
                    path: routes.PARTNER_STATEMENTS,
                    element: (
                        <ProtectedRoute routeKey={ERoute.PARTNER_STATEMENTS}>
                            <StatementsProvider>
                                <Statements />
                            </StatementsProvider>
                        </ProtectedRoute>
                    )
                },
                {
                    path: routes.PARTNER_USER,
                    element: (
                        <ProtectedRoute routeKey={ERoute.PARTNER_USER}>
                            <PartnerUserProvider>
                                <PartnerUser />
                            </PartnerUserProvider>
                        </ProtectedRoute>
                    )
                }
            ]
        },
        {
            path: routes.PAGE_FORBIDDEN,
            element: <PageForbidden />
        },
        {
            path: '*',
            element: <PageNotFound />
        }
    ]);

    if (
        !isCurrentUserDataLoading &&
        !currentUserData &&
        routes.NO_TENANT_NULIA_LICENSE_STATUS === window.location.pathname
    ) {
        return <NoTenantNuliaLicenseStatus />;
    }

    if (
        !isCurrentUserDataLoading &&
        !currentUserData &&
        routes.NO_LICENSE_NULIA_LICENSE_STATUS === window.location.pathname
    ) {
        return <NoLicenseNuliaLicenseStatus />;
    }

    if (!currentUserData)
        return (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Loading />
            </div>
        );

    return (
        <ToastProvider>
            <MainContentBox isSidemenuOpen={showNav}>
                <RouterProvider router={router} fallbackElement={<SomethingWentWrong />} />
            </MainContentBox>
        </ToastProvider>
    );
};

export default Routes;
