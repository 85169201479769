import { useState } from 'react';

interface ITableReturn<T> {
    handleChangePage: (event: unknown, newPage: number) => void;
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleClick: (event: React.MouseEvent<unknown>, name: string) => void;
    handleSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isSelected: (name: string) => boolean;
    handleRequestSort: (property: keyof T) => void;
    page: number;
    rowsPerPage: number;
    selected: string[];
    order: Order;
    // orderBy: keyof T;
    orderBy: any;
    getComparator: any;
    stableSort: (array: readonly T[], comparator: (a: T, b: T, order?: Order) => number) => T[];
    setSelected: (selected: string[]) => void;
    setPage: (page: number) => void;
}

interface ITableProps<T> {
    data: T[];
    initialOrderBy?: string;
    initialOrderDirection?: Order;
    initialRowsPerPage?: number;
    disableRowsIds?: (string | number)[];
}

export type Order = 'asc' | 'desc';

function useTable<T>({
    data,
    initialOrderBy,
    initialOrderDirection,
    initialRowsPerPage,
    disableRowsIds
}: ITableProps<T>): ITableReturn<T> {
    const [order, setOrder] = useState<Order>(initialOrderDirection || 'asc');
    // @ts-ignore
    const [orderBy, setOrderBy] = useState<keyof Data>(initialOrderBy || 'name');
    const [selected, setSelected] = useState<string[]>([]);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(initialRowsPerPage ?? 10);

    const handleRequestSort = (property: keyof T) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            // @ts-ignore
            const newSelected = data.filter((row) => !disableRowsIds?.includes(row.id)).map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    function getComparator<Key extends keyof any>(
        order: Order,
        orderBy: Key
    ): (a: { [key in Key]: number | string | boolean }, b: { [key in Key]: number | string | boolean }) => number {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        if (disableRowsIds?.includes(name)) return;
        const selectedIndex = selected.indexOf(name);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function stableSort<T>(array: readonly T[], comparator: (a: T, b: T, order?: Order) => number) {
        const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
        stabilizedThis.sort((a, b) => {
            const orderResult = comparator(a[0], b[0], order);
            if (orderResult !== 0) {
                return orderResult;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    return {
        handleChangePage,
        handleChangeRowsPerPage,
        isSelected,
        handleClick,
        handleSelectAllClick,
        handleRequestSort,
        page,
        rowsPerPage,
        selected,
        order,
        orderBy,
        getComparator,
        stableSort,
        setSelected,
        setPage
    };
}

export default useTable;
