import {
    NavigationSidemenu,
    styled,
    useNavigationStateValue,
    useTabsStateValue,
    useUserAuthStateValue,
    useWizardConfig,
    createFullVideoUrls,
    IFullVideoUrlsResponse,
    EActivityType,
    usePostLaunchActivity,
    usePostMessage,
    ELaunchType,
    useApplicationStateValue
} from 'nulia-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ShepherdTour } from 'react-shepherd';
import PageHeader from './page-header/PageHeader';

export const SidemenuControlBox = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 56,
    height: 40,
    cursor: 'pointer',
    left: 0,
    top: 0,
    backgroundColor: 'blue'
});

export const RootBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'applyPadding' && prop !== 'isOpen'
})<{ applyPadding: boolean; isOpen: boolean }>(({ theme, applyPadding, isOpen }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    paddingBottom: applyPadding ? '20px' : 'unset',
    [theme.breakpoints.down('lg')]: {
        width: !isOpen ? '100%' : '100%'
    }
}));

export const OutletWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isOpen'
})<{ isOpen: boolean }>(({ isOpen }) => ({
    marginLeft: isOpen ? '110px' : '0px',
    width: isOpen ? 'calc(100% - 110px)' : '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative'
}));

export const HeaderWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isOpen'
})<{ isOpen: boolean }>(({ isOpen }) => ({
    width: isOpen ? 'calc(100% - 110px)' : '100%',
    marginLeft: isOpen ? '110px' : '0px'
}));

const MainLayout = () => {
    const { showNav } = useNavigationStateValue();
    const { changeSearchText, searchText } = useTabsStateValue();
    const { config, tourOptions } = useWizardConfig();
    const { currentUserData } = useUserAuthStateValue();
    const { mutateAsync: mutatePostLaunchActivityAsync } = usePostLaunchActivity();
    const { isTeamsEnv } = useApplicationStateValue();

    const postLaunchActivityCallback = async (targetId: string, launchType: ELaunchType) => {
        const response = await mutatePostLaunchActivityAsync({ activityId: targetId, launchType });
        window.postMessage({ targetId, lastLaunched: response.lastLaunched }, window.location.origin);
    };

    usePostMessage({
        postMessageEvents: [
            {
                type: ELaunchType.VIDEO_WATCHED,
                callback: postLaunchActivityCallback
            },
            {
                type: ELaunchType.PAGE_SCROLLED,
                callback: postLaunchActivityCallback
            }
        ]
    });

    return (
        <>
            {/* @ts-ignore */}
            <ShepherdTour steps={config} tourOptions={tourOptions}>
                <RootBox applyPadding={true} isOpen={showNav && !isTeamsEnv}>
                    <NavigationSidemenu />
                    <HeaderWrapper isOpen={showNav && !isTeamsEnv}>
                        <PageHeader onSearchInputValueChange={changeSearchText} searchInputValue={searchText} />
                    </HeaderWrapper>
                    <OutletWrapper isOpen={showNav && !isTeamsEnv}>
                        <Outlet />
                    </OutletWrapper>
                </RootBox>
            </ShepherdTour>
        </>
    );
};

export default MainLayout;
