import { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Dialog from '../../../../components/dialogs/dialog/Dialog';
import { EAccountType } from '../../../../interfaces/enums/EAccountType';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    connectToTenantDialog: {},
    text: {
        color: 'rgba(0,0,0,0.64)',
        fontFamily: 'Open Sans',
        fontSize: 16,
        letterSpacing: 0.5,
        lineHeight: 1.5
    },
    topText: {
        marginBottom: 32
    }
});

interface Props {
    accountType: EAccountType;
    open: boolean;
    onClose: () => void;
    connectUrl: string;
}

const ConnectToTenantDialog: FC<Props> = ({ accountType, open, onClose, connectUrl }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const copyUrl = () => {
        if (accountType === EAccountType.CUSTOMER) {
            navigator.clipboard.writeText(`${connectUrl}`);
        }
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            onAccept={copyUrl}
            title={t('accountDetails.connection.connectToTenantModal.headerTitle')}
            acceptText={t('accountDetails.connection.connectToTenantModal.acceptButton')}
            cancelText={t('accountDetails.connection.connectToTenantModal.cancelButton')}
            acceptButtonWidth={168}
            width={714}
            minHeight='300px'
            content={
                <div className={classes.connectToTenantDialog}>
                    <div className={clsx(classes.text, classes.topText)}>
                        {t('accountDetails.connection.connectToTenantModal.description1')}
                    </div>
                    <div className={classes.text}>
                        {t('accountDetails.connection.connectToTenantModal.description2')}
                    </div>
                </div>
            }
        />
    );
};

export default ConnectToTenantDialog;
