import {
    ActionBox,
    ActionButtonBox,
    ActionsBox,
    ActionTitle,
    ActiveConnectionBox,
    CategoryWrapper,
    CollectorBox,
    ConnectionDateTypography
} from './Style';
import { Loading, PrimaryButton, SecondaryButton } from 'nulia-ui';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { EConnectionStatus } from '../../../interfaces/enums/EConnectionStatus';
import ConnectToTenantDialog from './connect-to-tenant-dialog/ConnectToTenantDialog';
import SendConnectInstructionsDialog from './send-connect-instructions-dialog/SendConnectInstructionsDialog';
import { EAccountType } from '../../../interfaces/enums/EAccountType';
import CollectorStatus from './collector-status/CollectorStatus';
import BasicStatusCard, { EStatus } from '../../../components/basic-status-card/BasicStatusCard';
import { useGetCustomerCollectorStatusQuery } from '../../../services/AccountsQueryService';
import { useParams } from 'react-router-dom';

interface IProps {
    connectionStatus: EConnectionStatus;
    changeConnectionStatus: (newConnectionStatus: EConnectionStatus) => void;
    accountType: EAccountType;
    defaultExpanded?: boolean;
    isLoading: boolean;
    connectUrl?: string;
}

const Connection: FC<IProps> = ({
    connectionStatus,
    accountType,
    defaultExpanded = false,
    changeConnectionStatus,
    isLoading,
    connectUrl
}) => {
    const params = useParams<{ id: string }>();

    const [openSendInstructionsDialog, setOpenSendInstructionsDialog] = useState<boolean>(false);
    const [openConnectDialog, setOpenConnectDialog] = useState<boolean>(false);
    const isReconnectModeRef = useRef<boolean>(false);
    const {
        data: fetchedCollectorStatusData,
        isLoading: isCollectorStatusLoading,
        isError: isCollectorStatusError,
        refetch: refetchCollectorStatus
    } = useGetCustomerCollectorStatusQuery(params?.id);

    useEffect(() => {
        if (connectionStatus === EConnectionStatus.ACTIVE) {
            isReconnectModeRef.current = true;
        }
    }, [connectionStatus]);

    const toggleTenantDialog = () => {
        setOpenConnectDialog((openConnectDialog) => !openConnectDialog);
    };

    const toggleSendInstructionsDialog = () => {
        setOpenSendInstructionsDialog((openSendInstructionsDialog) => !openSendInstructionsDialog);
    };

    const contentMemo = useMemo(() => {
        switch (connectionStatus) {
            case EConnectionStatus.IN_PROGRESS:
                return (
                    <>
                        <ActionBox>
                            <ActionTitle>I am the administrator for this tenant</ActionTitle>
                            <ActionButtonBox>
                                <PrimaryButton
                                    style={{ width: '176px' }}
                                    title='Connect'
                                    clickHandler={toggleTenantDialog}
                                />
                            </ActionButtonBox>
                        </ActionBox>
                        <ActionBox>
                            <ActionTitle>I am NOT the administrator for this tenant</ActionTitle>
                            <ActionButtonBox>
                                <PrimaryButton
                                    style={{ width: '196px' }}
                                    title='Send Instructions'
                                    clickHandler={toggleSendInstructionsDialog}
                                />
                            </ActionButtonBox>
                        </ActionBox>
                    </>
                );
            case EConnectionStatus.ACTIVE:
                return (
                    <ActiveConnectionBox>
                        <ConnectionDateTypography>
                            Successfully connected on <strong>Sep 1, 2020</strong>
                        </ConnectionDateTypography>
                        <SecondaryButton
                            onClick={() => {
                                changeConnectionStatus(EConnectionStatus.IN_PROGRESS);
                            }}
                            style={{ width: '170px' }}
                        >
                            Reconnect
                        </SecondaryButton>
                    </ActiveConnectionBox>
                );
            default:
                return <></>;
        }
    }, [connectionStatus]);

    return (
        <BasicStatusCard title='Connection' status={EStatus.SUCCESS} defaultExpanded={defaultExpanded}>
            {isLoading ? (
                <Loading />
            ) : (
                <CategoryWrapper isContentLoading={isLoading}>
                    <ActionsBox>{contentMemo}</ActionsBox>
                    <CollectorBox>
                        <CollectorStatus
                            isLoading={isCollectorStatusLoading}
                            collectorStatus={fetchedCollectorStatusData}
                            isError={isCollectorStatusError}
                            refetch={refetchCollectorStatus}
                        />
                    </CollectorBox>
                </CategoryWrapper>
            )}

            {connectUrl && (
                <ConnectToTenantDialog
                    accountType={accountType}
                    open={openConnectDialog}
                    onClose={toggleTenantDialog}
                    connectUrl={connectUrl}
                />
            )}
            <SendConnectInstructionsDialog
                accountType={accountType}
                open={openSendInstructionsDialog}
                onClose={toggleSendInstructionsDialog}
            />
        </BasicStatusCard>
    );
};

export default Connection;
