import { createContext, FC, useContext, PropsWithChildren } from 'react';
import { IStatementVM } from '../interfaces/views/IStatementVM';
import { useGetStatements } from '../services/statements/useGetStatements';

export interface IStatementsContext {
    statementsData?: IStatementVM[];
    isStatementsDataLoading: boolean;
    isStatementsDataFetchingError: boolean;
    refetchStatementsCallback: () => void;
}

export const StatementsContext = createContext<IStatementsContext>({} as IStatementsContext);

interface IProps {}

export const StatementsProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const {
        data: fetchedStatementsData,
        isLoading: isStatementsDataLoading,
        isError: isStatementsDataFetchingError,
        refetch: refetchStatementsCallback
    } = useGetStatements();

    const statementsState: IStatementsContext = {
        statementsData: fetchedStatementsData,
        isStatementsDataLoading,
        isStatementsDataFetchingError,
        refetchStatementsCallback
    };

    return <StatementsContext.Provider value={statementsState}>{children}</StatementsContext.Provider>;
};

export const useStatementsStateValue: () => IStatementsContext = () => useContext(StatementsContext);
