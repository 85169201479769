import { getTokenAndHeaders, IAuthParams, useAuthParams } from 'nulia-ui';
import { apiInstance } from './util/apiInstance';
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from '@tanstack/react-query';
import { EApiQueryKey } from '../types/enums/EApiQueryKey';
import { mapFolderLicenseSummary } from './mappers';
import { ILicenseSummaryVM } from '../interfaces/views/ILicenseSummaryVM';

// GET FOLDER LICENSE SUMMARY
export const getFolderLicenseSummary = async (authParams: IAuthParams, folderId: string | undefined) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`folder/${folderId}/licenseSummary`, headers);
    return mapFolderLicenseSummary(data);
};

export const useGetFolderLicenseSummaryQuery: (
    folderId: string | undefined
) => UseQueryResult<ILicenseSummaryVM, Error> = (folderId) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.FOLDER_LICENSE_SUMMARY_FETCH, folderId],
        queryFn: async () => {
            return getFolderLicenseSummary(authParams, folderId);
        },
        refetchOnWindowFocus: false,
        enabled: false
    });
};

// POST UPDATE FOLDER NAME
export const putUpdateFolderName: (
    variables: { folderId: string; name: string } & { authParams: IAuthParams }
) => Promise<undefined> = async (variables) => {
    const { headers } = await getTokenAndHeaders(variables.authParams);

    const {
        data: { data }
    } = await apiInstance.put(
        `folder/${variables.folderId}`,
        {
            name: variables.name
        },
        {
            headers: {
                ...headers.headers
            }
        }
    );
    return data;
};

export const usePutUpdateFolderName: () => UseMutationResult<any, Error, any> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: async (variables: { folderId: string; name: string }) => {
            return putUpdateFolderName({ ...variables, authParams });
        }
    });
};
