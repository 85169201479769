import { styled } from '@mui/material';

export const RootBox = styled('div')({
    width: '100%'
});

export const StyledForm = styled('form')({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
});

export const ActionBox = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '24px'
});

export const InputBox = styled('div')({
    padding: '0 24px 16px 24px'
});
