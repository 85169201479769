import { FC, useRef } from 'react';
import Dialog from '../dialog/Dialog';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { StyledForm } from './Style';
import { FormControl } from '@mui/material';
import { EToastSeverity, NTextInput, useToastContextStateValue } from 'nulia-ui';
import { usePostAddPartnerUser } from '../../../services/partner-user/usePostAddPartnerUser';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onAddPartnerUserSuccessCallback?: () => void;
    partnerId?: string;
}

const AddPartnerUserDialog: FC<Props> = ({ isOpen, onClose, onAddPartnerUserSuccessCallback, partnerId }) => {
    const { t } = useTranslation();
    const {
        reset,
        formState: { isValid, errors, isDirty },
        control,
        handleSubmit,
        getValues
    } = useForm({
        mode: 'onChange'
    });
    const formRef = useRef<HTMLFormElement>(null);
    const { setToastMessage } = useToastContextStateValue();
    const { mutateAsync: mutateAddPartnerUserAsync, isPending: isAddPartnerUserPending } = usePostAddPartnerUser();

    const handleClose = () => {
        resetForm();
        onClose();
    };

    const handleSubmitForm = async () => {
        if (!partnerId) {
            setToastMessage({
                isOpen: true,
                message: t('errorToastMessages.partnerUsers.add'),
                severity: EToastSeverity.ERROR
            });
            return;
        }
        const formValues = getValues();
        try {
            await mutateAddPartnerUserAsync({
                partnerId: partnerId,
                addPartnerUserDTO: {
                    name: formValues.name,
                    email: formValues.email,
                    userOid: formValues.userOid
                }
            });
            setToastMessage({
                isOpen: true,
                message: t('successToastMessages.partnerUsers.add'),
                severity: EToastSeverity.SUCCESS
            });
            if (onAddPartnerUserSuccessCallback) onAddPartnerUserSuccessCallback();
            handleClose();
        } catch (err) {
            console.error(err);
            setToastMessage({
                isOpen: true,
                message: t('errorToastMessages.partnerUsers.add'),
                severity: EToastSeverity.ERROR
            });
            handleClose();
        }
    };

    const resetForm = () => {
        reset();
    };

    return (
        <Dialog
            open={isOpen}
            onAccept={handleSubmitForm}
            acceptText={t('partnerUsers.addDialog.confirmButton')}
            onClose={handleClose}
            cancelText={t('partnerUsers.addDialog.cancelButton')}
            title={t('partnerUsers.addDialog.title')}
            isSubmitActionDisabled={!isValid || isAddPartnerUserPending || !isDirty}
            minHeight='200px'
            content={
                <div>
                    <StyledForm ref={formRef} onSubmit={handleSubmit(handleSubmitForm)}>
                        <FormControl fullWidth>
                            <Controller
                                name={'name'}
                                control={control}
                                rules={{
                                    required: true,
                                    maxLength: {
                                        message: 'Maximum length is 100',
                                        value: 100
                                    }
                                }}
                                render={({ field }) => (
                                    <NTextInput
                                        field={field}
                                        label={t('partnerUsers.addDialog.name')}
                                        value={field.value}
                                        onChange={field.onChange}
                                        errorMessage={errors['name']?.message as string}
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <Controller
                                name={'email'}
                                control={control}
                                rules={{
                                    required: true,
                                    maxLength: {
                                        message: 'Maximum length is 255',
                                        value: 255
                                    },
                                    pattern: {
                                        value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                        message: 'Invalid email address'
                                    }
                                }}
                                render={({ field }) => (
                                    <NTextInput
                                        field={field}
                                        label={t('partnerUsers.addDialog.email')}
                                        value={field.value}
                                        onChange={field.onChange}
                                        errorMessage={errors['email']?.message as string}
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <Controller
                                name={'userOid'}
                                control={control}
                                rules={{
                                    required: true,
                                    maxLength: {
                                        message: 'Maximum length is 100',
                                        value: 100
                                    },
                                    pattern: {
                                        value: /^\d{8}-\d{4}-\d{4}-\d{4}-\d{12}$/,
                                        message: "Value doesn't follow pattern 00000000-0000-0000-0000-000000000000"
                                    }
                                }}
                                render={({ field }) => (
                                    <NTextInput
                                        field={field}
                                        label={t('partnerUsers.addDialog.oid')}
                                        value={field.value}
                                        onChange={field.onChange}
                                        errorMessage={errors['userOid']?.message as string}
                                    />
                                )}
                            />
                        </FormControl>
                    </StyledForm>
                </div>
            }
        />
    );
};

export default AddPartnerUserDialog;
