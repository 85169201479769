import { FC, useCallback } from 'react';
import Page from '../../components/page/Page';
import PageContent from '../../components/page-content/PageContent';
import { ActionBox, PartnersContentBox, TableActionBox, TableActionsBox, TableCard } from './Style';
import { IconButton, ITableHeadCell, routes, Table, Tooltip, useCrumbsStateValue, useModal } from 'nulia-ui';
import { PlusCircle, User } from 'react-feather';
import AddPartnerDialog from '../../components/dialogs/add-partner-dialog/AddPartnerDialog';
import { useAdminStateValue } from '../../contexts/AdminContext';
import { useTranslation } from 'react-i18next';
import { IPartnerVM } from '../../interfaces/views/IPartnerVM';
import { useNavigate } from 'react-router-dom';

interface ITableHeader {
    adTenantId: string;
    name: string;
    region: string;
    userCount: number;
    actionsRow: string;
}

const Admin: FC = () => {
    const { data, isPartnersDataLoading, isPartnersDataFetchingError, refetchPartnersCallback } = useAdminStateValue();
    const {
        isOpen: isAddPartnerModalOpen,
        open: openPartnerModal,
        close: closePartnerModal
    } = useModal({ defaultOpen: false });
    const { appendCrumb } = useCrumbsStateValue();

    const { t } = useTranslation();
    const navigate = useNavigate();

    const onAddPartnerConfirmSuccessCallback = useCallback(() => {
        refetchPartnersCallback();
        closePartnerModal();
    }, []);

    const headCells: ITableHeadCell<ITableHeader>[] = [
        {
            id: 'name',
            label: t('partners.table.companyName')
        },
        {
            id: 'adTenantId',
            label: t('partners.table.adTenantId')
        },
        {
            id: 'region',
            label: t('partners.table.region'),
            align: 'center'
        },
        {
            id: 'userCount',
            label: t('partners.table.userCount'),
            align: 'center'
        },
        {
            id: 'actionsRow',
            label: '',
            disablePadding: true,
            disableSort: true,
            customRender(value, row: IPartnerVM) {
                return (
                    <TableActionsBox>
                        <TableActionBox onClick={() => onPartnerTableRowClick(row.id)}>
                            <Tooltip title='See Partner Users'>
                                <User />
                            </Tooltip>
                        </TableActionBox>
                    </TableActionsBox>
                );
            }
        }
    ];

    const onPartnerTableRowClick = (id: string) => {
        const pathname = routes.PARTNER_USER.replaceAll(':id', id);
        navigate(pathname);
        appendCrumb({
            name: 'Admin',
            pathname
        });
    };

    return (
        <Page>
            <PageContent>
                <PartnersContentBox>
                    <ActionBox>
                        <IconButton text='Partner' startIcon={<PlusCircle />} clickHandler={openPartnerModal} />
                    </ActionBox>
                    <TableCard>
                        <Table
                            headCells={headCells}
                            isLoading={isPartnersDataLoading}
                            data={data}
                            propertyKeys={headCells.map((headCell) => headCell.id)}
                            isError={isPartnersDataFetchingError}
                            refetchDataCallback={refetchPartnersCallback}
                            customMediaColumnName='actionsRow'
                            initialOrderBy='name'
                            initialOrderDirection='asc'
                        />
                    </TableCard>
                </PartnersContentBox>
                <AddPartnerDialog
                    isOpen={isAddPartnerModalOpen}
                    onClose={closePartnerModal}
                    onConfirmSuccessCallback={onAddPartnerConfirmSuccessCallback}
                />
            </PageContent>
        </Page>
    );
};

export default Admin;
