import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import {
    MediaProvider,
    Poster,
    Track,
    Controls,
    MediaPlayerInstance,
    isVideoProvider,
    type MediaProviderAdapter,
    type MediaProviderSetupEvent,
    useMediaStore
} from '@vidstack/react';
import { X } from 'react-feather';
import { DefaultVideoLayout } from '@vidstack/react/player/layouts/default';
import { FC, useEffect, useRef, useState, KeyboardEvent, useMemo } from 'react';
import { createUrl } from '../../../utils/createUrl';
import { makeStyles } from '@material-ui/core';
import { isFullLink } from '../../../utils/isFullLink';
import { StyledBackdrop, StyledControlGroup, StyledMediaPlayer } from './Style';
import vidstackIcons from './VidstackIcons';
import { defaultLayoutIcons } from '@vidstack/react/player/layouts/default';

interface Props {
    videoUrl?: string;
    captionUrl?: string;
    videoImage?: string;
    contentId?: number;
    videoWatchedCallback?: (relatedId?: number) => Promise<void>;
    onVideoOpenChange?: (isExpanded: boolean) => void;
    defaultOpen?: boolean;
    chaptersUrl?: string;
    onCloseVideoCallback?: () => void;
    autoPlay?: boolean;
    disableCloseAction?: boolean;
    muted?: boolean;
    isMandatoryVideoMode?: boolean;
}

const useStyles = makeStyles((theme) => ({
    '@keyframes scaleeffect': {
        '0%': {
            width: 44,
            height: 44,
            top: 0,
            left: 0
        },
        '100%': {
            left: -5,
            top: -5,
            height: 54,
            width: 54
        }
    },
    thumbnail: {
        position: 'relative',
        flex: 1,
        width: 230,
        minHeight: 130,
        display: 'flex',
        // boxShadow: '0px 0.5px 1.5px rgba(0, 0, 0, 0.08), 0px 1px 4px rgba(0, 0, 0, 0.08)',
        backgroundSize: 'cover',
        cursor: 'pointer',
        '& svg': {
            width: '44px',
            height: '44px',
            position: 'absolute',
            cursor: 'pointer'
        },
        '&:hover svg': {
            width: 54,
            height: 54,
            top: -5,
            left: -5,
            animation: `$scaleeffect 200ms ease-in`
        }
    },
    playVideo: {
        margin: 'auto'
    },
    centerContent: {
        display: 'flex',
        justifyContent: 'center'
    },
    buttonAlign: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    modal: {
        position: 'absolute',
        width: 'fit-content',
        height: 'fit-content',
        // top: 'calc(100vh / 2)',
        // left: 'calc(100vw / 2)',
        right: 0,
        top: '-50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0, 0, 0, .50)',
        zIndex: 2000,
        borderRadius: '6px'
    },
    mandatoryVideoModal: {
        position: 'fixed',
        width: 'fit-content',
        height: 'fit-content',
        // top: 'calc(100vh / 2)',
        // left: 'calc(100vw / 2)',
        top: '6%',
        bottom: '6%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0, 0, 0, .50)',
        zIndex: 2000,
        borderRadius: '6px'
    },
    vidContainer: {
        background: 'rgb(255, 255, 255)',
        display: 'flex',
        flexFlow: 'column ',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2000,
        borderRadius: '6px'
    },
    closeButton: {
        background: 'rgb(255, 255, 255)',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 10,
        zIndex: 2000,
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px'
    },
    playIcon: {
        width: '44px',
        height: '44px',
        position: 'absolute',
        cursor: 'pointer'
    }
}));

const initialTimeWatched: ITimeWatched = {
    percentage: 0,
    total: 0
};

interface ITimeWatched {
    total: number;
    percentage: number;
}

const VidstackVideo: FC<Props> = ({
    videoUrl,
    captionUrl,
    videoImage,
    videoWatchedCallback,
    contentId,
    defaultOpen = false,
    onVideoOpenChange,
    chaptersUrl,
    onCloseVideoCallback,
    autoPlay = false,
    disableCloseAction = false,
    muted = false,
    isMandatoryVideoMode = false
}) => {
    const classes = useStyles();
    const [isVideoPlaying, setVideoPlaying] = useState<boolean>(false);
    const player = useRef<MediaPlayerInstance>(null);
    const [timeWatched, setTimeWatched] = useState<ITimeWatched>(initialTimeWatched);
    const isUsageTrackingConditionFullfilled = useRef<boolean>(false);
    const videoElementRef = useRef<HTMLVideoElement | null>(null);
    const { pictureInPicture } = useMediaStore(player);

    useEffect(() => {
        setVideoPlaying(defaultOpen);
    }, [defaultOpen]);

    useEffect(() => {
        if (onVideoOpenChange) onVideoOpenChange(!isVideoPlaying);
        // if (isVideoPlaying && videoElementRef?.current) videoElementRef?.current.play();
    }, [isVideoPlaying]);

    useEffect(() => {
        if (timeWatched?.percentage >= 50) {
            if (isUsageTrackingConditionFullfilled.current === false && videoWatchedCallback) {
                videoWatchedCallback(contentId);
            }
            isUsageTrackingConditionFullfilled.current = true;
        } else {
            isUsageTrackingConditionFullfilled.current = false;
        }
    }, [timeWatched]);

    const onPlayVideoHandler = () => {
        setVideoPlaying(true);
    };

    const onCloseVideoHandler = () => {
        setVideoPlaying(false);
        if (onCloseVideoCallback) onCloseVideoCallback();
        player.current?.pause();
    };

    const getPlayedTime: (video: HTMLVideoElement | undefined | null) => ITimeWatched = (video) => {
        if (!video) return { percentage: 0, total: 0 };
        let totalPlayed = 0;
        const played = video.played;

        for (let i = 0; i < played.length; i++) {
            totalPlayed += played.end(i) - played.start(i);
        }

        return {
            total: totalPlayed,
            percentage: (totalPlayed / video.duration) * 100
        };
    };

    const onProviderSetup = (provider: MediaProviderAdapter, nativeEvent: MediaProviderSetupEvent) => {
        if (isVideoProvider(provider)) {
            videoElementRef.current = provider.video;
        }
    };

    const onKeyPressedHandler = (e: KeyboardEvent<HTMLElement>) => {
        if (e.code === 'Escape' && !isMandatoryVideoMode) {
            if (onCloseVideoCallback) onCloseVideoCallback();
            setVideoPlaying(false);
        }
    };

    const subtitleMemo = useMemo(() => {
        if (captionUrl) {
            let extension = captionUrl.split(/[#?]/)[0].split('.').pop()?.trim();
            if (!extension) extension = 'vtt';
            return (
                <Track
                    src={captionUrl}
                    kind='subtitles'
                    lang='en-US'
                    type={extension === 'vtt' || extension === 'srt' ? extension : 'vtt'}
                    label='English'
                    default
                />
            );
        }
        return <></>;
    }, [captionUrl]);

    const chaptersMemo = useMemo(() => {
        if (chaptersUrl) {
            let extension = chaptersUrl.split(/[#?]/)[0].split('.').pop()?.trim();
            if (!extension) extension = 'vtt';
            return (
                <Track
                    src={chaptersUrl}
                    kind='chapters'
                    lang='en-US'
                    type={extension === 'vtt' || extension === 'srt' ? extension : 'vtt'}
                    default
                />
            );
        }
        return <></>;
    }, [chaptersUrl]);

    const pauseVideoPlayerHandler = () => {
        if (disableCloseAction && !isUsageTrackingConditionFullfilled.current) return;
        setVideoPlaying(false);
        if (onCloseVideoCallback) onCloseVideoCallback();
        player.current?.pause();
    };

    useEffect(() => {
        if (autoPlay && videoElementRef?.current) videoElementRef?.current.play();
    }, [autoPlay]);

    return (
        <>
            <StyledBackdrop open={isVideoPlaying && !pictureInPicture} onClick={pauseVideoPlayerHandler} />
            <div
                className={
                    isVideoPlaying && !pictureInPicture
                        ? isMandatoryVideoMode
                            ? classes.mandatoryVideoModal
                            : classes.modal
                        : 'none'
                }
            >
                {videoUrl && (
                    <StyledMediaPlayer
                        onKeyDown={onKeyPressedHandler}
                        ref={player}
                        aspectRatio={isVideoPlaying ? '16/9' : undefined}
                        src={videoUrl}
                        autoPlay={autoPlay}
                        onPlay={onPlayVideoHandler}
                        style={isVideoPlaying && !pictureInPicture ? undefined : { width: '230px', height: '130px' }}
                        onTimeUpdate={(e: any) => {
                            const timeWatched = getPlayedTime(videoElementRef.current);
                            setTimeWatched(timeWatched);
                        }}
                        onProviderSetup={onProviderSetup}
                        // hideControlsOnMouseLeave={!pictureInPicture && isVideoPlaying}
                        poster={videoImage}
                        posterLoad='eager'
                        isPopupMode={!pictureInPicture && isVideoPlaying}
                        //TODO IF WE WANT MANDATORY VIDEO TO WORK WE SHOULD DEAL WITH THIS MUTED PROP
                        muted={muted}
                    >
                        <MediaProvider>
                            {subtitleMemo}
                            {chaptersMemo}
                            <Poster
                                className='vds-poster'
                                src={
                                    videoImage && isFullLink(videoImage)
                                        ? videoImage
                                        : createUrl(videoImage)?.generatedUrl || ''
                                }
                                alt='Video poster'
                            />
                        </MediaProvider>
                        <Controls.Root className='vds-controls'>
                            {isVideoPlaying &&
                                !pictureInPicture &&
                                (!disableCloseAction ||
                                    (disableCloseAction && isUsageTrackingConditionFullfilled.current)) && (
                                    <StyledControlGroup className='vds-controls-group'>
                                        <X onClick={onCloseVideoHandler} />
                                    </StyledControlGroup>
                                )}
                        </Controls.Root>
                        <DefaultVideoLayout
                            icons={isVideoPlaying && !pictureInPicture ? defaultLayoutIcons : vidstackIcons}
                        />
                    </StyledMediaPlayer>
                )}
            </div>
        </>
    );
};

export default VidstackVideo;
