import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const ContentRootBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '24px'
}));

export const HeaderTitleTypography = styled(Typography)({
    color: 'rgba(0, 0, 0, 0.4)',
    fontSize: '20px',
    textAlign: 'center',
    fontFamily: 'Ubuntu',
    lineHeight: 1.2,
    marginBottom: '16px',
    letterSpacing: '0.25px'
});

export const DescriptionTypography = styled(Typography)({
    width: '100%',
    marginBottom: '21px'
});

export const ContentBox = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
});

export const ActionBox = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end'
});

export const RowActions = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    justifyContent: 'flex-end'
});

export const RowActionBox = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    margin: '4px 8px',
    '&:hover': {
        cursor: 'pointer',
        borderRadius: 24,
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        color: theme.palette.primary.main
    }
}));
