import { FC, useState } from 'react';
import Page from '../../components/page/Page';
import PageContent from '../../components/page-content/PageContent';
import { DownloadIconBox, RootBox } from './Style';
import { IStatementVM } from '../../interfaces/views/IStatementVM';
import { ITableHeadCell, Table, Tooltip, useDownloadReport } from 'nulia-ui';
import { useStatementsStateValue } from '../../contexts/StatementsContext';
import { Download } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { enUS } from 'date-fns/locale';

interface ITableHeader {
    month: string;
    year: string;
    rowActions: any;
}

const Statements: FC = () => {
    const { statementsData, isStatementsDataFetchingError, isStatementsDataLoading, refetchStatementsCallback } =
        useStatementsStateValue();
    const { t } = useTranslation();
    const { downloadReportCallback } = useDownloadReport({
        url: `partner/statements`,
        generateFileNameFromHeader: true
    });
    const [isDownloadReportPending, setDownloadReportPending] = useState<boolean>(false);

    const downloadStatement = async (month: string, year: string) => {
        try {
            setDownloadReportPending(true);
            await downloadReportCallback(`partner/statements/${year}/${month}`);
            setDownloadReportPending(false);
        } catch (err) {
            console.error(err);
        }
    };

    const headCells: ITableHeadCell<ITableHeader>[] = [
        {
            id: 'year',
            label: 'Year'
        },
        {
            id: 'month',
            label: 'Month',
            customRender(value) {
                return enUS.localize.month(value, { width: 'wide' });
            }
        },
        {
            id: 'rowActions',
            label: '',
            disablePadding: true,
            disableSort: true,
            customRender(value, row: IStatementVM) {
                return (
                    <DownloadIconBox
                        isDisabled={isDownloadReportPending}
                        onClick={() => {
                            if (!isDownloadReportPending) downloadStatement(row.month, row.year);
                        }}
                    >
                        <Tooltip title={isDownloadReportPending ? '' : t('partnerTooltips.downloadStatement')}>
                            <Download />
                        </Tooltip>
                    </DownloadIconBox>
                );
            }
        }
    ];

    return (
        <Page>
            <PageContent>
                <RootBox>
                    <Table<IStatementVM, ITableHeader>
                        headCells={headCells}
                        data={statementsData}
                        propertyKeys={headCells.map((headCell) => headCell.id)}
                        customMediaColumnName='rowActions'
                        initialOrderBy='year'
                        initialOrderDirection='desc'
                        isLoading={isStatementsDataLoading}
                        isError={isStatementsDataFetchingError}
                        refetchDataCallback={refetchStatementsCallback}
                    />
                </RootBox>
            </PageContent>
        </Page>
    );
};

export default Statements;
