import BasicStatusCard, { EStatus } from '../../../components/basic-status-card/BasicStatusCard';
import { FC, useCallback, useState } from 'react';
import {
    ContentRootBox,
    DescriptionTypography,
    ContentBox,
    // HeaderTitleTypography,
    RowActions,
    RowActionBox,
    ActionBox
} from './Style';
import {
    ConfirmDialog,
    EToastSeverity,
    IconButton,
    ITableHeadCell,
    Table,
    Tooltip,
    useConfirmModal,
    useModal,
    useToastContextStateValue
} from 'nulia-ui';
import { IAdministratorVM } from '../../../interfaces/views/IAdministratorVM';
import { useTranslation } from 'react-i18next';
import { PlusCircle, UserX } from 'react-feather';
import AddAdministratorDialog from './add-administrator-dialog/AddAdministratorDialog';
import { EAdministratorState } from '../../../interfaces/enums/EAdministratorState';
import { useDeleteCustomerAdministrator } from '../../../services/AccountsQueryService';
interface IProps {
    administratorsList?: IAdministratorVM[];
    customerId?: string;
    isLoading: boolean;
    isError: boolean;
    refetch: () => void;
}

interface ITableHeader {
    name: string;
    email: string;
    state: string;
    rowActions: string;
}

const Administrators: FC<IProps> = ({ administratorsList, customerId, isError, isLoading, refetch }) => {
    const { t } = useTranslation();
    const { isOpen: isAddAdministratorOpen, setOpen: setAddAdministratorOpen } = useModal({ defaultOpen: false });
    const {
        isOpen: isDeleteAdministratorDialogOpen,
        open: openDeleteAdministratorDialog,
        close: closeDeleteAdministratorDialog
    } = useConfirmModal({ defaultOpen: false });
    const { setToastMessage } = useToastContextStateValue();
    const { mutateAsync: deleteCustomerAdministratorApiAsync } = useDeleteCustomerAdministrator();
    const [selectedTableRow, setSelectedTableRow] = useState<IAdministratorVM | undefined>();

    const successAddAdminCallback = useCallback(() => {
        refetch();
    }, []);

    const onOpenDeleteAdministratorModalClick = useCallback((administrator: IAdministratorVM) => {
        openDeleteAdministratorDialog();
    }, []);

    const onOpenAddAdministratorModalClick = useCallback(() => {
        setAddAdministratorOpen(true);
    }, []);

    const toggleAddAdministrator = () => {
        setAddAdministratorOpen(!isAddAdministratorOpen);
    };

    const onConfirmDeleteAdministratorCallback = useCallback(async () => {
        try {
            if (selectedTableRow?.id && customerId) {
                await deleteCustomerAdministratorApiAsync({
                    customerId,
                    administratorId: selectedTableRow?.id
                });
                setToastMessage({
                    isOpen: true,
                    message: t('successToastMessages.accountDetails.administrators.delete'),
                    severity: EToastSeverity.SUCCESS
                });
                refetch();
                closeDeleteAdministratorDialog();
            }
        } catch (err) {
            console.error(err);
            setToastMessage({
                isOpen: true,
                message: t('errorToastMessages.accountDetails.administrators.delete'),
                severity: EToastSeverity.ERROR
            });
            closeDeleteAdministratorDialog();
        }
    }, [selectedTableRow]);

    const onCancelDeleteAdministratorCallback = useCallback(() => {
        closeDeleteAdministratorDialog();
    }, []);

    const headCells: ITableHeadCell<ITableHeader>[] = [
        {
            id: 'name',
            label: 'Name'
        },
        {
            id: 'email',
            label: 'Email'
        },
        {
            id: 'state',
            label: 'Status',
            disablePadding: true,
            customRender(value, row) {
                switch (value) {
                    case EAdministratorState.ACTIVE:
                        return t('accountDetails.administrators.active');
                    case EAdministratorState.ERROR_UNKNOWN:
                        return t('accountDetails.administrators.errorUknown');
                    case EAdministratorState.ERROR_USER_NOT_FOUND:
                        return t('accountDetails.administrators.errorUserNotFound');
                    case EAdministratorState.PENDING:
                        return t('accountDetails.administrators.pending');
                    default:
                        return '';
                }
            }
        },
        {
            id: 'rowActions',
            label: '',
            disablePadding: true,
            disableSort: true,
            customRender(value, row: IAdministratorVM) {
                return (
                    <RowActions>
                        <RowActionBox
                            onClick={(e) => {
                                setSelectedTableRow(row);
                                onOpenDeleteAdministratorModalClick(row);
                            }}
                        >
                            <Tooltip title={t('partnerTooltips.deleteAdministrator')}>
                                <UserX />
                            </Tooltip>
                        </RowActionBox>
                    </RowActions>
                );
            }
        }
    ];

    return (
        <BasicStatusCard title='Administrators' status={EStatus.SUCCESS}>
            <ContentRootBox>
                {/* <HeaderTitleTypography>
                    Connect your Office 365 Tenant to Fully Add Administrators
                </HeaderTitleTypography> */}
                <DescriptionTypography variant='body2'>
                    These people can bulk assign outcomes across the tenant.
                </DescriptionTypography>
                <ContentBox>
                    <ActionBox>
                        <IconButton
                            clickHandler={onOpenAddAdministratorModalClick}
                            text='Administrator'
                            startIcon={<PlusCircle />}
                            variant='outlined'
                        />
                    </ActionBox>
                    <Table<IAdministratorVM, ITableHeader>
                        headCells={headCells}
                        data={administratorsList}
                        propertyKeys={headCells.map((headCell) => headCell.id)}
                        customMediaColumnName='name'
                        customMediaSecondaryColumns={['rowActions']}
                        initialOrderBy='name'
                        isLoading={isLoading}
                        isError={isError}
                        refetchDataCallback={refetch}
                    />
                </ContentBox>
            </ContentRootBox>
            {customerId && (
                <AddAdministratorDialog
                    open={isAddAdministratorOpen}
                    onClose={toggleAddAdministrator}
                    customerId={customerId}
                    successAddAdminCallback={successAddAdminCallback}
                />
            )}
            <ConfirmDialog
                title={t('accountDetails.administrators.confirmDeleteDialog.title')}
                description={t('accountDetails.administrators.confirmDeleteDialog.description')}
                isOpen={isDeleteAdministratorDialogOpen}
                handleConfirm={onConfirmDeleteAdministratorCallback}
                handleClose={onCancelDeleteAdministratorCallback}
            />
        </BasicStatusCard>
    );
};

export default Administrators;
