export function filterOutItemsWithEmptyProp<TypeOfElementsInArray, TypeOfPropertyOfItem>(
    arrayToUpdate: TypeOfElementsInArray[],
    propertyGetter: (arrayItem: TypeOfElementsInArray) => TypeOfPropertyOfItem
): TypeOfElementsInArray[] {
    const updatedArray = arrayToUpdate.reduce<TypeOfElementsInArray[]>((accumulator, currentItem) => {
        const propVal = propertyGetter(currentItem);
        if (propVal) {
            accumulator.push(currentItem);
        }

        return accumulator;
    }, []);

    return updatedArray;
}
