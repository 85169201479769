import { routes } from '../pages/routes';
import { useState, useEffect } from 'react';
import { matchPath, matchRoutes, useLocation } from 'react-router';

interface IUsePageHeader {
    config: IPageHeaderConfig;
    setConfig: (config: IPageHeaderConfig) => void;
}

interface IPageHeaderConfig {
    isSearchVisible: boolean;
    searchPlaceholder?: string;
    pageName: string;
    isLastUpdatedInfoVisible: boolean;
    wizardSteps?: any;
}

const usePageHeaderConfig: (isBPSkill?: boolean) => IUsePageHeader = (isBPSkill) => {
    const [config, setConfig] = useState<IPageHeaderConfig>({
        isSearchVisible: false,
        searchPlaceholder: '',
        pageName: '',
        isLastUpdatedInfoVisible: false
    });
    const location = useLocation();

    useEffect(() => {
        if (matchPath(routes.SKILLS, location.pathname)) {
            setConfig({
                isSearchVisible: true,
                searchPlaceholder: 'Search Skills',
                pageName: 'Skills',
                isLastUpdatedInfoVisible: true
            });
        } else if (matchPath(routes.USER_OUTCOMES, location.pathname)) {
            setConfig({
                isSearchVisible: true,
                searchPlaceholder: 'Search Outcomes',
                pageName: 'Outcomes',
                isLastUpdatedInfoVisible: true
            });
        } else if (matchPath({ path: routes.OUTCOME }, location.pathname)) {
            setConfig({
                isSearchVisible: false,
                pageName: '',
                isLastUpdatedInfoVisible: true
            });
        } else if (matchPath({ path: routes.SKILL }, location.pathname)) {
            setConfig({
                isSearchVisible: false,
                pageName: '',
                isLastUpdatedInfoVisible: true
            });
        } else if (location.pathname.startsWith(routes.ADMIN)) {
            if (location.pathname === routes.ADMIN_HISTORY)
                setConfig({
                    isSearchVisible: false,
                    searchPlaceholder: '',
                    pageName: 'Admin',
                    isLastUpdatedInfoVisible: false
                });
            else if (
                matchPath({ path: routes.ADMIN_LICENSES }, location.pathname) ||
                matchPath({ path: routes.ADMIN_ROLES }, location.pathname) ||
                matchPath({ path: routes.ADMIN_HISTORY_DETAILS }, location.pathname)
            )
                setConfig({
                    isSearchVisible: true,
                    searchPlaceholder: 'Search User',
                    pageName: 'Admin',
                    isLastUpdatedInfoVisible: false
                });
            else {
                setConfig({
                    isSearchVisible: false,
                    searchPlaceholder: '',
                    pageName: 'Admin',
                    isLastUpdatedInfoVisible: false
                });
            }
        } else if (location.pathname.startsWith(routes.HISTORY_OUTCOMES)) {
            setConfig({
                isSearchVisible: false,
                searchPlaceholder: '',
                pageName: 'Outcomes',
                isLastUpdatedInfoVisible: false
            });
        } else if (
            matchRoutes(
                [
                    {
                        path: routes.INSIGHTS_ORGANIZATIONAL_SKILL_DETAILS
                    },
                    {
                        path: routes.INSIGHTS_ORGANIZATIONAL_ASSESSMENT_DETAILS
                    }
                ],
                location.pathname
            )
        ) {
            setConfig({
                isSearchVisible: false,
                searchPlaceholder: '',
                pageName: '',
                isLastUpdatedInfoVisible: false
            });
        } else if (
            matchRoutes(
                [
                    {
                        path: routes.INSIGHTS_ORGANIZATIONAL_USER_BADGES
                    },
                    {
                        path: routes.INSIGHTS_PERSONAL_BADGES
                    }
                ],
                location.pathname
            )
        ) {
            setConfig({
                isSearchVisible: false,
                searchPlaceholder: 'Search Outcomes',
                pageName: 'Insights',
                isLastUpdatedInfoVisible: false
            });
        } else if (
            matchRoutes(
                [
                    {
                        path: routes.INSIGHTS_ORGANIZATIONAL_USER_SKILLS
                    },
                    {
                        path: routes.INSIGHTS_PERSONAL_SKILLS
                    }
                ],
                location.pathname
            )
        ) {
            setConfig({
                isSearchVisible: false,
                searchPlaceholder: 'Search Skills',
                pageName: 'Insights',
                isLastUpdatedInfoVisible: false
            });
        } else if (
            matchRoutes(
                [
                    {
                        path: routes.INSIGHTS_ORGANIZATIONAL_USER_OUTCOMES
                    },
                    {
                        path: routes.INSIGHTS_PERSONAL_OUTCOMES
                    }
                ],
                location.pathname
            )
        ) {
            setConfig({
                isSearchVisible: false,
                searchPlaceholder: 'Search Outcomes',
                pageName: 'Insights',
                isLastUpdatedInfoVisible: false
            });
        } else if (
            matchRoutes(
                [
                    {
                        path: routes.INSIGHTS_ORGANIZATIONAL_USER_ENGAGEMENT
                    },
                    {
                        path: routes.INSIGHTS_PERSONAL_ENGAGEMENT
                    }
                ],
                location.pathname
            )
        ) {
            setConfig({
                isSearchVisible: false,
                searchPlaceholder: '',
                pageName: 'Insights',
                isLastUpdatedInfoVisible: false
            });
        } else if (
            matchRoutes(
                [
                    {
                        path: routes.INSIGHTS_ORGANIZATIONAL_USERS
                    },
                    {
                        path: routes.INSIGHTS_ORGANIZATIONAL_BADGES
                    }
                ],
                location.pathname
            )
        ) {
            setConfig({
                isSearchVisible: false,
                searchPlaceholder: 'Search Users',
                pageName: 'Insights',
                isLastUpdatedInfoVisible: false
            });
        } else if (
            matchRoutes(
                [
                    {
                        path: routes.INSIGHTS_ORGANIZATIONAL_OUTCOMES
                    }
                ],
                location.pathname
            )
        ) {
            setConfig({
                isSearchVisible: false,
                searchPlaceholder: 'Search Outcomes',
                pageName: 'Insights',
                isLastUpdatedInfoVisible: false
            });
        } else if (
            matchRoutes(
                [
                    {
                        path: routes.INSIGHTS_ORGANIZATIONAL_ENGAGEMENT
                    }
                ],
                location.pathname
            )
        ) {
            setConfig({
                isSearchVisible: false,
                searchPlaceholder: '',
                pageName: 'Insights',
                isLastUpdatedInfoVisible: false
            });
        } else if (
            location.pathname.startsWith(routes.INSIGHTS) &&
            !matchRoutes(
                [
                    {
                        path: routes.INSIGHTS_ORGANIZATIONAL_OUTCOMES_DETAILS
                    }
                ],
                location.pathname
            )
        ) {
            setConfig({
                isSearchVisible: false,
                searchPlaceholder: '',
                pageName: 'Insights',
                isLastUpdatedInfoVisible: false
            });
        } else if (
            matchRoutes(
                [
                    {
                        path: routes.INSIGHTS_ORGANIZATIONAL_OUTCOMES_DETAILS
                    }
                ],
                location.pathname
            )
        ) {
            setConfig({
                isSearchVisible: false,
                searchPlaceholder: 'Search Users',
                pageName: '',
                isLastUpdatedInfoVisible: false
            });
        } else if (
            location.pathname.startsWith(routes.INSIGHTS_ORGANIZATIONAL) &&
            !matchRoutes(
                [
                    {
                        path: routes.INSIGHTS_ORGANIZATIONAL_OUTCOMES_DETAILS
                    }
                ],
                location.pathname
            )
        ) {
            setConfig({
                isSearchVisible: false,
                searchPlaceholder: '',
                pageName: 'Insights',
                isLastUpdatedInfoVisible: false
            });
        } else if (location.pathname.startsWith(routes.INSIGHTS_PERSONAL)) {
            setConfig({
                isSearchVisible: false,
                searchPlaceholder: '',
                pageName: 'Insights',
                isLastUpdatedInfoVisible: false
            });
        } else if (location.pathname.startsWith(routes.SETTINGS)) {
            setConfig({
                isSearchVisible: false,
                searchPlaceholder: '',
                pageName: 'Settings',
                isLastUpdatedInfoVisible: false
            });
        } else if (matchPath(routes.HOME, location.pathname) || matchPath('/', location.pathname)) {
            setConfig({
                isSearchVisible: false,
                searchPlaceholder: '',
                pageName: 'Home',
                isLastUpdatedInfoVisible: true
            });
        } else if (matchPath(routes.HOW_TOS_HOMEPAGE, location.pathname)) {
            setConfig({
                isSearchVisible: false,
                searchPlaceholder: '',
                pageName: "How To's",
                isLastUpdatedInfoVisible: false
            });
        } else if (matchPath(routes.SHOWCASE_HOME, location.pathname)) {
            setConfig({
                isSearchVisible: false,
                searchPlaceholder: '',
                pageName: 'Showcase',
                isLastUpdatedInfoVisible: false
            });
        } else if (matchPath(routes.PARTNER_ACCOUNTS, location.pathname)) {
            setConfig({
                isSearchVisible: false,
                searchPlaceholder: '',
                pageName: 'Partner',
                isLastUpdatedInfoVisible: false
            });
        } else if (matchPath(routes.PARTNER_PARTNERS, location.pathname)) {
            setConfig({
                isSearchVisible: false,
                searchPlaceholder: '',
                pageName: 'Partner',
                isLastUpdatedInfoVisible: false
            });
        } else if (matchPath(routes.PARTNER_ACCOUNT_DETAILS, location.pathname)) {
            setConfig({
                isSearchVisible: false,
                searchPlaceholder: '',
                pageName: 'Partner',
                isLastUpdatedInfoVisible: false
            });
        } else if (matchPath(routes.PARTNER_RESOURCES, location.pathname)) {
            setConfig({
                isSearchVisible: false,
                searchPlaceholder: '',
                pageName: 'Partner',
                isLastUpdatedInfoVisible: false
            });
        } else if (matchPath(routes.PARTNER_CONTACT, location.pathname)) {
            setConfig({
                isSearchVisible: false,
                searchPlaceholder: '',
                pageName: 'Partner',
                isLastUpdatedInfoVisible: false
            });
        } else if (matchPath(routes.PARTNER_STATEMENTS, location.pathname)) {
            setConfig({
                isSearchVisible: false,
                searchPlaceholder: '',
                pageName: 'Partner',
                isLastUpdatedInfoVisible: false
            });
        } else if (matchPath(routes.PARTNER_USER, location.pathname)) {
            setConfig({
                isSearchVisible: false,
                searchPlaceholder: '',
                pageName: 'Partner',
                isLastUpdatedInfoVisible: false
            });
        } else if (
            !matchRoutes(
                [
                    {
                        path: routes.INSIGHTS_ORGANIZATIONAL_OUTCOMES_DETAILS
                    }
                ],
                location.pathname
            )
        ) {
            setConfig({
                isSearchVisible: false,
                searchPlaceholder: '',
                pageName: '',
                isLastUpdatedInfoVisible: false
            });
        }
    }, [location]);

    return {
        config,
        setConfig
    };
};

export default usePageHeaderConfig;
