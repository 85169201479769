import { FC, useEffect } from 'react';
import { Outlet } from 'react-router';
import { ERoute, isUserAllowedToAccess, ITab, routes, useTabsStateValue, useUserAuthStateValue } from 'nulia-ui';

const PartnerTabs: FC = () => {
    const { currentUserData } = useUserAuthStateValue();
    const { setTabs } = useTabsStateValue();

    useEffect(() => {
        if (!currentUserData) {
            setTabs([]);
            return;
        }
        const tabs: ITab[] = [
            {
                id: 1,
                title: 'Accounts',
                fullTitle: 'Accounts',
                path: routes.PARTNER_ACCOUNTS_TAB,
                isUserAllowedToAccess: isUserAllowedToAccess(
                    ERoute.PARTNER_ACCOUNTS_TAB,
                    currentUserData?.roles,
                    currentUserData.permissions
                ),
                children: [
                    {
                        id: 6,
                        title: 'Partner',
                        fullTitle: 'Partner',
                        path: routes.PARTNER_ACCOUNTS,
                        isUserAllowedToAccess: isUserAllowedToAccess(
                            ERoute.PARTNER_ACCOUNTS,
                            currentUserData?.roles,
                            currentUserData.permissions
                        )
                    },
                    {
                        id: 7,
                        title: 'Customer Details',
                        fullTitle: 'Customer Details',
                        path: routes.PARTNER_ACCOUNT_DETAILS,
                        isUserAllowedToAccess: isUserAllowedToAccess(
                            ERoute.PARTNER_ACCOUNT_DETAILS,
                            currentUserData?.roles,
                            currentUserData.permissions
                        )
                    }
                ]
            },
            {
                id: 3,
                title: 'Statements',
                fullTitle: 'Statements',
                path: routes.PARTNER_STATEMENTS,
                isUserAllowedToAccess: isUserAllowedToAccess(
                    ERoute.PARTNER_STATEMENTS,
                    currentUserData?.roles,
                    currentUserData.permissions
                )
            },
            {
                id: 4,
                title: 'Resources',
                fullTitle: 'Resources',
                path: routes.PARTNER_RESOURCES,
                isUserAllowedToAccess: isUserAllowedToAccess(
                    ERoute.PARTNER_RESOURCES,
                    currentUserData?.roles,
                    currentUserData.permissions
                )
            },
            {
                id: 5,
                title: 'Contact',
                fullTitle: 'Contact',
                path: routes.PARTNER_CONTACT,
                isUserAllowedToAccess: isUserAllowedToAccess(
                    ERoute.PARTNER_CONTACT,
                    currentUserData?.roles,
                    currentUserData.permissions
                )
            },
            {
                id: 2,
                title: 'Admin',
                fullTitle: 'Admin',
                path: routes.PARTNER_PARTNERS,
                isUserAllowedToAccess: isUserAllowedToAccess(
                    ERoute.PARTNER_PARTNERS,
                    currentUserData?.roles,
                    currentUserData.permissions
                ),
                children: [
                    {
                        id: 6,
                        title: 'Partner',
                        fullTitle: 'Partner',
                        path: routes.PARTNER_PARTNERS,
                        isUserAllowedToAccess: isUserAllowedToAccess(
                            ERoute.PARTNER_PARTNERS,
                            currentUserData?.roles,
                            currentUserData.permissions
                        )
                    },
                    {
                        id: 6,
                        title: 'Partner User',
                        fullTitle: 'Partner User',
                        path: routes.PARTNER_USER,
                        isUserAllowedToAccess: isUserAllowedToAccess(
                            ERoute.PARTNER_USER,
                            currentUserData?.roles,
                            currentUserData.permissions
                        )
                    }
                ]
            }
        ];
        setTabs(tabs.filter((tab) => tab.isUserAllowedToAccess));
        return () => {
            setTabs([]);
        };
    }, [currentUserData]);

    return <Outlet />;
};

export default PartnerTabs;
