import React, { CSSProperties, FC } from 'react';
import { StyledButton } from './Style';

interface IProps {
    clickHandler?: (() => void) | ((e: React.MouseEvent) => void);
    style?: CSSProperties;
    variant?: 'text' | 'outlined' | 'contained';
    startIcon?: React.ReactElement;
    endIcon?: React.ReactElement;
    text: string;
}

const IconButton: FC<IProps> = ({ clickHandler, style, variant = 'outlined', startIcon, endIcon, text }) => {
    return (
        <StyledButton onClick={clickHandler} style={style} variant={variant} endIcon={endIcon} startIcon={startIcon}>
            {text}
        </StyledButton>
    );
};

export default IconButton;
