export enum ERoute {
    HOME = 'Home',
    SKILLS = 'Skills',
    SKILL = 'Skill',
    OUTCOME = 'Outcome',
    USER_OUTCOMES = 'UserOutcomes',
    ADMIN_OUTCOMES = 'AdminOutcomes',
    SETTINGS_PERSONAL = 'SettingsPersonal',
    SETTINGS_ORGANIZATIONAL = 'SettingsOrganizational',
    ADMIN_LICENSES = 'AdminLicenses',
    ADMIN_ROLES = 'AdminRoles',
    ADMIN_HISTORY = 'AdminHistory',
    ADMIN_HISTORY_DETAILS = 'AdminHistoryDetails',
    TABS_OUTCOMES = 'OutcomesTabs',
    FEEDBACK = 'Feedback',
    INSIGHTS_PERSONAL = 'InsightsPersonal',
    INSIGHTS_ORGANIZATION = 'InsightsOrganization',
    INSIGHTS_PERSONAL_SKILLS = 'InsightsPersonalSkills',
    INSIGHTS_PERSONAL_OUTCOMES = 'InsightsPersonalOutcomes',
    INSIGHTS_PERSONAL_ENGAGEMENT = 'InsightsPersonalEngagement',
    INSIGHTS_PERSONAL_BADGES = 'InsightsPersonalBadges',
    INSIGHTS_ORGANIZATION_USERS = 'InsightsOrganizationUsers',
    INSIGHTS_ORGANIZATION_OUTCOMES = 'InsightsOrganizationOutcomes',
    INSIGHTS_ORGANIZATIONAL_OUTCOMES_DETAILS = 'InsightsOrganizationOutcomesDetails',
    INSIGHTS_ORGANIZATION_ENGAGEMENT = 'InsightsOrganizationEngagement',
    INSIGHTS_ORGANIZATION_BADGES = 'InsightsOrganizationBadges',
    INSIGHTS_ORGANIZATION_SINGLE_BADGE = 'InsightsOrganizationSingleBadge',
    INSIGHTS_ORGANIZATION_ASSESSMENT = 'InsightsOrganizationAssessment',
    INSIGHTS_ORGANIZATION_ASSESSMENT_DETAILS = 'InsightsOrganizationAssessmentDetails',
    INSIGHTS_ORGANIZATION_USER_SKILLS = 'InsightsOrganizationUserSkills',
    INSIGHTS_ORGANIZATION_USER_OUTCOMES = 'InsightsOrganizationUserOutcomes',
    INSIGHTS_ORGANIZATION_USER_ENGAGEMENT = 'InsightsOrganizationUserEngagement',
    INSIGHTS_ORGANIZATION_USER_BADGES = 'InsightsOrganizationUserBadges',
    LINKEDIN = 'Linkedin',
    INSTRUCTIONS = 'Instructions',
    OUTCOMES_HISTORY = 'OutcomesHistory',
    HOW_TOS_HOMEPAGE = 'HowTosHomepage',
    HOW_TO = 'HowTo',
    HOW_TO_PLAYLIST = 'HowToPlaylist',
    SOMETHING_WENT_WRONG = 'SomethingWentWrong',
    ADMIN = 'Admin',
    INSIGHTS = 'Insights',
    OUTCOMES = 'Outcomes',
    SETTINGS = 'Settings',
    SHOWCASE_HOME = 'ShowcaseHome',
    ACHIEVEMENTS_PREVIEW = 'AchievementsPreview',
    ACHIEVEMENTS_DETAIL = 'AchievementsDetail',
    BADGES_ALL_BY_CATEGORY = 'BadgesAllByCategory',

    PARTNER_ACCOUNTS = 'PartnerAccounts',
    PARTNER_ACCOUNTS_TAB = 'PartnerAccountsTab',
    PARTNER_PARTNERS = 'PartnerPartners',
    PARTNER_RESOURCES = 'PartnerResources',
    PARTNER_CONTACT = 'PartnerContact',
    PARTNER_ACCOUNT_DETAILS = 'PartnerAccountDetails',
    PARTNER_STATEMENTS = 'PartnerStatements',
    PARTNER_USER = 'PartnerUser'
}
