import { FC, useRef } from 'react';
import Dialog from '../dialog/Dialog';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { StyledForm } from './Style';
import { FormControl } from '@mui/material';
import { EToastSeverity, NTextInput, useToastContextStateValue } from 'nulia-ui';
import { usePutUpdateFolderName } from '../../../services/FolderQueryService';
import { useGetPartnerResellersQuery } from '../../../services/AccountsQueryService';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    initialResellerName: string;
    resellerId: string;
}

const RenameResellerDialog: FC<Props> = ({ isOpen, onClose, initialResellerName, resellerId }) => {
    const { t } = useTranslation();
    const {
        reset,
        formState: { isValid, errors, isDirty },
        control,
        handleSubmit,
        getValues
    } = useForm({
        defaultValues: {
            resellerName: initialResellerName
        }
    });
    const formRef = useRef<HTMLFormElement>(null);
    const { setToastMessage } = useToastContextStateValue();
    const { mutateAsync: mutateUpdateFolderNameAsync, isPending: isUpdateFolderNamePending } = usePutUpdateFolderName();
    const { refetch: refetchPartnerResellers } = useGetPartnerResellersQuery();

    const handleAccept = async () => {
        handleSubmitForm();
    };

    const handleClose = () => {
        resetForm();
        onClose();
    };

    const handleSubmitForm = async () => {
        try {
            const formValues = getValues();
            await mutateUpdateFolderNameAsync({
                name: formValues.resellerName,
                folderId: resellerId
            });
            setToastMessage({
                isOpen: true,
                message: t('successToastMessages.resellers.rename'),
                severity: EToastSeverity.INFO
            });
            refetchPartnerResellers();
            onClose();
        } catch (err) {
            console.error(err);
            onClose();
        }
    };

    const resetForm = () => {
        reset();
    };

    return (
        <Dialog
            open={isOpen}
            onAccept={handleAccept}
            acceptText={t('resellers.renameDialog.confirmButton')}
            onClose={handleClose}
            cancelText={t('resellers.renameDialog.cancelButton')}
            title={t('resellers.renameDialog.title')}
            isSubmitActionDisabled={!isValid || isUpdateFolderNamePending || !isDirty}
            minHeight='200px'
            content={
                <div>
                    <StyledForm ref={formRef} onSubmit={handleSubmit(handleSubmitForm)}>
                        <FormControl fullWidth>
                            <Controller
                                name={'resellerName'}
                                control={control}
                                rules={{
                                    required: true,
                                    maxLength: {
                                        message: 'Maximum length is 100',
                                        value: 100
                                    }
                                }}
                                render={({ field }) => (
                                    <NTextInput
                                        field={field}
                                        label={t('resellers.renameDialog.resellerName')}
                                        value={field.value}
                                        onChange={field.onChange}
                                        errorMessage={errors['resellerName']?.message as string}
                                    />
                                )}
                            />
                        </FormControl>
                    </StyledForm>
                </div>
            }
        />
    );
};

export default RenameResellerDialog;
