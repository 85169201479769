import BasicStatusCard from '../../../components/basic-status-card/BasicStatusCard';
import { FC, useEffect, useRef } from 'react';
import { ContentRootBox, SettingsGroupBox, SubmitButtonBox, TitleTypography } from './Style';
import { ICustomerSettingsVM } from '../../../interfaces/views/ICustomerSettingsVM';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EToastSeverity, NCheckbox, PrimaryButton, useToastContextStateValue } from 'nulia-ui';
import { usePostCustomerSettings } from '../../../services/AccountsQueryService';
import { useParams } from 'react-router-dom';

interface IProps {
    settings?: ICustomerSettingsVM;
    isLoading: boolean;
    isError: boolean;
    refetch: () => void;
}

const Settings: FC<IProps> = ({ settings, isLoading, isError, refetch }) => {
    const {
        control,
        setValue,
        handleSubmit,
        formState: { isDirty },
        reset
    } = useForm();
    const formRef = useRef<HTMLFormElement>(null);
    const { t } = useTranslation();
    const { mutateAsync: postCustomerSettingsApi, isPending: isCustomerSettingsApiPending } = usePostCustomerSettings();
    const params = useParams<{ id?: string }>();
    const { setToastMessage } = useToastContextStateValue();

    useEffect(() => {
        if (settings) {
            reset();
            Object.entries(settings).forEach(([key, value]) => {
                setValue(key, value);
            });
        }
    }, [settings]);

    const handleUpdateSettings = async (data: any) => {
        try {
            if (params.id) {
                const submitData = data as ICustomerSettingsVM;
                await postCustomerSettingsApi({
                    customerId: params.id,
                    customerSettings: submitData
                });
                refetch();
                setToastMessage({
                    isOpen: true,
                    message: t('successToastMessages.settings.updateSettings'),
                    severity: EToastSeverity.SUCCESS
                });
            }
        } catch (err) {
            console.error(err);
            setToastMessage({
                isOpen: true,
                message: t('errorToastMessages.settings.updateSettings'),
                severity: EToastSeverity.ERROR
            });
        }
    };

    return (
        <BasicStatusCard title='Settings' isLoading={isLoading} isError={isError} refetch={refetch}>
            <form style={{ width: '100%' }} ref={formRef} onSubmit={handleSubmit(handleUpdateSettings)}>
                <SubmitButtonBox>
                    <PrimaryButton
                        disabled={!isDirty || isCustomerSettingsApiPending}
                        title='Submit'
                        clickHandler={() => {
                            formRef?.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
                        }}
                    />
                </SubmitButtonBox>
                <ContentRootBox>
                    <SettingsGroupBox>
                        <TitleTypography variant='overline'>Email Notification Preference</TitleTypography>
                        <Controller
                            name={'enableAllEmails'}
                            control={control}
                            render={({ field }) => (
                                <NCheckbox
                                    isChecked={field.value}
                                    label={t('customerSettings.enableAllEmails')}
                                    field={field}
                                    setValue={setValue}
                                />
                            )}
                        />
                        <Controller
                            name={'enableWeeklyEmails'}
                            control={control}
                            render={({ field }) => (
                                <NCheckbox
                                    isChecked={field.value}
                                    label={t('customerSettings.enableWeeklyEmails')}
                                    field={field}
                                    setValue={setValue}
                                />
                            )}
                        />
                        <Controller
                            name={'enableOtherEmails'}
                            control={control}
                            render={({ field }) => (
                                <NCheckbox
                                    isChecked={field.value}
                                    label={t('customerSettings.enableOtherEmails')}
                                    field={field}
                                    setValue={setValue}
                                />
                            )}
                        />
                        <Controller
                            name={'enableOnboardingEmails'}
                            control={control}
                            render={({ field }) => (
                                <NCheckbox
                                    isChecked={field.value}
                                    label={t('customerSettings.enableOnboardingEmails')}
                                    field={field}
                                    setValue={setValue}
                                />
                            )}
                        />
                    </SettingsGroupBox>
                    <SettingsGroupBox>
                        <TitleTypography variant='overline'>Manager Settings</TitleTypography>
                        <Controller
                            name={'enableManagerCanSeeTeamMembersInsights'}
                            control={control}
                            render={({ field }) => (
                                <NCheckbox
                                    isChecked={field.value}
                                    label={t('customerSettings.enableManagerCanSeeTeamMembersInsights')}
                                    field={field}
                                    setValue={setValue}
                                />
                            )}
                        />
                        <Controller
                            name={'enableManagerCanAssignOutcomesToTeamMembers'}
                            control={control}
                            render={({ field }) => (
                                <NCheckbox
                                    isChecked={field.value}
                                    label={t('customerSettings.enableManagerCanAssignOutcomesToTeamMembers')}
                                    field={field}
                                    setValue={setValue}
                                />
                            )}
                        />
                    </SettingsGroupBox>
                    <SettingsGroupBox>
                        <TitleTypography variant='overline'>Teams Notification Preference</TitleTypography>
                        <Controller
                            name={'enableOutcomeAssignedNotification'}
                            control={control}
                            render={({ field }) => (
                                <NCheckbox
                                    isChecked={field.value}
                                    label={t('customerSettings.enableOutcomeAssignedNotification')}
                                    field={field}
                                    setValue={setValue}
                                />
                            )}
                        />
                        <Controller
                            name={'enableBadgeEligibilityNotification'}
                            control={control}
                            render={({ field }) => (
                                <NCheckbox
                                    isChecked={field.value}
                                    label={t('customerSettings.enableBadgeEligibilityNotification')}
                                    field={field}
                                    setValue={setValue}
                                />
                            )}
                        />
                        <Controller
                            name={'enableWeeklyUpdateAvailableNotification'}
                            control={control}
                            render={({ field }) => (
                                <NCheckbox
                                    isChecked={field.value}
                                    label={t('customerSettings.enableWeeklyUpdateAvailableNotification')}
                                    field={field}
                                    setValue={setValue}
                                />
                            )}
                        />
                    </SettingsGroupBox>
                    <SettingsGroupBox>
                        <TitleTypography variant='overline'>Leaderboard Settings</TitleTypography>
                        <Controller
                            name={'enableLeaderboard'}
                            control={control}
                            render={({ field }) => (
                                <NCheckbox
                                    isChecked={field.value}
                                    label={t('customerSettings.enableLeaderboard')}
                                    field={field}
                                    setValue={setValue}
                                />
                            )}
                        />
                    </SettingsGroupBox>
                </ContentRootBox>
            </form>
        </BasicStatusCard>
    );
};

export default Settings;
