import { FC, useEffect, useRef } from 'react';
import Dialog from '../dialog/Dialog';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { StyledForm, StyledSelect } from './Style';
import { FormControl, FormHelperText, MenuItem } from '@mui/material';
import { useAccountsStateValue } from '../../../contexts/AccountsContext';
import { EToastSeverity, NTextInput, Tooltip, useToastContextStateValue } from 'nulia-ui';
import { ERegion } from '../../../interfaces/enums/ERegion';
import { usePostUpdateCustomer } from '../../../services/AccountsQueryService';

interface Props {
    open: boolean;
    onClose: () => void;
    initialReseller: string;
    initialRegion: string;
    initialCustomerName: string;
    isRegionFieldChangeDisabled: boolean;
    customerId: string;
    onSuccessCallback?: () => void;
}

const EditCustomerDialog: FC<Props> = ({
    open,
    onClose,
    initialRegion,
    initialCustomerName,
    initialReseller,
    isRegionFieldChangeDisabled,
    customerId,
    onSuccessCallback
}) => {
    const { t } = useTranslation();
    const {
        reset,
        formState: { isValid, errors, isDirty },
        control,
        handleSubmit,
        setValue,
        getValues
    } = useForm({
        mode: 'onChange'
    });
    const formRef = useRef<HTMLFormElement>(null);
    const { resellersData } = useAccountsStateValue();
    const { setToastMessage } = useToastContextStateValue();
    const { mutateAsync: mutateUpdateCustomerAsync, isPending: isUpdateCustomerApiCallPending } =
        usePostUpdateCustomer();

    useEffect(() => {
        if (initialRegion) setValue('region', initialRegion);
    }, [initialRegion]);

    useEffect(() => {
        if (initialReseller) setValue('reseller', initialReseller);
        else setValue('reseller', 'None');
    }, [initialReseller]);

    useEffect(() => {
        if (initialCustomerName) setValue('customerName', initialCustomerName);
    }, [initialCustomerName]);

    const handleAccept = async () => {
        handleSubmitForm();
    };

    const handleClose = () => {
        resetForm();
        onClose();
    };

    const handleSubmitForm = async () => {
        const formValues = getValues();
        try {
            await mutateUpdateCustomerAsync({
                customerId: customerId,
                updateRequestDTO: {
                    folderId: formValues.reseller === 'None' ? undefined : formValues.reseller,
                    name: formValues.customerName,
                    region: formValues.region || initialRegion
                }
            });
            setToastMessage({
                isOpen: true,
                message: t('successToastMessages.customers.edit'),
                severity: EToastSeverity.INFO
            });
            if (onSuccessCallback) onSuccessCallback();
            resetForm();
            onClose();
        } catch (err) {
            console.error(err);
            setToastMessage({
                isOpen: true,
                message: t('errorToastMessages.customers.edit'),
                severity: EToastSeverity.ERROR
            });
            resetForm();
            onClose();
        }
    };

    const resetForm = () => {
        reset();
    };

    return (
        <Dialog
            open={open}
            onAccept={handleAccept}
            acceptText={t('accounts.editCustomer.confirmButton')}
            onClose={handleClose}
            cancelText={t('accounts.editCustomer.cancelButton')}
            title={t('accounts.editCustomer.title')}
            isSubmitActionDisabled={!isValid || isUpdateCustomerApiCallPending || !isDirty}
            minHeight='200px'
            content={
                <div>
                    <StyledForm ref={formRef} onSubmit={handleSubmit(handleSubmitForm)}>
                        <FormControl fullWidth>
                            <Controller
                                name={'customerName'}
                                control={control}
                                rules={{
                                    required: true,
                                    maxLength: {
                                        message: 'Maximum length is 50',
                                        value: 50
                                    }
                                }}
                                render={({ field }) => (
                                    <NTextInput
                                        field={field}
                                        label={t('accounts.editCustomer.customerNameInput')}
                                        value={field.value}
                                        onChange={field.onChange}
                                        errorMessage={errors['customerName']?.message as string}
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <FormHelperText style={{ marginLeft: 0 }}>
                                {t('accounts.editCustomer.resellerInput')}
                            </FormHelperText>
                            <Controller
                                name={'reseller'}
                                control={control}
                                render={({ field }) => (
                                    <StyledSelect id='reseller-select' value={field.value} onChange={field.onChange}>
                                        <MenuItem key='none' value='None'>
                                            None
                                        </MenuItem>
                                        {resellersData?.map((reseller) => {
                                            return (
                                                <MenuItem key={reseller.id} value={reseller.id}>
                                                    {reseller.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </StyledSelect>
                                )}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <FormHelperText style={{ marginLeft: 0 }}>
                                {t('accounts.editCustomer.regionInput')}
                            </FormHelperText>
                            <Controller
                                name={'region'}
                                control={control}
                                disabled={isRegionFieldChangeDisabled}
                                rules={{
                                    required: true
                                }}
                                render={({ field }) => (
                                    <Tooltip
                                        title={
                                            isRegionFieldChangeDisabled ? t('partnerTooltips.regionFieldDisabled') : ''
                                        }
                                    >
                                        <StyledSelect
                                            disabled={isRegionFieldChangeDisabled}
                                            id='region-select'
                                            value={isRegionFieldChangeDisabled ? initialRegion : field.value}
                                            onChange={field.onChange}
                                        >
                                            <MenuItem key='0' value={ERegion.US}>
                                                US
                                            </MenuItem>
                                            <MenuItem key='1' value={ERegion.EUROPE}>
                                                Europe
                                            </MenuItem>
                                            <MenuItem key='2' value={ERegion.UK}>
                                                UK
                                            </MenuItem>
                                            <MenuItem key='3' value={ERegion.AUSTRALIA}>
                                                Australia
                                            </MenuItem>
                                        </StyledSelect>
                                    </Tooltip>
                                )}
                            />
                        </FormControl>
                    </StyledForm>
                </div>
            }
        />
    );
};

export default EditCustomerDialog;
