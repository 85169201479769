import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const FilterBox = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
    maxWidth: '330px',
    '& .MuiSelect-outlined.MuiInputBase-input': {
        background: 'linear-gradient(0deg, #F3F1F8 0%, #F3F1F8 100%) !important'
    }
}));

export const FilterTypography = styled(Typography)(({ theme }) => ({
    color: 'rgba(0,0,0,0.64)',
    marginLeft: 24,
    flex: 1,
    minWidth: '100px',
    height: '100%',
    display: 'flex',
    alignItems: 'center'
}));

export const AddRemoveLicenceIconBox = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    flex: 2,
    padding: '8px',
    margin: '4px 8px',
    '&:hover': {
        background: 'rgba(0, 0, 0, 0.04)',
        cursor: 'pointer',
        borderRadius: '24px',
        color: theme.palette.primary.main
    }
}));

export const CircleSpan = styled('span', {
    shouldForwardProp: (prop) => prop !== 'color'
})<{ color?: string }>(({ color, theme }) => ({
    backgroundColor: color,
    width: '12px',
    height: '12px',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '16px',
    border: '2px solid rgba(0, 0, 0, 0.08)',
    [theme.breakpoints.down('lg')]: {
        marginLeft: 0
    }
}));

export const HeaderBox = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 20px'
});

export const HeaderLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Ubuntu',
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '1.25px',
    color: theme.palette.primary.main
}));
