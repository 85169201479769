import { FC, useEffect, useRef } from 'react';
import { Navigate, matchPath, useLocation, Outlet } from 'react-router';
import { findDefaultRouteByPermissions } from '../utils/findDefaultRouteByPermissions';
import { routes } from './routes';
import { ERoute } from '../interfaces/enums/ERoute';
import { useUserAuthStateValue } from '../contexts/UserAuthContext';
import { isUserAllowedToAccess, routeRules } from './routeRules';
import { useCrumbsStateValue } from '../contexts/CrumbsContext';
import { useApplicationStateValue } from '../contexts/ApplicationContext';

interface IProps {
    redirectPath?: string;
    children: any;
    routeKey: ERoute;
}

const TabsDefaultRoutes = [
    routes.OUTCOMES,
    routes.SETTINGS,
    routes.ADMIN,
    routes.INSIGHTS,
    routes.INSIGHTS_PERSONAL,
    routes.INSIGHTS_ORGANIZATIONAL
];
const TabsDefaultRouteKeys = [
    ERoute.OUTCOMES,
    ERoute.SETTINGS,
    ERoute.ADMIN,
    ERoute.INSIGHTS,
    ERoute.INSIGHTS_PERSONAL,
    ERoute.INSIGHTS_ORGANIZATION
];

const ProtectedRoute: FC<IProps> = ({ redirectPath = routes.HOME, children, routeKey }) => {
    const { currentUserData } = useUserAuthStateValue();
    const isAllowed =
        currentUserData && isUserAllowedToAccess(routeKey, currentUserData?.roles, currentUserData?.permissions);
    const { removeLatestCrumb, crumbs } = useCrumbsStateValue();
    const isUserAllowedToHome =
        currentUserData && isUserAllowedToAccess(ERoute.HOME, currentUserData?.roles, currentUserData?.permissions);
    // const navigate = useNavigate();
    const routeRef = useRef<string>('');
    const location = useLocation();
    const { isTeamsEnv } = useApplicationStateValue();

    useEffect(() => {
        window.onpopstate = () => {
            removeLatestCrumb();
        };
        return () => {
            window.onpopstate = null;
        };
    }, []);

    const findDefaultRoute = () => {
        const defaultRoute: ERoute | undefined = Object.values(ERoute).find((value: string) => {
            if (currentUserData) {
                return isUserAllowedToAccess(value as ERoute, currentUserData?.roles, currentUserData?.permissions);
            }
            return false;
        });
        return defaultRoute ? routeRules[defaultRoute].route : routes.SOMETHING_WENT_WRONG;
    };

    useEffect(() => {
        if (!isTeamsEnv) sessionStorage.setItem('crumbs', JSON.stringify(crumbs));
    }, [crumbs]);

    if (!isAllowed || TabsDefaultRouteKeys.includes(routeKey)) {
        if (!TabsDefaultRouteKeys.includes(routeKey)) {
            return <Navigate replace to={routes.PAGE_FORBIDDEN} />;
        }

        if (currentUserData && TabsDefaultRouteKeys.includes(routeKey)) {
            const defaultRoute = findDefaultRouteByPermissions(routeKey, currentUserData);

            if (
                defaultRoute &&
                ((!location.state && !TabsDefaultRoutes.find((tab) => matchPath(tab, location.pathname))) ||
                    ((!location.state || !TabsDefaultRouteKeys.includes(location.state['from'])) &&
                        TabsDefaultRoutes.find((tab) => matchPath(tab, location.pathname))))
            ) {
                routeRef.current = routeKey;
                if (!TabsDefaultRoutes.find((tab) => matchPath(tab, location.pathname))) {
                    return (
                        <Navigate
                            to={`${location.pathname}${location.search || ''}`}
                            replace
                            state={{ from: routeKey }}
                        />
                    );
                }
                return <Navigate to={defaultRoute} replace state={{ from: routeKey }} />;
            } else {
                if (!defaultRoute && TabsDefaultRoutes.find((tab) => matchPath(tab, location.pathname)))
                    return <Navigate to={routes.PAGE_FORBIDDEN} replace state={{ from: routeKey }} />;
                return children ? <>{children}</> : <Outlet />;
            }
        }

        return <Navigate to={isUserAllowedToHome ? redirectPath : findDefaultRoute()} replace />;
    }

    return children ? <>{children}</> : <Outlet />;
};

export default ProtectedRoute;
