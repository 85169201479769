import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '12px',
    boxShadow: '0px 2.5px 8px rgb(0 0 0 / 8%), 0px 1px 4px rgb(0 0 0 / 8%)',
    flex: 1,
    backgroundColor: theme.palette.common.white,
    padding: '24px',
    height: 'fit-content',
    borderRadius: theme.borderRadius?.outside?.small
}));

export const TitleBox = styled('div')({
    display: 'flex',
    gap: '6px',
    alignItems: 'center'
});

export const TitleTypography = styled(Typography)({
    textTransform: 'uppercase'
});

export const Dot = styled('span', {
    shouldForwardProp: (prop) => prop !== 'color'
})<{ color: string }>(({ color }) => ({
    width: '13px',
    height: '13px',
    borderRadius: '7px',
    backgroundColor: color,
    display: 'inline-block'
}));
