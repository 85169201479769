import BasicStatusCard from '../../basic-status-card/BasicStatusCard';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ContentRootBox, MenuItemTitleSpan, StyledMenuItem, StyledSelect, TableAdditionalHeader } from './Style';
import { formatDate, ITableHeadCell, Loading, SomethingWentWrongCard, Table } from 'nulia-ui';
import { IActivityVM } from '../../../interfaces/views/IActivityVM';
import { Input, SelectChangeEvent } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { ChevronDown } from 'react-feather';

interface IProps {
    data?: IActivityVM[];
    isCustomerNameColumnPresent?: boolean;
    isLoading?: boolean;
    isError?: boolean;
    refetchData?: () => void;
    isDefaultExpanded?: boolean;
}

interface ITableHeader {
    timestamp: string;
    activityType: string;
    initiatorName: string;
    message: string;
    tenantName?: string;
}

export interface IDropdownMenuItem {
    value: string | number;
    name: string | number;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 210
        }
    }
};

const ActivityLog: FC<IProps> = ({
    data,
    isCustomerNameColumnPresent = false,
    isLoading = false,
    isError = false,
    refetchData,
    isDefaultExpanded = false
}) => {
    const selectRef = useRef();
    const [selectedFilterValue, setSelectedFilterValue] = useState<string[]>(['All']);
    const [filteredData, setFilteredData] = useState<IActivityVM[]>();

    const activityFilterMenuItemsMemo: IDropdownMenuItem[] = useMemo(() => {
        const activityTypeSet: Set<string> = new Set();
        data?.forEach((activity) => {
            activityTypeSet.add(activity.activityType);
        });
        const activityTypes: IDropdownMenuItem[] = [];
        activityTypeSet.forEach((activitySetItem) =>
            activityTypes.push({
                value: activitySetItem,
                name: activitySetItem
            })
        );
        return activityTypes;
    }, [data]);

    useEffect(() => {
        setFilteredData(data);
    }, [data]);

    const mainHeadCells: ITableHeadCell<ITableHeader>[] = [
        {
            id: 'timestamp',
            label: 'Time',
            disablePadding: true,
            minWidth: '120px',
            customRender(value, row) {
                return formatDate(value as Date);
            }
        },
        {
            id: 'activityType',
            label: 'Type',
            disablePadding: true
        },
        {
            id: 'initiatorName',
            label: 'Initiator',
            width: '120px',
            disablePadding: true
        },
        {
            id: 'message',
            label: 'Message'
        }
    ];

    const headCells: ITableHeadCell<ITableHeader>[] = isCustomerNameColumnPresent
        ? [
              ...mainHeadCells,
              {
                  id: 'tenantName',
                  label: 'Customer'
              }
          ]
        : mainHeadCells;

    const filterActivitiesByType = (activities: IActivityVM[], filters: string[]) => {
        const newFilteredActivityData = activities?.filter((activity) => {
            return selectedFilterValue.indexOf(activity.activityType) !== -1 || filters.includes('All');
        });
        return newFilteredActivityData;
    };

    const onActivityFilterChange = useCallback(() => {
        let newFilteredItems: IActivityVM[] = [];
        if (data) {
            newFilteredItems = filterActivitiesByType(data, selectedFilterValue);
            setFilteredData(newFilteredItems);
        }
    }, [selectedFilterValue, data]);

    useEffect(() => {
        onActivityFilterChange();
    }, [selectedFilterValue]);

    const handleChange = (event: SelectChangeEvent<typeof selectedFilterValue>) => {
        const {
            target: { value }
        } = event;
        setTimeout(() => {
            document.body.style.overflow = 'hidden';
            document.body.style.paddingRight = '17px';
        }, 0);
        if (Array.isArray(value)) {
            if (value.includes('All')) {
                if (value.length > 1 && value[value.length - 1] !== 'All')
                    setSelectedFilterValue(value.filter((val) => val !== 'All'));
                else setSelectedFilterValue(['All']);
            } else {
                if (
                    value.length === 0 ||
                    (value.length === activityFilterMenuItemsMemo.length - 1 && !value.includes('All'))
                )
                    setSelectedFilterValue(['All']);
                else setSelectedFilterValue(value);
            }
        } else
            setSelectedFilterValue(
                // On autofill we get a stringified value.
                typeof value === 'string' ? value.split(',') : value
            );
    };

    const tableMemo = useMemo(() => {
        if (isLoading) {
            return <Loading />;
        }
        if (isError) {
            return <SomethingWentWrongCard actionCallback={refetchData} />;
        }
        return <Table headCells={headCells} data={filteredData} propertyKeys={headCells.map((cell) => cell.id)} />;
    }, [isLoading, isError, data, refetchData, headCells, filteredData]);

    return (
        <BasicStatusCard title='Activity' defaultExpanded={isDefaultExpanded}>
            <ContentRootBox>
                <TableAdditionalHeader>
                    <StyledSelect
                        placeholder='Filter'
                        multiple
                        ref={selectRef}
                        value={selectedFilterValue}
                        onChange={handleChange}
                        onOpen={() => {
                            setTimeout(() => {
                                document.body.style.overflow = 'hidden';
                                document.body.style.paddingRight = '17px';
                            }, 50);
                        }}
                        input={<Input disableUnderline />}
                        renderValue={(selected) => {
                            if (selected.length === 1 && selected[0] === 'All') return 'All filters';
                            else if (selected.length >= 1) return 'Active filters';
                            return undefined;
                        }}
                        MenuProps={MenuProps}
                        IconComponent={ChevronDown}
                        tabIndex={0}
                    >
                        {activityFilterMenuItemsMemo.map((activityFilterMenuItem) => {
                            const isChecked = selectedFilterValue.findIndex(
                                (filterValue) => filterValue === activityFilterMenuItem.value
                            );
                            return (
                                <StyledMenuItem
                                    sx={{ height: 32, paddingLeft: '4px' }}
                                    key={activityFilterMenuItem.value}
                                    value={activityFilterMenuItem.value}
                                >
                                    <Checkbox checked={isChecked !== -1} />
                                    <MenuItemTitleSpan>{activityFilterMenuItem.name}</MenuItemTitleSpan>
                                </StyledMenuItem>
                            );
                        })}
                    </StyledSelect>
                </TableAdditionalHeader>
                {tableMemo}
            </ContentRootBox>
        </BasicStatusCard>
    );
};

export default ActivityLog;
