import { useQuery, UseQueryResult, useMutation, UseMutationResult } from '@tanstack/react-query';
import { getTokenAndHeaders } from './helpers/apiUtil';
import useAuthParams from '../hooks/useAuthParams';
import { UserSkillProgressDTO } from '../interfaces/dtos/UserSkillProgressDTO';
import { IAuthParams } from '../interfaces/IAuthParams';
import { mapSingleUserSkill, mapUserSkills } from './helpers/mappers';
import { IUserSkillVM } from '../interfaces/views/IUserSkillVM';
import { ISingleUserSkillVM } from '../interfaces/views/ISingleUserSkillVM';
import { IAssignmentDTO } from '../interfaces/dtos/IAssignmentDTO';
import { EMasterAction } from '../interfaces/enums/EMasterAction';
import { apiInstance } from './helpers/apiInstance';
import { IUserSkillsSnapshotDTO } from '../interfaces/dtos/IUserSkillsSnapshotDTO';
import { EApiQueryKey } from '../interfaces/enums/EApiQueryKey';
import { ELaunchType } from '../interfaces/enums/ELaunchType';
export interface IPostFavoriteSkillResponse {
    skillId: number;
    isFavorite: boolean;
}

export interface IPostFavoriteSkillRequest {
    skillId: string;
}

export interface IPostAssignSkillRequest {
    assignmentType: number;
    skillId: string;
}

export interface IPostMasterSkillRequest {
    skillId: number;
    action: EMasterAction;
    isRecommendation?: boolean;
}

export interface IPostMasterSkillResponse {}

export interface IPostFavoriteSkillRequest {
    skillId: string;
}

export interface IPostAssignSkillRequest {
    assignmentType: number;
    skillId: string;
    isRecommendation?: boolean;
}

// GET ALL SKILLS
export const getAllSkills = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`userskills`, headers);
    return mapUserSkills(data);
};

export const useGetAllSkillsQuery: () => UseQueryResult<IUserSkillVM[], Error> = () => {
    const authParams = useAuthParams();

    return useQuery({
        queryKey: [EApiQueryKey.SKILL_FETCH_ALL],
        queryFn: async () => {
            return getAllSkills(authParams);
        },
        refetchOnWindowFocus: false
    });
};

// GET USER SKILL
export const getUserSkill = async (authParams: IAuthParams, skillId?: string) => {
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data }
    } = await apiInstance.get(`userskills/${skillId}`, headers);

    return mapSingleUserSkill(data);
};

export const useGetUserSkill: (skillId?: string) => UseQueryResult<ISingleUserSkillVM, Error> = (skillId) => {
    const authParams = useAuthParams();

    return useQuery({
        queryKey: [EApiQueryKey.SKILL_FETCH, skillId],
        queryFn: async () => {
            return getUserSkill(authParams, skillId);
        },
        refetchOnWindowFocus: false,
        enabled: false
    });
};

export const getUserSkillProgress = async (authParams: IAuthParams, skillId?: string) => {
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data }
    } = await apiInstance.get(`userskillprogress/${skillId}`, headers);
    return data;
};

export const useGetUserSkillProgress: (skillId?: string) => UseQueryResult<UserSkillProgressDTO, Error> = (skillId) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.SKILL_FETCH_USER_PROGRESS, skillId],
        queryFn: async () => {
            return getUserSkillProgress(authParams, skillId);
        },
        refetchOnWindowFocus: false,
        enabled: false
    });
};

export const postLaunchActivity: (
    variables: { activityId: string; launchType: ELaunchType } & { authParams: IAuthParams }
) => Promise<undefined> = async (variables) => {
    const { headers } = await getTokenAndHeaders(variables.authParams);

    const {
        data: { data }
    } = await apiInstance.post(
        `activities/${variables.activityId}/launched`,
        {
            launchType: variables.launchType
        },
        headers
    );
    return data;
};

export const usePostLaunchActivity: () => UseMutationResult<any, Error, any> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: async (variables: { activityId: string; launchType: ELaunchType }) => {
            return postLaunchActivity({ ...variables, authParams });
        }
    });
};

// POST FAVORITE SKILL
export const postFavoriteSkill: (variables: IPostFavoriteSkillRequest & { authParams: IAuthParams }) => any = async (
    variables
) => {
    const { authParams, skillId } = variables;
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data }
    } = await apiInstance.post(`skills/${skillId}/favorite`, undefined, headers);
    return data;
};

export const usePostFavoriteSkill: () => UseMutationResult<
    IPostFavoriteSkillResponse,
    Error & { status: number },
    IPostFavoriteSkillRequest
> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: async (variables: IPostFavoriteSkillRequest) => {
            return postFavoriteSkill({ ...variables, authParams });
        }
    });
};

// POST FAVORITE SKILL
export const postAssignSkill: (
    variables: IPostAssignSkillRequest & { authParams: IAuthParams }
) => Promise<IAssignmentDTO> = async (variables) => {
    const { authParams, assignmentType, skillId, isRecommendation } = variables;
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data }
    } = await apiInstance.post(
        `skills/${skillId}/assignment${isRecommendation ? '?rec=true' : ''}`,
        {
            assignmentType
        },
        headers
    );
    return data as IAssignmentDTO;
};

export const usePostAssignSkill: () => UseMutationResult<IAssignmentDTO, Error, IPostAssignSkillRequest> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables: IPostAssignSkillRequest) => {
            return postAssignSkill({ ...variables, authParams });
        }
    });
};

// POST MASTER
export const postMasterSkill: (
    variables: IPostMasterSkillRequest & { authParams: IAuthParams }
) => Promise<IPostMasterSkillResponse> = async (variables) => {
    const { authParams, skillId, action, isRecommendation } = variables;
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data }
    } = await apiInstance.post(
        `skills/${skillId}/master-level${isRecommendation ? '?rec=true' : ''}`,
        {
            action
        },
        headers
    );
    return data;
};

export const usePostMasterSkill: () => UseMutationResult<
    IPostMasterSkillResponse,
    Error,
    IPostMasterSkillRequest
> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables: IPostMasterSkillRequest) => {
            return postMasterSkill({ ...variables, authParams });
        }
    });
};

// GET USER SKILLS SNAPSHOT
export const getUserSkillsSnapshot = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data }
    } = await apiInstance.get(`userSkillSnapshot`, headers);

    return data;
};

export const useGetUserSkillsSnapshot: () => UseQueryResult<IUserSkillsSnapshotDTO, Error> = () => {
    const authParams = useAuthParams();

    return useQuery({
        queryKey: [EApiQueryKey.SKILL_FETCH_SNAPSHOT],
        queryFn: async () => {
            return getUserSkillsSnapshot(authParams);
        },
        refetchOnWindowFocus: false
    });
};
