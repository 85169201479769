import styled from '../../../../../ui/theme/styled';

export const WarningIconSpan = styled('span', {
    shouldForwardProp: (prop) => prop !== 'isDisabled' && prop !== 'isAvailable'
})<{ isDisabled: boolean; isAvailable: boolean }>(({ isDisabled, isAvailable, theme }) => ({
    cursor: isAvailable ? (isDisabled ? 'not-allowed' : 'pointer') : 'default',
    padding: '13px 10px 7px 0px',
    borderRadius: '20px',
    '&:hover': {
        backgroundColor: isAvailable ? 'rgba(0, 0, 0, 0.16)' : 'inherit'
    },
    [theme.breakpoints.down('lg')]: {
        padding: 0
    }
}));
