import { FC, useCallback, useState } from 'react';
import Page from '../../components/page/Page';
import PageContent from '../../components/page-content/PageContent';
import Connection from './connection/Connection';
import {
    // CustomerNameBox,
    // CustomerNameTypography,
    // CustomerRenameBox,
    RootBox
    // StyledEditIcon
} from './Style';
import { EConnectionStatus } from '../../interfaces/enums/EConnectionStatus';
import { EAccountType } from '../../interfaces/enums/EAccountType';
import Administrators from './administrators/Administrators';
import { useAccountDetailsStateValue } from '../../contexts/AccountDetailsContext';
import Settings from './settings/Settings';
import ActivityLog from '../../components/accounts/activity-log/ActivityLog';
// import { Tooltip } from 'nulia-ui';
// import { useTranslation } from 'react-i18next';
// import Skeleton from '@material-ui/lab/Skeleton';
import UsageSummarySimple from '../../pages/accounts/usage-summary-simple/UsageSummarySimple';
import { ETenantStatus } from '../../interfaces/enums/ETenantStatus';
import Notes from './notes/Notes';

const AccountDetails: FC = () => {
    const [connectionStatus, setConnectionStatus] = useState<EConnectionStatus>(EConnectionStatus.ACTIVE);
    const {
        activityData,
        administratorsList,
        customerId,
        isAdministratorsLoading,
        isAdministratorsError,
        refetchAdministratorsApiCall,
        settingsData,
        isSettingsLoading,
        isSettingsError,
        refetchSettingsApiCall,
        customerDetailsData,
        licenseDetailsData,
        isCustomerLicenseDetailsError,
        isCustomerLicenseDetailsLoading,
        refetchLicenseDetailsApiCall,
        isCustomerDetailsLoading,
        isCustomerDetailsError,
        isActivityDataLoading,
        isActivityDataFetchingError,
        notesData,
        isNotesError,
        isNotesLoading,
        refetchNotesApiCall,
        customerConnectUrlData
    } = useAccountDetailsStateValue();
    // const { t } = useTranslation();

    const changeConnectionStatus = useCallback((newConnectionStatus: EConnectionStatus) => {
        setConnectionStatus(newConnectionStatus);
    }, []);

    // const openRenameCustomerModal = () => {
    //     alert('TODO');
    // };

    return (
        <Page>
            <PageContent>
                {/* <CustomerNameBox>
                    <CustomerNameTypography variant='h5'>
                        {isCustomerDetailsLoading ? (
                            <Skeleton variant='rect' width={100} height={20} />
                        ) : (
                            customerDetailsData?.name
                        )}
                    </CustomerNameTypography>
                    <Tooltip title={isCustomerDetailsLoading ? '' : t('partnerTooltips.renameCustomer')}>
                        <CustomerRenameBox
                            isDisabled={isCustomerDetailsLoading}
                            onClick={isCustomerDetailsLoading ? undefined : openRenameCustomerModal}
                        >
                            <StyledEditIcon isDisabled={isCustomerDetailsLoading} />
                        </CustomerRenameBox>
                    </Tooltip>
                </CustomerNameBox> */}
                <RootBox>
                    <UsageSummarySimple
                        isError={isCustomerLicenseDetailsError}
                        isLoading={isCustomerLicenseDetailsLoading}
                        allocatedNuliaLicenses={licenseDetailsData?.nuliaLicensesAllocated || 0}
                        refetchDataCallback={refetchLicenseDetailsApiCall}
                        totalOfficeLicenses={licenseDetailsData?.m365LicensedUsers || 0}
                        totalOfficeWithNoLicenses={licenseDetailsData?.m365UsersWithoutNuliaLicenses || 0}
                        assignedNuliaLicenses={licenseDetailsData?.nuliaLicensesAssigned || 0}
                        unassignedNuliaLicenses={licenseDetailsData?.nuliaLicensesUnassigned || 0}
                        initialCustomerName={customerDetailsData?.name}
                        initialRegion={customerDetailsData?.region}
                        initialReseller={customerDetailsData?.folderId}
                        isCustomerMode={!isCustomerDetailsLoading && !isCustomerDetailsError}
                        isRegionFieldChangeDisabled={customerDetailsData?.tenantStatus !== ETenantStatus.NEW}
                        tenantId={customerDetailsData?.tenantId}
                        licenseClass={licenseDetailsData?.licenseClass}
                        headerTitle={customerDetailsData?.name}
                    />
                    <Connection
                        connectionStatus={connectionStatus}
                        changeConnectionStatus={changeConnectionStatus}
                        accountType={EAccountType.CUSTOMER}
                        isLoading={isCustomerDetailsLoading}
                        connectUrl={customerConnectUrlData?.url}
                    />
                    <Administrators
                        customerId={customerId}
                        administratorsList={administratorsList}
                        isError={isAdministratorsError}
                        isLoading={isAdministratorsLoading}
                        refetch={refetchAdministratorsApiCall}
                    />
                    <Settings
                        settings={settingsData}
                        isError={isSettingsError}
                        isLoading={isSettingsLoading}
                        refetch={refetchSettingsApiCall}
                    />
                    <Notes
                        notes={notesData}
                        isError={isNotesError}
                        isLoading={isNotesLoading}
                        refetch={refetchNotesApiCall}
                        customerId={customerDetailsData?.tenantId}
                    />
                    <ActivityLog
                        data={activityData}
                        isError={isActivityDataFetchingError}
                        isLoading={isActivityDataLoading}
                    />
                </RootBox>
            </PageContent>
        </Page>
    );
};

export default AccountDetails;
