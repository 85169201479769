import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Edit } from 'react-feather';

export const RootBox = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
}));

export const CustomerNameBox = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
    gap: '16px'
});

export const CustomerNameTypography = styled(Typography)({
    display: 'flex'
});

export const CustomerRenameBox = styled('div')<{ isDisabled: boolean }>(({ isDisabled }) => ({
    display: 'flex',
    padding: '8px',
    alignItems: 'center',
    '&:hover': {
        cursor: !isDisabled && 'pointer',
        borderRadius: 24,
        backgroundColor: !isDisabled && 'rgba(0, 0, 0, 0.04)'
    }
}));

export const StyledEditIcon = styled(Edit)<{ isDisabled: boolean }>(({ isDisabled, theme }) => ({
    color: isDisabled ? theme.palette.action.disabled : 'inherit'
}));
