import { Table, TableContainer } from '@mui/material';
import styled from '../../../../../ui/theme/styled';
import SearchInput from '../../../../../ui/general/search-input/SearchInput';

export const StyledMain = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
});

export const RootInnerBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '12px',
    width: 1080,
    [theme.breakpoints.down('lg')]: {
        width: '100%'
    }
}));

export const StyledTableContainer = styled(TableContainer)({
    borderRadius: 4,
    flex: 11
});

export const StyledTable = styled(Table)(({ theme }) => ({
    // minWidth: 750,
    backgroundColor: theme.palette.common.white
}));

export const FiltersWrapper = styled('div')({
    flex: 4
});

export const StyledSearchInput = styled(SearchInput)(({ theme }) => ({
    background: theme.palette.common.white,
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0.5px 1.5px rgba(0, 0, 0, 0.08)',
    marginRight: 12,
    width: '100%',
    '& input': {
        background: theme.palette.common.white
    }
}));

export const SearchInputWrapper = styled('div')(() => ({
    width: '100%',
    margin: '16px 0'
}));
