import { FC, PropsWithChildren } from 'react';
import TextField from '@mui/material/TextField';
import { TextInputRootBox, ErrorTypography } from './Style';

interface IProps {
    label: string;
    value: string | number;
    onChange: any;
    field?: any;
    disabled?: boolean;
    inputProps?: any;
    errorMessage?: string;
    type?: string;
    boxStyle?: any;
}

const NTextInput: FC<PropsWithChildren<IProps>> = ({
    label,
    value,
    onChange,
    field,
    disabled = false,
    inputProps,
    errorMessage,
    type,
    boxStyle
}) => {
    return (
        <TextInputRootBox style={boxStyle}>
            <TextField
                {...field}
                disabled={disabled}
                label={label}
                variant='standard'
                onChange={onChange}
                value={value}
                inputProps={inputProps}
                type={type}
            />
            {errorMessage && <ErrorTypography>{errorMessage}</ErrorTypography>}
        </TextInputRootBox>
    );
};

export default NTextInput;
